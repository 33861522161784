/// <reference types="@types/googlemaps" />
import {
  Component,
  OnInit,
  ViewChild,
  Renderer2,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import { LatLngBounds, MapsAPILoader, AgmInfoWindow, AgmMap } from "@agm/core";
import * as moment from "moment-timezone";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { AssigTripsService } from "../assigned-trips/assigntrips-service/assigntrips-service";
import { SocketService } from "src/app/service/socket-service/socket-service.service";
import { Events } from "src/app/utils/event-utils";
import { UniquePipe } from "ngx-pipes";
import { getBrokersList, getJobStatus } from "src/app/utils/utils.common";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";

declare var $: any;
declare var google: any;

@Component({
  selector: 'app-mapview',
  templateUrl: './mapview.component.html',
  styleUrls: ['./mapview.component.css'],
  providers: [UniquePipe],
})
export class MapviewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("AgmMap", { static: true }) agmElement: AgmMap;
  notifications;
  online = 0;
  offline = 0;
  busy = 0;

  companies;
  selectedCompanyId = "all";
  selectedCompany;
  
  driversToShow = "";
  driversToShow_Statuses = ["online", "buzy"];

  expiringSoonTrips = [];
  approachingTrips = [];
  markedTrips = [];
  selectedHourTab = 1;

  event: any;
  assigns: any;
  count: number;
  user: any;
  drivers: any;
  selectedCompanyDrivers = [];
  currentCompanyDrivers: any;
  status = false;
  zoom = 7;
  autoZoom = true;
  showDrivers = true;
  showCompanies = false;
  searchAddress = '';
  
  notificationSearch = "";
  interval;
  agmMap;
  origin = {
    latitude: 37.0902,
    longitude: 95.7129,
  };
  showSections = false;
  searchMarker;
  companyDrivers;
  isLoadingDrivers = true;
  brokersList = getBrokersList;
  getTripJobStatus = getJobStatus;
  mapFilterMenu = false;
  live;
  selectedDriver = null;
  driverLoc = { lat: 0.00000, lng: 0.00000 };

  constructor(
    private socketService: SocketService,
    private assignService: AssigTripsService,
    private sharedData: SharedDataService,
    private uniquePipe: UniquePipe
  ) { }

  ngOnInit() {
    this.listenSocket();
  }

  ngAfterViewInit() {
    this.agmElement.mapReady
      .subscribe((map) => {
        this.agmMap = map;
        this.getUser();
        this.getTrips();
        this.interval = setInterval(() => {
          this.getAllData();
        }, 10000);
      });
  }

  onSelectCompany() {
    this.selectedCompany = this.companies.find(company => {
      return company._id === this.selectedCompanyId;
    });
    if (this.selectedCompanyId !== "all") {
      this.showSections = true;
    } else {
      this.showSections = false;
    }
    this.filterDriversByCompany();
    this.getAllData();
    this.driversToShow = "";
    this.selectedDriver = "";
  }

  onSelectSearchLocation(place) {
    this.searchMarker = {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    };
    this.setMapZoom();
  }

  toggleAutoZoom() {
    this.setMapZoom();
  }

  getUser() {
    this.sharedData
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.user = data;
          this.socketService.emit(Events.GET_DRIVES); 
        }
    });
  }

  getNotifications() {
    if (this.selectedCompanyId!== "all") {
      this.assignService
        .getNotification(this.selectedCompany._id)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((data: any) => {
          if (data && data.length > 0) {
            this.notifications = data;
          } else {
            this.notifications = null;
          }
        });
    } else {
      this.notifications = null;
    }
  }

  extractCompanies() {
    this.companies = [];

    if (this.drivers) {
      this.drivers.forEach(driver => {
        if (driver.driverCompany) {
          this.companies.push(driver.driverCompany);
        }
      });
      this.companies = this.uniquePipe.transform(this.companies, "_id");
    }
  }

  getTrips() {
    const search = {};
      const scheduleTime = moment().startOf("day").toISOString();
      search["scheduleTime"] = scheduleTime;

      this.assignService
        .getAllTrips(search)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((data: any) => {
          if (data) {
            this.assigns = data;
          }
        });
  }

  getAllData() {
    if (this.selectedCompanyId !== "all") {
      this.getMarkedReadyTrips();
      this.getNotifications();
      this.getApproachingTrips(this.selectedHourTab);
      this.getExpireTrips();
      this.socketService.emit(Events.GET_DRIVES); 
    } else {
      this.expiringSoonTrips = [];
      this.markedTrips = [];
      this.approachingTrips = [];
      this.notifications = [];
      // Apply filter Driver related things

      if (this.agmMap) {
        if (!(this.drivers && this.drivers.length)) {
          this.socketService.emit(Events.GET_DRIVES);
        } else {
          this.callDriverRelatedFilter();
        }
      }
    }
  }

  filterDriversByCompany() {
    this.isLoadingDrivers = false;

    if (this.selectedCompanyId !== "all") {
      this.selectedCompanyDrivers = this.drivers.filter(
        (d) => d.driverCompany._id === this.selectedCompany._id
      );
    } else {
      this.selectedCompanyDrivers = this.drivers;
    }
    this.online = this.selectedCompanyDrivers.filter((x) => x.status === "online").length;
    this.offline = this.selectedCompanyDrivers.filter((x) => x.status === "offline").length;
    this.busy = this.selectedCompanyDrivers.filter((x) => x.status === "buzy").length;
  }

  callDriverRelatedFilter() {
    this.extractCompanies();    
    this.setMapBoundsWithRespectToDrivers();
    this.filterDriversByCompany();
  }

  filterMapDriversByStatus(driverStatus) {
    // Resets the applied filter based on driver status
    if (driverStatus === this.driversToShow) {
      this.driversToShow_Statuses = ["online", "buzy"];
      this.driversToShow = "";
      this.selectedDriver = "";
      return false;
    }

    // Sets which drivers to show on map
    if (driverStatus === "offline") {
      this.driversToShow_Statuses = ["offline"];
    } else if (driverStatus === "online") {
      this.driversToShow_Statuses = ["online"];
    } else if (driverStatus === "busy") {
      this.driversToShow_Statuses = ["buzy"];
    }

    this.driversToShow = driverStatus;
    this.setMapZoom();
  }

  setMapBoundsWithRespectToDrivers() {
    this.setMapZoom();
  }

  updateDriverLocation(driver) {
    if (this.drivers && driver && driver.id) {
      this.drivers.forEach(d => {
        if (d._id == driver._id) {
          d.latitude = driver.latitude;
          d.longitude = driver.longitude;
          d.status = driver.status;
        }
      });
    }

    if (this.selectedCompanyDrivers && driver && driver.id) {
      this.selectedCompanyDrivers.forEach(d => {
        if (d._id == driver._id) {
          d.latitude = driver.latitude;
          d.longitude = driver.longitude;
          d.status = driver.status;
        }
      });
    }
    this.setMapZoom();
  }

  setMapZoom() {
    if (!this.autoZoom) return;
    if (this.agmMap) {
      const bounds: LatLngBounds = new google.maps.LatLngBounds();

      if (this.showDrivers && this.selectedCompanyDrivers) {
        for (const driver of this.selectedCompanyDrivers) {
          if (
            driver.driverCompany &&
            this.driversToShow_Statuses.indexOf(driver.status) > -1
          ) {
            bounds.extend(
              new google.maps.LatLng(driver.latitude, driver.longitude)
            );
          }
        }
      }

      if (this.showCompanies && this.companies) {
        for (const company of this.companies) {
          bounds.extend(
            new google.maps.LatLng(company.latitude, company.longitude)
          );
        }
      }

      if (this.searchMarker) {
        bounds.extend(
          new google.maps.LatLng(
            this.searchMarker.latitude,
            this.searchMarker.longitude
          )
        );
      }

      if (this.selectedDriver) {
        bounds.extend(
          new google.maps.LatLng(
            this.selectedDriver.latitude,
            this.selectedDriver.longitude
          )
        );
      }

      for (const trips of this.expiringSoonTrips) {
        bounds.extend(
          new google.maps.LatLng(
            trips.jobOriginLatitude,
            trips.jobOriginLongitude
          )
        );
      }
      this.agmMap.setCenter(bounds.getCenter());
      this.agmMap.fitBounds(bounds);
    }
  }

  onSelectDriver(driver) {
    this.selectedDriver = driver;
    this.driverLoc = {
      lat: driver.latitude,
      lng: driver.longitude
    };
    this.setMapZoom()
  }

  // ======== Trips ======== 

  getMarkedReadyTrips() {
    this.markedTrips = [];
    if (this.selectedCompanyId !== "all") {
      const companyObject = {
        company: {
          _id: this.selectedCompany._id,
          timeZone: this.selectedCompany.timeZone,
        },
      };
      
      this.socketService.emit(Events.GET_MARKED_TRIPS, companyObject);
    }
  }

  getExpireTrips() {
    this.expiringSoonTrips = [];
    if (this.selectedCompanyId !== "all") {
      
      const companyObject = {
        company: {
          _id: this.selectedCompany._id,
          timeZone: this.selectedCompany.timeZone,
        },
      };
      
      this.socketService.emit(Events.SOON_EXPIRING_TRIPS, companyObject);
    }
  }

  getApproachingTrips(hours) {
    this.selectedHourTab = hours;
    this.approachingTrips = [];
    if (this.selectedCompanyId !== "all") {
      
      let companyObject = {
        company: {
          _id: this.selectedCompany._id,
          timeZone: this.selectedCompany.timeZone,
        },
        hours: hours,
      };
      
      this.socketService.emit(Events.GET_APPROCHING_TRIPS, companyObject);
    }
  }

  expiringSoonTime(trip) {
    const now = moment.utc(new Date()).local().format();
    const expiringSoonTime = moment(trip.scheduleTime);
    return expiringSoonTime.diff(now, "minutes");
  }

  getNameChars(assign) {
    if (assign && assign.priorityClient && assign.priorityClient.displayName) {
      return assign.priorityClient.displayName
        .toLowerCase()
        .trim()
        .split(" ")[0];
    }
  }

  listenSocket() {
    this.socketService
      .addListener(Events.GET_DRIVES)
      .subscribe(data => {
        if (data.success && data.drivers) {
          if (this.agmMap) {
            this.drivers = data.drivers;
            this.callDriverRelatedFilter();
          }
        } else {
          if (this.agmMap) {
            this.drivers = [];
            this.callDriverRelatedFilter();
          }
        }
      });

    this.socketService
      .addListener(Events.SOON_EXPIRING_TRIPS)
      .subscribe(data => {
        if (data && data.success) {
          this.expiringSoonTrips = data.assigns;
          this.setMapZoom();
        } else {
          this.expiringSoonTrips = [];
        }
      });
    
    this.socketService
      .addListener(Events.GET_MARKED_TRIPS)
      .subscribe(data => {
        if (data && data.success) {
          this.markedTrips = data.assigns;
        } else {
          this.markedTrips = [];
        }
      });

    this.socketService
      .addListener(Events.GET_APPROCHING_TRIPS)
      .subscribe(data => {
        if (data && data.success) {
          this.approachingTrips = data.assigns;
        } else {
          this.approachingTrips = [];
        }
      });
    
    this.socketService
      .addListener(Events.GET_MARK_READY_NOTIFICATION)
      .subscribe(data => {
        if (this.assigns && data && data.assign) {
          const trip = data.assign;
          const index = this.assigns.findIndex(
            (assign) => assign._id === trip._id
          );
          this.assigns[index].isReadyForPickup = data.isReadyForPickup;
        }
      });
    
    this.socketService
      .addListener(Events.GET_DRIVER_LOCATION)
      .subscribe(data => {
        if (data.driver) {
          this.updateDriverLocation(data.driver);
        }
      });
  }

  getBroker(company) {
    return this.brokersList[company];
  }

  clearSearch() {
    this.searchMarker = undefined;
    this.searchAddress = '';
    this.setMapZoom();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.socketService.removeListener(Events.GET_DRIVES);
    this.socketService.removeListener(Events.SOON_EXPIRING_TRIPS);
    this.socketService.removeListener(Events.GET_APPROCHING_TRIPS);
    this.socketService.removeListener(Events.GET_MARKED_TRIPS);
    this.socketService.removeListener(Events.GET_MARK_READY_NOTIFICATION);
    this.socketService.removeListener(Events.GET_DRIVER_LOCATION);
  }
}
