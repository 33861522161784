import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { AssigTripsService } from 'src/app/components/assigned-trips/assigntrips-service/assigntrips-service';

@Component({
  selector: 'app-trip-logs',
  templateUrl: './trip-logs.component.html',
  styleUrls: ['./trip-logs.component.css']
})
export class TripLogsComponent implements OnInit ,OnDestroy {
  @Input() logs;
  @Output() close = new EventEmitter<any>();
  tripLogs = [];

  constructor(
    private assignTripService: AssigTripsService
  ) { }

  ngOnInit() {
    this.getTripLogs();
  }

  getTripLogs(){
    this.assignTripService
    .tripLogs(this.logs._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.tripLogs = data;// sweetAlert('Success', `${assign.isDvRequired ? 'Marked' : 'Un-Marked'} DV Required Successfully`, 'success', 'OK');
        }
  });
}

  ngOnDestroy(): void {
  }

}
