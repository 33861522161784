import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HomeService } from './home-service/home.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { getBrokersList } from 'src/app/utils/utils.common';
import * as moment from 'moment-timezone';
import { Events } from 'src/app/utils/event-utils';
import * as _ from 'underscore';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { CompaniesService } from '../companies/service/companies.service';
import { ToastrService } from 'ngx-toastr';
import { AssigTripsService } from '../assigned-trips/assigntrips-service/assigntrips-service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild("date", { static: true }) date_v: ElementRef;
  @ViewChild("audioOption", { static: true }) audioPlayerRef: ElementRef;
  // tripDetail = 'Taimoor';
  tripDetailObj;
  providerTime = moment().toISOString();
  assigns: any;
  datePipeEn = new DatePipe("en-US");
  searchForm: FormGroup;
  currentUser: any;
  searchCompanyType = "";
  submitted = false;
  companyType: any;
  filteredAssigns: any;
  emptySearch: boolean;
  searchBroker = '';
  searchStatus: string = "";
  companyTypes = getBrokersList;
  intervalTimeID;
  userPlatform: any;
  filterOpen = false;
  view = "list";
  assignKey;
  search;

  filterdataList = [];
  selectedDropdownIndex = -1;
  toggleActionButtons: boolean = false;
  showSlideFilter = false;

  companies;
  companiesByKey;

  columns = {
    name: true,
    address: true,
    miles: true,
    vehicle: true,
    provider: true,
    status: true
  }

  constructor(
    private homeService: HomeService,
    private formBuilder: FormBuilder,
    private sharedDataService: SharedDataService,
    private socketService: SocketService,
    private companyService: CompaniesService,
    private toastr: ToastrService,
    private assignService: AssigTripsService
  ) { }

  ngOnInit() {
    this.intervalTimeID = setInterval(() => {
      this.providerTime = moment().toISOString();
    }, 1000);

    this.searchForm = this.formBuilder.group({
      search_date: ["", Validators.required],
      search_tripId: ["", Validators.required],
      search_name: ["", Validators.required],
      search_phone: ["", Validators.required],
    });

    this.sharedDataService.getUser()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data && data.platform) {
        this.userPlatform = data.platform;
      }
      this.currentUser = data;
    });

    this.listenSocket();
    this.getCompanies();
  }

  getCompanies() {
    const queryParams = {
      accountStatus: 'active',
      isRemoveIAAL: true
    };

    this.companyService
      .getCompanies(queryParams)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (data && data.length) {
          this.companies = data;
          this.companiesByKey = data.reduce((obj, item) => (obj[item._id] = item, obj) ,{});
        }
      });
  }

  getTrips() {
    this.searchForm.value.search_date = this.datePipeEn.transform(
      this.date_v.nativeElement.value,
      "yyyy-MM-dd"
    );

    const scheduleTime = moment(this.searchForm.value.search_date)
      .tz(this.searchCompanyType ? this.companiesByKey[this.searchCompanyType].timeZone : this.currentUser.timeZone, true)
      .toISOString();

    let search = {};
    if (this.searchForm.value.search_name) {
      search["displayName"] = this.searchForm.value.search_name;
    }
    if (this.searchForm.value.search_tripId) {
      search["tripId"] = this.searchForm.value.search_tripId;
    }
    if (this.searchForm.value.search_date) {
      search["scheduleTime"] = scheduleTime;
    }
    if (this.searchForm.value.search_phone) {
      search["contactNumber"] = this.searchForm.value.search_phone;
    }

    if (this.searchCompanyType) {
      // Single
      search["companies"] = [this.searchCompanyType];
      search["timeZone"] = this.companiesByKey[this.searchCompanyType].timeZone;
    } else {
      // All
      search["companies"] = this.companies.map(c => c._id);
      search["timeZone"] = this.currentUser.timeZone;
    }

    this.homeService.getDashboardTrips(search)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      this.searchStatus = "";
      this.searchBroker = "";
      this.assigns = data;
      this.filteredAssigns = data;
     
      if (this.filteredAssigns && this.filteredAssigns.length) {
        this.filteredAssigns.sort((a, b) => {
          return (this.companiesByKey
              && this.companiesByKey[a.company]
              && this.companiesByKey[b.company]
              && this.companiesByKey[a.company].displayName.localeCompare(this.companiesByKey[b.company].displayName))
              || <any>new Date(a.scheduleTime) - <any>new Date(b.scheduleTime)
        });
      }
    });
    this.submitted = false;
  }

  onSubmit() {
    this.emptySearch = false;
    this.submitted = true;
    if (
      !this.searchForm.value.search_date &&
      !this.searchForm.value.search_tripId &&
      !this.searchForm.value.search_name &&
      !this.searchForm.value.search_phone
    ) {
      this.toastr.error(
        "Please fill atleast one field to continue search.",
        "Empty Search"
      );
      this.onAudioPlay();
      this.emptySearch = true;
      return;
    }
    this.getTrips();
  }

  clearSearch() {
    this.searchForm.patchValue({
      search_date: "",
      search_tripId: "",
      search_name: "",
      search_phone: "",
    });
    this.searchCompanyType = '';
  }

  updateJob(assign, assignKey) {
    // Update Job - Mark Ready, Mark VIP, Mark Confirm, Mark Voicemail
    let params = {
      assignId: assign._id
    }
    params[assignKey] = !assign[assignKey];
    this.socketService.emit(Events.UPDATE_ASSIGN_TRIP, params);
    this.selectedDropdownIndex = -1;
    this.toggleActionButtons = false;
  }

  listenSocket() {
    this.socketService.addListener(Events.UPDATE_ASSIGN_TRIP)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data.success) {
          if (data.assign) {
            const trip = data.assign;
            if (this.assigns && this.assigns.length) {
              const idx = this.assigns.findIndex((assign) => assign._id == trip._id);
              this.assigns[idx].isReadyForPickup = trip.isReadyForPickup;
              this.assigns[idx].isVipTrip = trip.isVipTrip;
              this.assigns[idx].isConfirmedTrip = trip.isConfirmedTrip;
              this.assigns[idx].isVoiceMail = trip.isVoiceMail;
            }
          }
        }
      });

    this.socketService
      .addListener(Events.GET_MARK_READY_NOTIFICATION)
      .subscribe(data => {
        if (this.assigns && data && data.assign) {
          const trip = data.assign;
          const index = this.assigns.findIndex(
            (assign) => assign._id === trip._id
          );
          this.assigns[index].isReadyForPickup = data.isReadyForPickup;
        }
      });
  }

  fetchDriver(assign) {
    this.assignService
      .getTripDetail(assign._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        assign.driver = data.driver;
      });
  }

  onChangeDropdownFilters() {
    this.filterTrips();
  }

  filterTrips() {
    this.filteredAssigns = this.assigns
      .filter(trip => {
        if (this.searchStatus) {
          if (this.searchStatus === "onway") {
            if (!(trip.jobStatus === this.searchStatus)) return false;
          } else {
            if (!(trip.status === this.searchStatus)) return false;
          }
        }

        if (this.searchBroker) {
          if (!(trip.companyType === this.searchBroker)) return false;
        }

        return true;
      });

  }

  viewTripDetails(trip) {
    this.tripDetailObj = trip;
  }

  showActionButtons(idx) {
    this.selectedDropdownIndex = idx;
    this.toggleActionButtons = !this.toggleActionButtons;
  }

  closeActionMenu(evt: Event) {
    if (evt && evt.target && (evt.target as Element).id.includes('toggle-action-menu-svg')) return;
    this.selectedDropdownIndex = -1;
    this.toggleActionButtons = false;
  }

  toggleView() {
    if (this.view === "list") {
      this.view = "grid";
    } else {
      this.view = "list";
    }
  }

  toggleFilter() {
    this.filterOpen = !this.filterOpen;
  }

  showFilter() {
    this.showSlideFilter = !this.showSlideFilter
  }

  closeFilter() {
    this.showSlideFilter = false;
  }

  setColumnVisibility(column) {
    this.columns[column] = !this.columns[column];
  }

  isAnyColumnToggled() {
    return Object.keys(this.columns).some(c => this.columns[c] === false);
  }

  onAudioPlay() {
    this.audioPlayerRef.nativeElement.play();
  }

  get form() {
    return this.searchForm.controls;
  }

  ngOnDestroy() {
    if (this.intervalTimeID) {
      clearInterval(this.intervalTimeID);
    }
    this.socketService.removeListener(Events.UPDATE_ASSIGN_TRIP);
    this.socketService.removeListener(Events.GET_MARK_READY_NOTIFICATION);
  }
}
