import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  constructor(private http:HttpClient) { }

  getAlivisCompanies(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_ALIVIS_COMPANIES);
  }

  getCompaniesDetails(id) {
    let params = new HttpParams();
    params = params.append('company', id);
    return this.http.get(`${urls.BASE_URL}${urls.GET_ALIVIS_COMPANIES_DETAILS}`, {params});

  }


  getDrivers(companyId) {

    let params = new HttpParams();
    params = params.append('company', companyId);

    let options = {
      params: params
    };

    return this.http.get(urls.BASE_URL + urls.GET_DRIVERS_LIST, options);
  }

  getDriverById(driverId) {
    return this.http.get(urls.BASE_URL + urls.GET_DRIVERS_LIST + driverId);
  }


  getVehiclesList(companyId) {
 

    let params = new HttpParams();
    params = params.append('company', companyId);

    let options = {
      params: params
    };

    return this.http.get(urls.BASE_URL + urls.GET_VEHICLES_LIST, options);
  }


  getVehicleById(id) {
   

    return this.http.get(urls.BASE_URL + urls.GET_VEHICLES_LIST +'/' + id);
  }


  updateCompany(object, companyId) {
    const formData = new FormData();
    for (var key of Object.keys(object)) {
      formData.append(key, object[key]);
    }
    return this.http.put<any>(urls.BASE_URL + 'companyalivis/' + companyId, formData)
  }
  sendErrorEmail(object, id) {
    
    
    return this.http.post<any>(urls.BASE_URL+ 'companyalivisemails/' + id, object)
  }

  updateDriver(object, driverId) {
    const formData = new FormData();
    for (var key of Object.keys(object)) {
      formData.append(key, object[key]);
      console.log(key, object[key]);
    }
    return this.http.put<any>(urls.BASE_URL + 'driveralivis/' + driverId, formData)
  }

  updateVehicle(object, vehcileId) {
    const formData = new FormData();
    for (var key of Object.keys(object)) {
      formData.append(key, object[key]);
    }
    return this.http.put<any>(urls.BASE_URL + 'fleetalivis/' + vehcileId, formData)
  }

  sendApprovalEmail(object, id) {
   
    
    return this.http.put<any>(urls.BASE_URL + 'companyalivisemails/' + id, object)
  }
}
