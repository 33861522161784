import { Component, Input, OnInit } from '@angular/core';
import { CompaniesService } from '../../service/companies.service';
import { ActivatedRoute } from '@angular/router';
import { getBrokersList } from 'src/app/utils/utils.common';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-fare-list',
  templateUrl: './fare-list.component.html',
  styleUrls: ['./fare-list.component.css']
})
export class FareListComponent implements OnInit {
  companyId: string;
  fares: any = [];
  search:any;
  brokers = getBrokersList;

  constructor(
    private companyService: CompaniesService, 
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(params => {
      this.companyId = params["id"];
      if (this.companyId) {
        this.getfares();
      }
    });
  }

  getfares() {
    this.companyService
      .getOfferedFaresList(this.companyId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.length) {
          this.fares = data;
        }
    });
  }

  ngOnDestroy(): void {
  }
  
}
