import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AssigTripsService } from 'src/app/components/assigned-trips/assigntrips-service/assigntrips-service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { sweetAlert } from 'src/app/utils/swal';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { environment } from "src/environments/environment";
import { EnterpriseService } from 'src/app/components/medex/enterprises/enterprise-service/enterprise.service';
import { CompaniesService } from 'src/app/components/companies/service/companies.service';
@Component({
  selector: 'app-parse-trip-modal',
  templateUrl: './parse-trip-modal.component.html',
  styleUrls: ['./parse-trip-modal.component.css']
})
export class ParseTripModalComponent implements OnInit, OnDestroy {
  @Input() trip: any;
  @Output() onCloseModal = new EventEmitter<any>();
  user;
  trips;
  selectedFile = null;
  submitted = false;
  htmlText = null;
  selectedCompany = null;
  invalidFileType = false;
  selectedCompanyName = 'epic';
  isDissable = false;
  brokers = [
    {
      name : "EPIC",
      imageURL : "assets/img/epic.png",
       isShow: false,
      value : "epic"
    }
  ]
  enterprises;
  selectedEnterprise = '';

  constructor(private assignTripsService: AssigTripsService,
    private enterpriseService: EnterpriseService,
    private companyService: CompaniesService,
    private sharedData: SharedDataService,
    private router: Router) { }

  ngOnInit() {
    this.sharedData.getUser().subscribe((user) => {
      this.user = user;
      // console.log("This current user logged in", this.user);
    });
    this.getEnterprises();
  }

  getEnterprises(){
    this.enterpriseService.getAllFacilities('facility')
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data)=>{
      if(data){
        this.enterprises = data;
      }
    });
  }
  
  readURL(event: HTMLInputEvent): void {
    if (event.target && event.target.files && event.target.files[0]) {
      this.selectedFile = event.target.files[0];
    }
    console.log(this.selectedFile);
  }


  submitFile() {
    this.submitted = true;
    if (!this.selectedCompanyName) {
      return;
    }
    if (this.isDissable) {
      return;
    }

    if (!this.selectedEnterprise) {
      return;
    }

    this.isDissable = true;
    // let token;
    // if (this.user.platform === "nemt") {
    //   token = environment.FACILITY_NEMT_TOKEN;
    // } else {
    //   token = environment.FACILITY_NEMTROUTES_TOKEN;
    // }
    if (this.selectedFile) {
      if (
        this.selectedFile.type === "application/vnd.ms-excel" ||
        this.selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        this.selectedFile.type === "text/csv"
      ) {
        // this.store.dispatch(new ScrapFile(this.selectedFile, data));
        sweetAlert(
          "Alert",
          "Are you sure you want to parse this file?",
          "warning",
          "Yes",
          "No"
        ).then((result: any) => {
          if(result.value){
            // this.scrapTrips(this.selectedFile, token);
            this.getEnterpriseToken(this.selectedFile, this.selectedEnterprise);
          }
        })
        
      } else {
        this.invalidFileType = true;
      }
    } 
    // else if (this.htmlText) {
    //   // this.store.dispatch(new ScrapFile(this.selectedFile, token));
    //   this.scrapTrips(this.selectedFile, token);
    // }
  }

  getEnterpriseToken(file, enterpriseId) {
    this.companyService.getCompanyToken(enterpriseId, 'facility')
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (data && data.companyToken) {
          this.scrapTrips(file, data.companyToken);
        }
      });
  }

  scrapTrips(file, token) {
   this.assignTripsService
      .parseTrips(file, token)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          sweetAlert("Success", 'File parse successfully', "success", "Ok")
          .then(
            () => {
              this.router.navigateByUrl("/distribution/trips");
            });
        }else{
          this.isDissable = false;
          sweetAlert("Error", 'Operation failed', "error", "Ok");
        }
      }, err => {
        this.isDissable = false;
        sweetAlert("Error", 'Operation failed', "error", "Ok");
      });
  }

  getSelectedBroker(){
    return this.brokers.find(data => data.value === this.selectedCompanyName)
  }

  closeImportModal(){
    this.onCloseModal.emit();
  }

  ngOnDestroy(): void {
  }
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}
