import { environment } from "src/environments/environment"

export class urls {
    static SERVER_URL = environment.baseUrl
    static NEW_SERVER_URL= environment.NEW_SERVER_URL;
    static BASE_URL = environment.baseUrl + 'api/'
    static SIGNIN_URL = 'auth/signin'
    static FORGOT_URL = 'auth/forgot'
    static PROFILE_URL = 'users/me'
    static STAFF_URL = 'staffs'
    static STAFF_CREATE_URL = 'staffs/create'
    static CHANGE_PASSWORD_URL = 'users'
    static CHANGE_PROFILE_IMAGE = 'users/picture'
    static DISPATCH_CENTER_HISTOR_URL = 'dispatches/company'
    static NOTIFICATIONS_URL = 'notifications'
    static DRIVERS_URL = 'drivers/companies'
    static ADD_DRIVER_URL = 'drivers'
    static DRIVER_RESET_PASSWORD_URL = 'drivers/resetpassword'
    static COPORATE_USER_LIST_URL = 'cooperates'
    static COPORATE_ADD_USER_URL = ''
    static FLEET_LIST_URL = 'fleets?'
    static ADD_FLEET_URL = 'fleets'
    static DRIVER_DISTRIBUTION_URL = 'distributions'
    static INVITES_LIST_URL = 'sms'
    static DRIVERS_SMS_URL = 'driveralerts?receiver='
    static QUERIES_URL = 'queries'
    static ADD_VEHICLE_EXPENSE_SERVICE_URL = 'maintenances/services'
    static DRIVER_EXPENSE_URL = 'maintenances/drivers?driver='
    static MAINTENANCE_STATS_URL = 'maintenances/stats'
    static GET_PLANNER_URL = 'planner/range?startDate='
    static GET_NEMT_PRO_TIPS_URL = 'protips'
    static GET_UNASSIGN_TRIPS_URL = 'assigns'
    static GET_MAPVIEW_TRIPS_URL = 'assigns/trips?scheduleTime='
    static GET_OUTSTANDING_TRIPS_URL = 'assigns/outstanding'
    static ASSIGN_MULTIPLE_TRIPS_URL = 'assigns/selectedtrips'
    static GET_ASSIGNED_TRIPS_LIST = 'assigns/corporate'
    static VERIFIED_CANCEL_TRIP_URL = 'assigns/verifiedcancelled'
    static GENRATE_TRIP_SIGNATURE_URL = 'assigns/canvasSignature'
    static UPDATE_ALL_TRIPS_SIGNATURE_URL = 'assigns/autosignature'
    static BANKED_TRIP_URL = 'assigns/banked'
    static SINGLE_TRIP = 'assigns/singletrip'
    static MARK_COMPLETED_TRIP = "assigns/markcompleted"
    static GENERATE_TRIPS_REPORT = "assigns/assignedtripsexcel"
    static SEND_CONFIRMATION_TEXT_URL = "assigns/tripconfirmation"
    static AUTO_TRIP_ADJUSTMENT_URL = "assigns/autotripadjustment"
    static TRIP_ODOMETER_ADJUST_URL = "assigns/adjustodometer"
    static CORPORATE_USER_REPORT_URL = "assigns/corporate/report"
    static GET_CORPORATE_USER_LIST = 'cooperates'
    static GET_CORPORATE_EXCEL_URL = 'cooperates/corporatexcel'
    static CREATE_NEW_TRIP_URL = 'assigns'
    static NEMT_STATISTICS_URL = 'kpis/jobs/stats'
    static NEMT_REPORTS_URL = 'kpis'
    static ADMIN_NEMT_REPORTS_URL = 'companies'
    static ADMIN_NEMT_REPORTS_BY_DATE_URL = 'adminkpis'
    static NEMT_STATISTICS_DETAIL_URL = 'kpis/jobs/statsByRange'
    static NEMT_STATISTICS_DETAIL_BY_MEMBER_ID_URL = 'kpis/jobs/statsdetails'
    static GET_DYNAMIC_FARE_LIST = 'dynamicfares'
    static GET_REPORTS_URL = 'reports/company/'
    static GET_TRIP_DETAILS_URL = 'dispatches'
    static DRIVER_TRIP_FARE_URL = 'dispatches/driverfare'
    static GET_ADJUSTMENT_DETAIL_URL = 'jobs/adjustment/'
    static GET_ADJUSTMENT_DETAIL_TRIPSLOGS_URL = 'adjusttrips/'
    static GET_NEARBY_DRIVER_URL = 'drivers/nearby'
    static TRIPS_FOR_ASSIGN_DASHBOARD_URL = 'assigns/dashboard'
    static DRIVER_GENERATE_QR_URL = 'drivers/generateqr'
    static DRIVER_CALCULATE_INVOICE_URL = 'driverinvoices'
    static DRIVER_TIMELINES_URL = 'timelines'
    static NEMT_STAT_REPORT_PDF_URL = 'kpis/kpistatsreport'
    static NEMT_STAT_TRIP_REPORT_XLS_URL = 'kpis/kpitripsreport'
    static NEMT_BUSSINESS_REPORT_PDF_URL = 'kpis/businesskpi'
    static NEMT_GMT_KPI_REPORT_PDF_URL = 'kpis/gmt'
    static NEMT_DRIVER_KPI_REPORT_PDF_URL = 'kpis/driverkpi'
    static NEMT_BROKER_REPORT_PDF_URL = 'kpis/brokerkpi'
    static NEMT_CORPORATE_REPORT_PDF_URL = 'kpis/corporatekpi'
    static NEMT_CORPORATE_TRIP_REPORT_PDF_URL = 'kpis/corporateTrips'
    static NEMT_DRIVER_REPORT_PDF_URL = 'kpis/driverkpi'
    static SINGLE_FLEET_QR_URL = 'fleets/generateqr/'
    static ALL_FLEET_QR_URL = 'fleets/fleetqr'
    static GET_DRIVER_PDF_REPORT_URL = 'reports/driver/'
    static GET_PAYMENT_PDF_REPORT_URL = 'reports/admin/'
    static GET_BROKER_RATE_URL = 'assigns/jobbyid?jobId='
    static SCRAP_TRIPS_URL = 'assigns/scrap'
    static DRIVER_BREAKDOWN_URL = 'kpis/bussiness'
    static GET_SWEEP_TRIPS_LIST_URL = 'assigns/parselist'
    static GET_UNPARSED_TRIPS_URL = 'assigns/unparsedtrips'
    static SEND_TRIP_LIST_EMAIL_URL = 'assigns/files/'
    static ACCIDENT_REPORT_URL = 'accidentalreports'
    static FACILITY_TRIPS_URL = 'facilitytrips'
    static FACILITY_TRIPS_COUNTER_URL = 'facilitytrips/dashboard'
    static PARSE_FACILITY_TRIPS = 'facilitytrips/parse'
    static FACILITY_TRIPS_LOGS = 'facilitytrips/logs'
    static MEMBER_URL = 'members'
    static EXPENSE_URL = 'expenses'
    static TRACKING_SMS_URL = 'trackingsms'
    static GET_CTC_TRIPS = 'brokersettings/ctc'
    static GET_MTM_TRIPS = 'brokersettings/mtm'
    static BILLED_TO_MODIVCARE_URL = 'assigns/billedtomodivcare'
    static BROKER_SETTING_URL = 'brokersettings'
    static GET_COMPANIES_LIST = 'companies'
    static GET_QUARTER_LIST = 'quarterpanels'
    static GET_INVOICE_LIST = 'companies'
    static GET_COMPANY_DATA_URL = 'providerinvoicesettings'
    static GET_TRIP_ASSISTANCE_LIST = 'tripassistances'
    // ---CORPORATE ADDON---
    static GETCORPORATEADDON = 'addonservices'
    static GET_CORPORATE_ADDON_SERVICES = 'addonservices/corporate?isdefault=true'
    // signup request companies
    static SIGNUP_REQUESTS = 'signuprequests'
    static GET_ALIVIS_COMPANIES = 'companyalivis'
    static GET_ALIVIS_COMPANIES_DETAILS = 'companyalivisdetail'
    static GET_STATISTICS = 'assigns/insurancepanelstats'
    static GET_COMPANY_TOKEN = 'getCompanyToken'
    static GET_OPERATIONS = 'assigns/operations'
    static GET_ALARMING_TRIPS = 'assigns/alarmingtrips'
    //FARE SECTION
    static GET_OFFERED_FARELIST = 'offeredfares?company='
    static GET_OFFERED_FAREBYID = 'offeredfares/'
    static GET_CREATE_OFFERED_FARE = 'offeredfares'
    static GET_DRIVERS_LIST = 'driveralivis/'
    static GET_VEHICLES_LIST = 'fleetalivis'
    static LEVEL_OF_SERVICE = 'vehicles'
    // Distribution Reports
    static PDF_REPORT_URL = 'http://pdfserver.qalbs.com/api/';

}
