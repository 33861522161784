import { Component, Input, OnInit } from '@angular/core';
import { OnboardingService } from '../onboarding-service/onboarding.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { StaffService } from '../../staff/staff.service';
import { getFileNameFromUrl, companyFieldsList, fillingStatus} from '../../../utils/utils.common';
import * as moment from 'moment';

@Component({
  selector: 'app-onboarding-company',
  templateUrl: './onboarding-company.component.html',
  styleUrls: ['./onboarding-company.component.css']
})
export class OnboardingCompanyComponent implements OnInit {
  companyId: any;
  approveForm: FormGroup;
  companyDetail: any;
  selectedCompanyId: any;
  staffList: any;
  submitted = false;

  constructor(
    private toastr: ToastrService,
    private staffService: StaffService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private onboardingService: OnboardingService
  ) {}

  ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params : any) => {
        this.companyId = params["id"];
      });

    this.approveForm = this.formBuilder.group({
      preApprovedDate: [""],
      approvedDate: ["",Validators.required ],
      approvedBy: ["", Validators.required],
      isApproved: [false],
    });

    this.getCompanydetail();
    this.getStaffList();
  }
  getCompanydetail() {
    this.onboardingService
      .getCompaniesDetails(this.companyId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (data) {
          this.companyDetail = data;
          this.selectedCompanyId = data.company;
          this.approveForm.patchValue({
            preApprovedDate: this.companyDetail.approvedDate,
            approvedDate: this.companyDetail.approvedDate,
            approvedBy: this.companyDetail.approvedBy
              ? this.companyDetail.approvedBy
              : "",
            isApproved: this.companyDetail.isApproved,
          });
        }
      });
  }

  getStaffList() {
    this.staffService
      .getStaffData("")
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        this.staffList = data;
      });
  }

  fillingStatus(object) {
    return fillingStatus(object);
  }

  getFileName(url) {
    return getFileNameFromUrl(url);
  }

  companyFieldsList() {
    return companyFieldsList();
  }

  approveDateChange(event) {
    const date = moment.utc(event).local().format();
    this.approveForm.patchValue({
      approvedDate: date,
    });
  }

  updateCompany(name, value) {
    let object = JSON.parse(JSON.stringify(this.companyDetail));
    object[name] = value;
    const errorList = [];
    for (let item of companyFieldsList()) {
      for (let sub in item) {
        if (!object[sub]) {
          errorList.push(item[sub]);
        }
      }
    }
    const object1 = {
      [name]: value,
      fillingStatus: errorList.length > 0 ? true : false,
    };
    this.sendCompanyServerRequest(object1, this.companyId);
  }


  sendCompanyServerRequest(object, id) {
    this.onboardingService
      .updateCompany(object, id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.companyDetail = data;
          this.approveForm.patchValue({
            preApprovedDate: this.companyDetail.approvedDate,
            approvedDate: this.companyDetail.approvedDate,
            approvedBy: this.companyDetail.approvedBy
              ? this.companyDetail.approvedBy
              : "",
            isApproved: this.companyDetail.isApproved,
          });
          // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Update Successfully');
          this.toastr.success("Update Successfully", "Alert");
        }
      });
  }

  onSubmit() {
    this.submitted = true
    if (this.approveForm.invalid) {
     return;
    } else{
      this.sendCompanyApprovalEmail()
    }
  }

  sendCompanyApprovalEmail() {
    const object = {
      ...this.approveForm.value,
      isApproved: this.companyDetail.isApproved? false: true ,
      _id: this.companyDetail._id,
      quarterUser: this.staffList.find(data => data._id === this.approveForm.value.approvedBy).displayName,
      profileMessage: `Company ${this.companyDetail.companyName}`,
      method: 'alivicompany' // alivicompany alividriver alivifleet
    }
    this.onboardingService.sendApprovalEmail(object, this.selectedCompanyId)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data) {
        this.companyDetail = data;
        // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Send Email Successfully');
        this.toastr.success('Send Email Successfully', 'Alert');
      }
    } ,err => {
      this.toastr.warning('Duplicate Key Found.','Alert');
    });

  }
  sendCompanyErrorEmail() {
    const errorList = [];
    for (let item of companyFieldsList()) {
      for (let sub in item) {
        if (!this.companyDetail[sub]) {
          errorList.push(item[sub]);
        }
      }
    }
    const object = {
      profileMessage: `Company ${this.companyDetail.companyName}`,
      errorList,
    };
    this.onboardingService
      .sendErrorEmail(object, this.selectedCompanyId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Send Email Successfully');
          this.toastr.success("Send Email Successfully", "Alert");
        }
      });
  }
  get form() {
    return this.approveForm.controls;
  }
  ngOnDestroy(): void {}

}
