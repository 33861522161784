import { Component, OnDestroy, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { LevelOfServiceService } from '../service/level-of-service.service';
import { sweetAlert } from 'src/app/utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-level-of-service',
  templateUrl: './level-of-service-list.component.html',
  styleUrls: ['./level-of-service-list.component.css']
})
export class LevelOfServiceComponent implements OnInit, OnDestroy {
  search;
  vehicles =[];
  user;
  status="active"

  constructor(
    private levelOfServiceService: LevelOfServiceService
  ) { }

  ngOnInit() {
    this.getVehicles();
  }

  onChangeStatus(evt) {
    this.getVehicles();
  }
  getVehicles() {
      const queryParams = {
        status: this.status
      }
      this.levelOfServiceService
        .getVehicles(queryParams)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data && data.length) {
            this.vehicles = data;
            console.log('Vehicles: ', this.vehicles);
          }else{
            this.vehicles = [];
          }
        }, err => {
          sweetAlert("Error", err, "error", "Ok");
        });
    }
    
    ngOnDestroy(): void {
      
    }
}
