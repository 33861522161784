import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { sweetAlert } from '../../../utils/swal';
import { AuthService } from '../../../service/auth-service/auth.service';
import { imageProcessing, ImageSnippet } from '../../../utils/image-processing-utils';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { UserProfileService } from '../user-profile-service/user-profile.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: ['./edit-user-profile.component.css']
})
export class EditUserProfileComponent implements OnInit {

  editProfileForm: FormGroup;
  submitted = false;
  selectedFile: ImageSnippet;
  userData = null;


  image = null;

  timeZone = 'America/New_York';

  placeholderString = 'Select timezone';


  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  toggleCurrentPassword = true;
  toggleNewPassword = true;
  toggleConfirmPassword = true;
  user;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private sharedDataService: SharedDataService,
    private userProfileService: UserProfileService
  ) {
  }



  /*
    changes in CSS of select2 node module
    In select2.css file

    comment line height in the following selector
    .select2-container--default .select2-selection--single .select2-selection__rendered

    comment border style in the following selector
    .select2-container--default .select2-selection--single .select2-selection__arrow b
  */

  get form() {
    return this.editProfileForm.controls;
  }

  ngOnInit() {
    this.getUser();

    this.editProfileForm = this.fb.group({
      displayName: [{ value: '', disabled: true }, Validators.required],
      email: [{ value: '', disabled: true }, Validators.required],
      address: [{ value: '', disabled: true }, Validators.required],
      timeZone: [this.timeZone, Validators.required],
      contactNumber: [{ value: '', disabled: true }, Validators.required],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      cpassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      latitude: [{ value: '', disabled: true }, Validators.required],
      longitude: [{ value: '', disabled: true }, Validators.required]

    });
    
    this.setUpView();

  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone
          this.userData = user;
        }
      });
  }

  afterEditSuccess() {
    this.selectedFile = null;
    // this.userData = this.authService.getUserData();
    this.editProfileForm.reset();
    this.submitted = false;
    this.setUpView();
  }

  setUpView() {

    if (this.userData != null) {
      

      this.editProfileForm.patchValue({
        ...this.userData
      });
      this.image = this.userData.profileImageURL;
    }

  }

  togglePassword(fieldName: string) {
    switch (fieldName) {
      case 'current':
        this.toggleCurrentPassword = !this.toggleCurrentPassword;
        break;
      case 'new':
          this.toggleNewPassword = !this.toggleNewPassword;
          break;
      case 'confirm':
          this.toggleConfirmPassword = !this.toggleConfirmPassword;
          break;
    }
  }

  processFile(imageInput: any) {
    this.submitted = false;
    imageProcessing(imageInput)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((result) => {
      this.selectedFile = result.file;
      this.image = result.src;
      this.uploadImage();
    });
  }

  changeTimezone(timezone) {
    this.timeZone = timezone;
    this.editProfileForm.patchValue({ timeZone: timezone });
  }

  onEditProfileSubmit() {
    this.submitted = true;
    if (this.editProfileForm.invalid) {
      return;
    }

    if (this.editProfileForm.value.password !== this.editProfileForm.value.cpassword) {
      return;
    }

    this.userProfileService
        .ChangePassword(this.editProfileForm.value)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data.success) {
            sweetAlert('Success', 'Password Updated Successfully', 'success', 'OK');
            this.afterEditSuccess();
          } else {
            sweetAlert('Error', data.message, 'warning', 'OK');
          }
        }, err => {
          sweetAlert('Error', err, 'warning', 'OK');
          this.selectedFile = null;
          this.submitted = false;
          this.setUpView();
        });
  }


  uploadImage() {
    if (this.selectedFile) {
      this.submitted = false;
      // this.store.dispatch(new UploadProfileImage(this.selectedFile));
      this.userProfileService
        .changeProfileImage(this.selectedFile)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data && data.user) {
            console.log(data);
            // this.authService.CheckLoginValidation(data, true);
            this.sharedDataService.saveUser({...this.user, profileImageURL: data.user.profileImageURL});
            this.afterEditSuccess();
            this.image = data.user.profileImageURL;
            sweetAlert('Success', 'Image Uploaded Successfully', 'success', 'OK');
          }
        }, err => {
          sweetAlert('Error', err, 'warning', 'OK');
          this.selectedFile = null;
          this.submitted = false;
          this.setUpView();
        });
    }
  }

  clearImage() {
    this.selectedFile = null;
    this.image = this.userData.profileImageURL;
  }
  getAddress(place) {
    this.editProfileForm.patchValue({
      currentAddress: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng()
    });

  }

  ngOnDestroy(): void { }

}
