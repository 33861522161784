import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AlarmingService } from '../service/alarming.service';
import { getBrokersList, getJobStatus } from '../../../utils/utils.common';
import { HttpParams } from '@angular/common/http';
import { CompaniesService } from '../../companies/service/companies.service';
import * as moment from "moment-timezone";
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-alarming',
  templateUrl: './alarming.component.html',
  styleUrls: ['./alarming.component.css']
})
export class AlarmingComponent implements OnInit, OnDestroy {
  @ViewChild('timer', { static: false }) timer;
  currentTime = moment().toISOString();
  duration = 10 * 1000; // 10 seconds
  alarmingTrips = [];
  brokersList = getBrokersList;
  getTripJobStatus = getJobStatus;
  selectedBroker = '';
  notesModalObj;
  companiesList;
  companiesListObj
  companies;
  selectedCompanies = [];
  view = "list";
  filterCompaniesList: any;
  search:any;
  live;
  intervalTimeID;
  intervalTripID;
  isShowCompanySelection = false;
  form: FormGroup;
  companyFilter;
  timeZone = 'America/New_York';

  constructor(
    private companyService: CompaniesService,
    private alarmingService: AlarmingService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      companyIds: this.fb.array([])
    });

    this.getCompaniesList();

    this.getAlarmingTrips();
  }

  getBroker(broker) {
    return this.brokersList[broker];
  }

  getCompaniesList() {
    const queryParams = {
      accountStatus: 'active',
      isRemoveIAAL: false
    }

    this.companyService.getCompanies(queryParams)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      if (data) {
        this.companiesList = data;
        this.companies = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
      }
    })
  }

  getAlarmingTrips() {
    let queryParams;
    const companyIds = this.form.value.companyIds;
    if(companyIds && companyIds.length) {
      queryParams = {
        companyIds: companyIds.toString()
      }
    }

    this.alarmingService.getAlarmingTrips(queryParams)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      if (data && data.assigns && data.assigns.length) {
        this.alarmingTrips = data.assigns;
      }
      else {
        this.alarmingTrips = [];
      }
      this.timer.start();
    }, err => {
      this.timer.start();
    });
  }

  getCompanyById(companyId) {
    if (this.companies.hasOwnProperty(companyId)) {
      return this.companies[companyId];
    }
    return { displayName: "--", timeZone: 'America/New_York' }
  }

  onDoneCompanySelection() {
    this.selectedBroker = '';
    this.selectedCompanies = [];

    const companyIds = this.form.value.companyIds;
    if (companyIds && companyIds.length) {
      this.selectedCompanies = this.companiesList.filter(company => companyIds.includes(company._id));
    }
    this.getAlarmingTrips();
    this.isShowCompanySelection = false;
  }

  onCompanyCheckboxChange(e) {
    const companyIds: FormArray = this.form.get('companyIds') as FormArray;

    if (e.target.checked) {
      companyIds.push(new FormControl(e.target.value));

    } else {
      let i: number = 0;
      companyIds.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          companyIds.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  isCompanySelected(companyId) {
    const companiesIds = this.form.value.companyIds;
    let found = false;
    if (companiesIds) {
      if (companiesIds.indexOf(companyId) > -1) {
        found = true;
      }
    }

    return found;
  }

  viewNotes(trip) {
    this.notesModalObj = trip;
  }

  ngOnDestroy() {
    if (this.intervalTimeID) {
      clearInterval(this.intervalTimeID);
    }
    if (this.intervalTripID) {
      clearInterval(this.intervalTripID);
    }
  }

  fetchTripsByCompanies() {
    this.alarmingService
      .getTripsByCompanies(this.filterCompaniesList.toString())
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.alarmingTrips = data.assigns;
      })
  }

  onTimerComplete(): void {
    this.timer.init();
    this.getAlarmingTrips();
  }
  //------------
  // const companiesList = this.companiesList.map(id => {
  //   const company = this.filterCompaniesList.find(c => c.id === id);
  //   return company ? company.displayName : 'Company not found';
  // });

  // onChangeCompanySelection(event) {
  //   // console.log(event)
  //   // all selected companies id in filterCompaniesList
  //   this.filterCompaniesList = event;
  //   console.log(this.filterCompaniesList)
  //   this.filterCompaniesName = this.companiesList.filter(company => this.filterCompaniesList.includes(company._id));
  //   this.fetchTripsByCompanies();

  // }
}
