import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { LevelOfServiceService } from "../service/level-of-service.service";
import { imageProcessing } from "src/app/utils/image-processing-utils";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { takeUntil } from "rxjs/operators";
import { sweetAlert } from "src/app/utils/swal";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";

@Component({
  selector: "app-add-level-of-service",
  templateUrl: "./add-level-of-service.component.html",
  styleUrls: ["./add-level-of-service.component.css"],
})
export class AddLevelOfServiceComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  vehicleId;
  vehicleImageFile;
  vehicle;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private vehicleService: LevelOfServiceService,
    private sharedDataService: SharedDataService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {

    this.formGroup = this.fb.group({
      name: ["", Validators.required],
      status: ["active", Validators.required],
      profileImageURL:["", Validators.required],
    });


    this.vehicleId = this.activatedRoute.snapshot.paramMap.get("id");

    if (this.vehicleId) {
      this.getVehicle();
    }

  }


  getVehicle() {
    this.vehicleService.getVehicleById(this.vehicleId)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data) {
        this.vehicle = data;
        this.formGroup.patchValue(data);

      }
    });
  }

  submit() {
    this.isSubmitted = true;
    if (this.formGroup.invalid) {
      return;
    }

    const payload = this.formGroup.value;
    if (this.vehicleImageFile) {
      payload['newProfilePicture'] = this.vehicleImageFile;
    }
    console.log("Valid Form: ", payload);
    this.vehicleService
      .saveVehicle(this.vehicleId, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.router.navigateByUrl("credentialing/levelofservice");
        }
      }, err => {
        sweetAlert("Error", err, "error", "Ok");
      });
  }

  processFile(imageInput: any) {
    imageProcessing(imageInput)
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((result) => {
        if (result) {
        this.vehicleImageFile = result.file;
          this.formGroup.patchValue({
            profileImageURL :  result.src
          });
        } else {
          this.clearImage();
        }
      });
  }

  clearImage() {
    this.vehicleImageFile = null;
    this.formGroup.patchValue({
      profileImageURL : ""
    });

  }

  get form() {
    return this.formGroup.controls;
  }
  ngOnDestroy(): void { }
}
