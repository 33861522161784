import { Component, OnInit } from '@angular/core';
import { StaffService } from '../staff.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.css']
})
export class StaffListComponent implements OnInit {
  staffList:any = [];
  search:any = '';
  status = 'active';

  constructor(
    private staffService:StaffService
  ) { }

  ngOnInit() {
    this.staffService
      .getQuarterlist()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
      if(data) {
        this.staffList = data;
      }
    });
  }

  ngOnDestroy(): void { }
  
}
