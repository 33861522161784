import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { errorHandl, setFormData, setFormDataV2 } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class LevelOfServiceService {

  constructor(private http: HttpClient) { }

  getVehicles(queryParams) {
    const params = new HttpParams({fromObject: queryParams});
    return this.http.get<any>(urls.BASE_URL + urls.LEVEL_OF_SERVICE, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  saveVehicle(vehicleId, payload) {
    const formData = setFormDataV2(payload, 'vehicle');

    if (vehicleId) {
      // Edit
      return this.http.put<any>(urls.BASE_URL + urls.LEVEL_OF_SERVICE +`/${vehicleId}`, formData)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      // Add
      return this.http.post<any>(urls.BASE_URL + urls.LEVEL_OF_SERVICE, formData)
        .pipe(
          catchError(errorHandl)
        );
    }
  }

  getVehicleById(vehicleId) {
    return this.http.get<any>(urls.BASE_URL + urls.LEVEL_OF_SERVICE + `/${vehicleId}`)
      .pipe(
        catchError(errorHandl)
      );
  }
}
