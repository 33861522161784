import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { getBrokersList, getJobStatus } from '../../../utils/utils.common';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { CompaniesService } from '../../companies/service/companies.service';
import * as moment from 'moment-timezone';
import { sweetAlert } from 'src/app/utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { Events } from 'src/app/utils/event-utils';
declare var _: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('timer', { static: false }) timer;
  duration = 10 * 1000; // 10 seconds
  companies: any;
  currentTime = moment().toISOString();
  intervalTimeID;
  assignModalObj;
  notesModalObj;

  todayDate
  selectedCompanyId = "Select Company";
  scheduleTime: "2023-06-14T19:00:00.000Z";
  selectedCompany: any;
  brokersList = getBrokersList;
  timeZone = "America/New_York";
  getTripJobStatus = getJobStatus;
  pretimePickerDate = moment(moment(), "YYYY-MM-DD").startOf("day").format();
  timePickerDate = moment(moment(), "YYYY-MM-DD").startOf("day").format();
  cancelReasonModalObj;
  search: any;
  live;

  intervalId;
  subscription;

  trips = [];
  markedReadyTrips = [];
  alarmingTrips = [];
  inprogressTrips = [];
  scheduledTrips = [];
  cancelledTrips = [];
  finishedTrips = [];
  companiesByKey;
  tripDetailObj;
  companyDateTime:any;
  updateInterval:any;

  constructor(
    private sharedDataService: SharedDataService,
    private dashboardService: AssigTripsService,
    private companyService: CompaniesService,
    private socketService: SocketService
  ) { }

  ngOnInit() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((user) => {
        if (user) {
          this.timeZone = user.timeZone;
        }
      });
    this.getCompanies();
    this.listenSocket();
  }

  // OK
  getCompanies() {
    const queryParams = {
      accountStatus: 'active',
      isRemoveIAAL: false
    };
    this.companyService
      .getCompanies(queryParams)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.companies = data;
          this.companies = _.sortBy(this.companies, "displayName");
          this.companiesByKey = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
          
          
          // For Testing
          // this.selectedCompanyId = "5fc094ab27d56e28615f674d";
          // this.onChangeCompany();
        }
      });
  }

  listenSocket() {
    this.socketService.addListener(Events.GET_MARK_READY_NOTIFICATION)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.assign) {
          this.clear();
          this.getTrips();
        }
      });
  }

  onChangeCompany() {
    this.clear();
    this.stopInterval();
    this.selectedCompany = this.companies.find(company => company._id === this.selectedCompanyId);
    if (this.selectedCompany) {
      this.startUpdatingTime();
      this.timeZone = this.selectedCompany.timeZone;
      this.companyDateTime = moment().tz(this.timeZone).format('MM/DD - HH:mm:ss');
      this.getTrips();
      // this.startInterval();
    }
  }

  clear() {
    this.markedReadyTrips = [];
    this.alarmingTrips = [];
    this.inprogressTrips = [];
    this.scheduledTrips = [];
    this.cancelledTrips = [];
    this.finishedTrips = [];
  }

  onChangeDate(event) {
    const pickerDate = moment(event, "YYYY-MM-DD").format();
    if (event) {
      this.timePickerDate = pickerDate;
    }
    // this.todayDate = event.toISOString();
    this.onChangeCompany();
  }

  getTrips() {
    const payload = {
      company: this.selectedCompany,
      scheduleTime: moment
        .tz(this.timePickerDate, "YYYY-MM-DD", this.timeZone)
        .toISOString(),
    }
    this.subscription = this.dashboardService
      .getCompaniesTrips(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.clear();
        if (data && data.assigns && data.assigns.length) {
          const trips = data.assigns;
          const currentTime = data.currentTime;
          this.segregateTrips(trips, currentTime);
        }
        this.timer.start();
      }, err => {
        this.timer.start();
        this.clear();
      });
  }

  segregateTrips(trips, currentTime) {
    trips.forEach((trip) => {
      const time = this.calculateTimeDifference(trip, currentTime);

      if (trip.status === "inprogress") {
        this.inprogressTrips.push(trip);
      } else if (trip.status === "finished") {
        this.finishedTrips.push(trip);
      } else if (trip.status === "cancelled") {
        this.cancelledTrips.push(trip);
      } else if (time <= 60 || trip.status === "declined" || trip.status === "bumped") {
        this.alarmingTrips.push(trip);
        // check if the trip is marked ready
        if (trip.isReadyForPickup) this.markedReadyTrips.push(trip);
      } else if (time > 60) {
        this.scheduledTrips.push(trip);
        // check if the trip is marked ready
        if (trip.isReadyForPickup) this.markedReadyTrips.push(trip);
      }
    });
  }

  calculateTimeDifference(assign, currentTime) {
    // calculate time difference from current time (server provided) 
    var end = moment(assign.scheduleTime);
    var startTime = moment(currentTime);
    var duration = moment.duration(end.diff(startTime));
    return duration.asMinutes();
  }

  expiringSoonTime(trip) {
    const now = moment().tz(this.timeZone).format();
    const expiringSoonTime = moment(trip.scheduleTime);
    return expiringSoonTime.diff(now, "minutes");
  }

  getETA(trip, objectName) {
    this.companyService
      .getETA(trip._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (this[objectName] && this[objectName].length) {
          const selectedTrip = this[objectName].find((x) => x._id === data._id);
          if (selectedTrip) {
            selectedTrip.etaMessage = data.etaMessage;
          }
        }
      });
  }

  getBroker(company) {
    return this.brokersList[company];
  }

  startInterval() {
    // 15000 (15 seconds)
    this.intervalId = setInterval(() => {
      this.getTrips();
    }, 15000);
  }
   startUpdatingTime() {
    // Update time every second
    this.updateInterval = setInterval(() => {
      this.companyDateTime = moment().tz(this.timeZone).format('MM/DD - HH:mm:ss');
    }, 1000);
  }

  stopInterval() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    clearInterval(this.intervalId);
    this.intervalId = null;
  }

  trackByFn(index, item) {
    if (!item) return;
    return item._id; // index or item.id
  }

  trackByAlarmingFn(index, item) {
    if (!item) return;
    return item._id; // index or item.id
  }

  trackByActiveFn(index, item) {
    if (!item) return;
    return item._id; // index or item.id
  }

  trackByScheduledFn(index, item) {
    if (!item) return;
    return item._id; // index or item.id
  }

  trackByCancelledFn(index, item) {
    if (!item) return;
    return item._id; // index or item.id
  }

  trackByFinishedFn(index, item) {
    if (!item) return;
    return item._id; // index or item.id
  }

  cancelInprogress(trip) {
    this.cancelReasonModalObj = trip;
  }

  closeCancelModal(cancelReason) {
    if (!cancelReason) {
      this.cancelReasonModalObj = null;
      return;
    }


    const companyId = this.selectedCompany ? this.selectedCompany._id : '';
    if (!companyId) {
      return;
    }
      
    this.companyService
      .getCompanyToken(companyId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data:any) => {
        if (data) {
          const token = data.companyToken;
          if (token) {
            this.cancelTripRequest(cancelReason, token);
          }
        }
      });
  }

  cancelTripRequest(cancelReason, token) {
    this.cancelReasonModalObj.changeReason = cancelReason;
    this.cancelReasonModalObj.status = "cancelled";
    this.cancelReasonModalObj.jobStatus = "cancelled";

    this.dashboardService
      .cancelTrip(this.cancelReasonModalObj, token)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.cancelReasonModalObj = null;
          sweetAlert('Success', "Inprogress trip cancelled successfully", 'success', 'OK')
          this.getTrips();
        }
      });
  }

  markComplete(trip) {
    sweetAlert(
      "Alert",
      "Are you sure you want to complete this trip?",
      "warning",
      "Yes",
      "No"
    ).then((result: any) => {
      if(result.value){
      const companyId = this.selectedCompany ? this.selectedCompany._id : '';
      if (!companyId) {
        return;
      }
      this.companyService
        .getCompanyToken(companyId)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((data: any) => {
          if (data) {
            const token = data.companyToken;
            if (token) {
              this.markCompleteRequest(trip, token);
            }
          }
        });       
      }
    })
  }

  markCompleteRequest(trip, token) {
    this.dashboardService
      .markCompleteTrip(trip, token)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', "Marked Complete", 'success', 'OK')
          this.getTrips();
        }
      });
  }

  openAssignModal(trip) {
    this.assignModalObj = trip;
  }

  onCloseAssignModal(evt) {
    if (evt) {
      this.getTrips();
    }
    this.assignModalObj = null;
  }

  cancelModal(trip) {
    this.cancelReasonModalObj = trip;
  }

  viewNotes(trip) {
    this.notesModalObj = trip;
  }

  viewTripDetails(trip) {
    this.tripDetailObj = trip;
  }

  onTimerComplete(): void {
    this.timer.init();
    this.getTrips();
  }

  onClearCompany() {
    this.selectedCompanyId = "";
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    clearInterval(this.updateInterval);
    this.socketService.removeListener(Events.GET_MARK_READY_NOTIFICATION);
  }
}
