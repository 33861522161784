import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { errorHandl } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseService {
  constructor(private http: HttpClient) { }

  getAllFacilities(profileRole = 'facility') {
 
    return this.http.get<any>(urls.BASE_URL + "facilities?profileRole=" + profileRole);
  }

  createNewFacility(facility, image , facilityUserId) {
    const formData: FormData = new FormData();
    Object.keys(facility).forEach(key => {
      formData.append(key, facility[key]);
    })
    formData.append('newProfilePicture', image)
    if(facilityUserId){
      return this.http.put(urls.BASE_URL + `facilities/${facilityUserId}`, formData)
    }else{
      return this.http.post(urls.BASE_URL + "facilities", formData)
        .pipe(catchError(errorHandl));
    }
  }

  getFacilityToEdit(facilityId) {
    return this.http.get <any>(urls.BASE_URL + `facilities/${facilityId}`);
  }

}
