import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { StaffService } from '../../staff/staff.service';
import { ActivatedRoute } from '@angular/router';
import { OnboardingService } from '../onboarding-service/onboarding.service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { fillingStatus, getFileNameFromUrl, vehicleFieldsList } from 'src/app/utils/utils.common';
import * as moment from 'moment';

@Component({
  selector: 'app-onboarding-vehicle',
  templateUrl: './onboarding-vehicle.component.html',
  styleUrls: ['./onboarding-vehicle.component.css']
})
export class OnboardingVehicleComponent implements OnInit {
  @Input() company
  selectedVehicle: any;
  vehicles: any;
  approveForm: FormGroup;
  search
  staffList: any;
  submitted = false;

  constructor(
    private toastr: ToastrService,
    private staffService: StaffService,
    private formBuilder: FormBuilder,
    private onboardingService: OnboardingService) { }

  ngOnInit() {
    this.approveForm = this.formBuilder.group({
      preApprovedDate: [""],
      approvedDate: ["", Validators.required],
      approvedBy: ["", Validators.required],
      isApproved: [false],
    });
    this.getVehicles();
    this.getStaffList();
  }

  getVehicles() {
    this.onboardingService
      .getVehiclesList(this.company)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (data) {
          this.vehicles = data;
          this.onSelectVehicle(this.vehicles[0]);
        }
      });
  }

  onSelectVehicle(vehicle) {
    if(vehicle && vehicle._id){
      this.onboardingService
      .getVehicleById(vehicle._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (data) {
          this.selectedVehicle = data;
          this.approveForm.patchValue({
            preApprovedDate: this.selectedVehicle.approvedDate,
            approvedDate: this.selectedVehicle.approvedDate,
            approvedBy: this.selectedVehicle.approvedBy
              ? this.selectedVehicle.approvedBy
              : "",
            isApproved: this.selectedVehicle.isApproved,
          });
        }
      });
    }

  }


  getFileName(url) {
    return getFileNameFromUrl(url);
  }

  endVehicleServerRequest(object, id) {
    this.onboardingService.updateVehicle(object, id)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data) {
        const objIndex = this.vehicles.findIndex((obj => obj._id === data._id));
        this.vehicles[objIndex] = data;
        this.selectedVehicle = data;
        this.approveForm.patchValue({
          preApprovedDate: this.selectedVehicle.approvedDate,
          approvedDate: this.selectedVehicle.approvedDate,
          approvedBy: this.selectedVehicle.approvedBy ? this.selectedVehicle.approvedBy : '',
          isApproved: this.selectedVehicle.isApproved
        });
        // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Update Successfully');
        this.toastr.success('Update Successfully','Alert');
      }
    });
  }

  getStaffList() {
    this.staffService
      .getStaffData("")
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        this.staffList = data;
      });
  }

  fillingStatus(object) {
    return fillingStatus(object);
  }

  vehicleFieldsList() {
    return vehicleFieldsList();
  }

  onSubmit() {
    this.submitted = true
    if (this.approveForm.invalid) {
      return;
    } else{
      this.sendVehicleApprovalEmail()

    }
  }

  approveDateChange(event) {
    const date = moment.utc(event).local().format();
    this.approveForm.patchValue({
      approvedDate: date,
    });
  }

  sendVehicleErrorEmail() {
    const errorList = [];
    for (let item of vehicleFieldsList()) {
      for (let sub in item) {
        if (!this.selectedVehicle[sub]) {
          errorList.push(item[sub]);
        }
      }
    }
    const object = {
      profileMessage: `Vehicle VIN #${this.selectedVehicle.vin} `,
      errorList
    }
    this.onboardingService.sendErrorEmail(object, this.company)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data) {
        // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Send Email Successfully');
        this.toastr.success('Send Email Successfully','Alert');
      }
    });
  }

  updateVehicle(name, value) {
    let object = JSON.parse(JSON.stringify(this.selectedVehicle));
    object[name] = value;
    const errorList = [];
    for (let item of vehicleFieldsList()) {
      for (let sub in item) {
        if (!object[sub]) {
          errorList.push(item[sub]);
        }
      }
    }
        const object1 = {
          [name]: value,
          fillingStatus: errorList.length > 0 ? true : false
        }
        this.sendVehicleServerRequest(object1, this.selectedVehicle._id);
  }

  sendVehicleApprovalEmail() {
    const object = {
      ...this.approveForm.value,
      isApproved: this.selectedVehicle.isApproved ? false : true,
      _id: this.selectedVehicle._id,
      quarterUser: this.staffList.find(e => e._id === this.approveForm.value.approvedBy).displayName,
      profileMessage: `Vehicle VIN #${this.selectedVehicle.vin} `,
      method :'alivifleet' // alivicompany alividriver alivifleet
    }
    this.onboardingService.sendApprovalEmail(object,  this.company)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data) {
        const objIndex = this.vehicles.findIndex((obj => obj._id === data._id));
        this.vehicles[objIndex] = data;
        this.selectedVehicle = data;
        // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Send Email Successfully');
        this.toastr.success('Send Email Successfully','Alert');
      }
    }, err => {
      this.toastr.warning('Duplicate Key Found.','Alert');
    });
  }
  sendVehicleServerRequest(object, id) {
    this.onboardingService.updateVehicle(object, id)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data) {
        const objIndex = this.vehicles.findIndex((obj => obj._id === data._id));
        this.vehicles[objIndex] = data;
        this.selectedVehicle = data;
        this.approveForm.patchValue({
          preApprovedDate: this.selectedVehicle.approvedDate,
          approvedDate: this.selectedVehicle.approvedDate,
          approvedBy: this.selectedVehicle.approvedBy ? this.selectedVehicle.approvedBy : '',
          isApproved: this.selectedVehicle.isApproved
        });
        // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Update Successfully');
        this.toastr.success('Update Successfully','Alert');
      }
    });
  }
  get form() {
    return this.approveForm.controls;
  }
  ngOnDestroy(): void {}


}
