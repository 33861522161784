import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { AssigTripsService } from 'src/app/components/assigned-trips/assigntrips-service/assigntrips-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { getBrokersList } from 'src/app/utils/utils.common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-trip-detail-modal',
  templateUrl: './trip-detail-modal.component.html',
  styleUrls: ['./trip-detail-modal.component.css']
})
export class TripDetailModalComponent implements OnInit {
  @Input() tripdetailModallObj ;
  @Output() onCloseModal = new EventEmitter<any>();
  tripDetail;
  origin: any;
  destination:any;
  contact;
  platform;
  trackinURL;

  constructor(private assignService : AssigTripsService,
   private sharedDataService: SharedDataService) { }

  ngOnInit() {
    this.trackinURL = environment.TRACKING_URL;
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.platform = data.platform;
        }
      });
    this.getTripDetail(); 
    this.getDirection();
  }

getTripDetail(){
     this.assignService.getTripDetail(this.tripdetailModallObj._id)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data: any) => {
      this.tripDetail = data;
     });
}


  getDirection() {
    this.origin = { lat: this.tripdetailModallObj.jobOriginLatitude, lng: this.tripdetailModallObj.jobOriginLongitude };
    this.destination = { lat: this.tripdetailModallObj.jobDestinationLatitude, lng: this.tripdetailModallObj.jobDestinationLongitude };
  }

  googleImageURL(latitude, longitude) {
    return `https://maps.googleapis.com/maps/api/staticmap?markers=icon:https://nemtpanel.com/modules/companies/client/img/buttons/normal.png|${latitude},${longitude}&zoom=18&size=500x500&key=AIzaSyC60nn_VZXJTxtmWuIB9SnHGU6USDb1LGo`;
  }

  modalClose() {
    this.onCloseModal.emit(null);
  }

  breakString(str) {
    if (!str) return "";
    return str.replace(/\n/g, "<br>");
  }

  getBroker(key) {
    return getBrokersList[key];
  }

  sendSMS() {
    if (!this.contact) return;
    this.tripdetailModallObj.contactNumber = this.contact;
    this.assignService
      .sendSMS(this.tripdetailModallObj)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.contact = '';
          // sweetAlert('Success', "SMS sent successfully", 'success', 'OK');
        }
      });
  }
  ngOnDestroy(): void {
  }
  
}
