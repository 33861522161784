import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AssigTripsService } from 'src/app/components/assigned-trips/assigntrips-service/assigntrips-service';
@Component({
  selector: 'app-documents-slideout',
  templateUrl: './documents-slideout.component.html',
  styleUrls: ['./documents-slideout.component.css']
})
export class DocumentsSlideoutComponent implements OnInit {
  @Output() close = new EventEmitter<any>();
  documents;
  isDataLoading = true;
  constructor( private tripService: AssigTripsService) { }

  ngOnInit() {
    this.getDocuments();
  }


  getDocuments() {
    this.tripService
      .getDocuments()
      .subscribe(data => {
        this.isDataLoading = false;
        if (data) {
          this.documents = data;
        }
      }, err => {
        this.isDataLoading = true;
      });
  }

}
