import { Injectable } from "@angular/core";
import { Menu, SubMenu } from "./menu-models";

enum UserRoles {
  // ADMIN = 'admin',
  // COMPANY = 'company',
  QUARTER = "quarter",
  ADMIN = "credential",
  AUTHORIZOR = "authorizer",
  DISPATCHER = "dispatcher",
}

@Injectable({
  providedIn: "root",
})
export class MenuService {
  menus = {};
  userRole;
  platform = "*";

  constructor() {}
  getAssignedSubMenu(role){
    let subMenus = [
      {
        name: "All",
        value: "all",
        roles: [UserRoles.DISPATCHER , UserRoles.QUARTER]
      },
       {
         name: "Oustanding",
         value: "outstanding",
         roles: [UserRoles.DISPATCHER , UserRoles.QUARTER]
       },
       {
         name: "Offered",
         value: "offered",
         roles: [UserRoles.DISPATCHER ,UserRoles.QUARTER]
       },
       {
         name: "Assigned",
         value: "accepted",
         roles: [UserRoles.DISPATCHER ,UserRoles.QUARTER]
       },
       {
         name: "Rejected",
         value: "rejected",
         roles: [UserRoles.DISPATCHER ,UserRoles.QUARTER]
       },
       {
         name: "In Progress ",
         value: "inprogress",
         roles: [UserRoles.QUARTER, UserRoles.DISPATCHER]
       },
       {
         name: "Cancelled",
         value: "cancelled",
         roles: [UserRoles.QUARTER, UserRoles.DISPATCHER]
       },
       {
         name: "Finished",
         value: "finished",
         roles: [UserRoles.DISPATCHER ,UserRoles.QUARTER]
       },

     ];
     // console.log("menu Roles", role)
     let roleSpecificMenus= [];

     for (let i = 0; i < subMenus.length; i++) {
       // console.log("menu Roles 1", subMenus[i].roles , role )
       if (subMenus[i].roles.includes(role)) {
         roleSpecificMenus.push(subMenus[i]);
     }
     }
     // console.log("menu Roles", roleSpecificMenus)
     return roleSpecificMenus;
   }

  addMenu(menu: Menu): void {
    if (menu.platform.includes(this.platform) && this.canView(menu.roles)) {
      this.menus[menu.key] = menu;
    }
  }

  // getAssignedSubMenu(role){
  //  let subMenus = [
  //     {
  //       name: "All",
  //       value: "all",
  //       roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
  //     },
  //     {
  //       name: "Unassigned",
  //       value: "unassigned",
  //       roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
  //     },
  //     {
  //       name: "Hanging",
  //       value: "hanging",
  //       roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
  //     },
  //     {
  //       name: "Inprogress",
  //       value: "inprogress",
  //       roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
  //     },
  //     {
  //       name: "Cancelled",
  //       value: "cancelled",
  //       roles: [UserRoles.COMPANY, UserRoles.ACCOUNTANT, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
  //     },
  //     {
  //       name: "Archived",
  //       value: "archived",
  //       roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
  //     },
  //     {
  //       name: "Completed",
  //       value: "completed",
  //       roles: [UserRoles.COMPANY, UserRoles.ACCOUNTANT, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
  //     }
  //   ];

  //   // console.log("menu Roles", role)
  //   let roleSpecificMenus= [];

  //   for (let i = 0; i < subMenus.length; i++) {
  //     console.log("menu Roles 1", subMenus[i].roles , role )
  //     if (subMenus[i].roles.includes(role)) {
  //       roleSpecificMenus.push(subMenus[i]);
  //   }
  //   }
  //   console.log("menu Roles", roleSpecificMenus)
  //   return roleSpecificMenus;
  // }

  addSubMenu(key: string, subMenu: SubMenu) {
    if (this.menus[key] && this.canView(subMenu.roles) && subMenu.platform.includes(this.platform)) {
      const subMenus = this.menus[key].subMenus;
      if (!subMenus) {
        this.menus[key].subMenus = [];
      }
      this.menus[key].subMenus.push(subMenu);
    }
  }

  getMenu(userRole, platform): object {
    this.menus = {};
    this.userRole = userRole;
    this.platform = platform;
    this.addMenus();
    return this.menus;
  }

  addMenus() {
    // Dashboard Center
    this.addMenu({
      title: "Dashboard",
      url: "dashboard",
      icon: this.getIcon("home"),
      key: "dashboard",
      position: 1,
      // UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS
      roles: [UserRoles.QUARTER, UserRoles.DISPATCHER, UserRoles.AUTHORIZOR],
      platform: ["nemt", "medex"],
    });
    // facility-trips
    this.addMenu({
      title: "Distribution",
      url: "distribution",
      icon: this.getIcon("distribution"),
      key: "distribution",
      position: 2,
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR],
      // roles: [UserRoles.QUARTER],
      platform: ["medex"],
    });
    this.addSubMenu("distribution", {
      title: "Dashboard",
      url: "distribution/dashboard",
      position: 3,
      // roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR],
      platform: ["medex"],
    });
    this.addSubMenu("distribution", {
      title: "Authorization",
      url: "distribution/authorization",
      position: 3,
      // roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR],
      platform: ["medex"],
    });
    this.addSubMenu("distribution", {
      title: "Trips",
      url: "distribution/trips",
      position: 3,
      // roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR],
      platform: ["medex"],
    });
    this.addSubMenu("distribution", {
      title: "New Trip",
      url: "distribution/new",
      position: 3,
      // roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR],
      platform: ["medex"],
    });


    this.addMenu({
      title: "Enterprises",
      url: "enterprises",
      icon: this.getIcon("enterprises"),
      key: "enterprises",
      position: 3,
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR],
      // roles: [UserRoles.QUARTER],
      platform: ["medex"],
    });
    this.addSubMenu("enterprises", {
      title: "Enterprises",
      url: "enterprises/enterprise",
      position: 3,
      // roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR],
      platform: ["medex"],
    });
    this.addSubMenu("enterprises", {
      title: "Members",
      url: "enterprises/members",
      position: 3,
      // roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR],
      platform: ["medex"],
    });


    // operations
    this.addMenu({
      title: "Operations",
      url: "operations",
      icon: this.getIcon("operations"),
      key: "operations",
      position: 2,
      roles: [UserRoles.QUARTER, UserRoles.DISPATCHER, UserRoles.AUTHORIZOR],
      // roles: [UserRoles.QUARTER],
      platform: ["nemt", "medex"],
    });

    // Trips
    this.addMenu({
      title: "Alarming",
      url: "alarming",
      icon: this.getIcon("trips"),
      key: "alarming",
      position: 3,
      // roles: [UserRoles.COMPANY, UserRoles.ACCOUNTANT, UserRoles.DISPATCHER, UserRoles.FULLACCESS],
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR, UserRoles.DISPATCHER],
      platform: ["nemt", "medex"],
    });

    // Credentails
    this.addMenu({
      title: "Credentialing",
      url: "credentialing",
      icon: this.getIcon("credentialing"),
      key: "credentialing",
      position: 4,
      // roles: [UserRoles.ADMIN, UserRoles.COMPANY , UserRoles.CREDENTIAL, UserRoles.DISPATCHER, UserRoles.FULLACCESS],
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR],
      platform: ["nemt", "medex"],
    });

    // this.addSubMenu('credentialing', {
    //   title: "Credentials",
    //   url: "credentialing/credentials",
    //   position: 1,
    //   // roles: [UserRoles.COMPANY,UserRoles.ADMIN, UserRoles.CREDENTIAL, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
    //   roles: [UserRoles.QUARTER,UserRoles.AUTHORIZOR],
    // });

    // this.addSubMenu('credentialing', {
    //   title: "Signup Request",
    //   url: "credentialing/signuprequest",
    //   position: 2,
    //   // roles: [UserRoles.COMPANY,UserRoles.ADMIN, UserRoles.CREDENTIAL, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
    //   roles: [UserRoles.QUARTER,UserRoles.AUTHORIZOR],
    // });




    this.addSubMenu("credentialing", {
      title: "Dashboard",
      url: "credentialing/dashboard",
      position: 1,
      // roles: [UserRoles.COMPANY, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR],
      platform: ["medex"],
    });
    this.addSubMenu("credentialing", {
      title: "Signup Request",
      url: "credentialing/signuprequest",
      position: 2,
      // roles: [UserRoles.COMPANY, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR],
      platform: ["medex"],
    });

    this.addSubMenu("credentialing", {
      title: "Onboarding",
      url: "credentialing/onboarding",
      position: 3,
      // roles: [UserRoles.COMPANY, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR],
      platform: ["medex"],
    });

    this.addSubMenu("credentialing", {
      title: "Companies",
      url: "credentialing/companies",
      position: 4,
      // roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR],
      platform: ["nemt","medex"],
    });


    this.addSubMenu("credentialing", {
      title: "LOS",
      url: "credentialing/levelofservice",
      position: 5,
      // roles: [UserRoles.COMPANY, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR],
      platform: ["nemt","medex"],
    });


    this.addSubMenu("credentialing", {
      title: "Threshold",
      url: "credentialing/threshold",
      position: 6,
      // roles: [UserRoles.COMPANY, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR],
      platform: ["medex"],
    });

    this.addSubMenu("credentialing", {
      title: "Staff",
      url: "credentialing/staff",
      position: 7,
      // roles: [UserRoles.COMPANY, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.AUTHORIZOR],
      platform: ["nemt", "medex"],
    });

    // this.addSubMenu('credentialing', {
    //   title: "Companies",
    //   url: "credentialing/companies",
    //   position: 4,
    //   // roles: [UserRoles.ADMIN]
    //   roles: [UserRoles.QUARTER],
    // });

    //Time Sheet

    this.addMenu({
      title: "Map View",
      url: "map-view",
      icon: this.getIcon("mapview"),
      key: "map-view",
      position: 4.1,
      // roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.FULLACCESS],
      roles: [UserRoles.QUARTER, UserRoles.DISPATCHER, UserRoles.AUTHORIZOR],
      platform: ["nemt", "medex"],
    });

    // Fares
    // this.addMenu({
    //   title:"Statistics ",
    //   url: " statistics ",
    //   icon: this.getIcon("fares"),
    //   key: "statistics",
    //   position: 5,
    //   roles: [UserRoles.COMPANY, UserRoles.ACCOUNTANT , UserRoles.DISPATCHER, UserRoles.FULLACCESS],
    // });

    // Reports
    this.addMenu({
      title: "Reports",
      url: "reports/statistics",
      icon: this.getIcon("reports"),
      key: "reports",
      position: 6,
      // roles: [UserRoles.COMPANY, UserRoles.ADMIN, UserRoles.ACCOUNTANT, UserRoles.CREDENTIAL, UserRoles.FULLACCESS],
      roles: [UserRoles.QUARTER, UserRoles.DISPATCHER, UserRoles.AUTHORIZOR],
      platform: ["nemt", "medex"],
    });

    this.addSubMenu("reports", {
      title: "Statistics",
      url: "reports/statistics",
      position: 2,
      // roles: [UserRoles.COMPANY, UserRoles.ACCOUNTANT, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.DISPATCHER, UserRoles.AUTHORIZOR],
      platform: ["nemt", "medex"],
    });

    this.addSubMenu("reports", {
      title: "NEMT Statistics",
      url: "reports/nemtstatistics",
      position: 3,
      // roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.DISPATCHER, UserRoles.AUTHORIZOR],
      platform: ["nemt", "medex"],
    });
     
    this.addSubMenu("reports", {
      title: "Enterprise",
      url: "reports/enterprise",
      position: 4,
      // roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.DISPATCHER, UserRoles.AUTHORIZOR],
      platform: ["medex"],
    });

    this.addSubMenu("reports", {
      title: "Company",
      url: "reports/company",
      position: 4,
      // roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.DISPATCHER, UserRoles.AUTHORIZOR],
      platform: ["medex"],
    });

    this.addSubMenu("reports", {
      title: "Member",
      url: "reports/member",
      position: 3,
      // roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.FULLACCESS]
      roles: [UserRoles.QUARTER, UserRoles.DISPATCHER, UserRoles.AUTHORIZOR],
      platform: ["medex"],
    });

    // this.addMenu({
    //   title: "Quarters",
    //   url: "quarterpanels",
    //   icon: this.getIcon("quarters"),
    //   key: "quarters",
    //   position: 7,
    //   // roles: [UserRoles.ADMIN],
    //   roles: [UserRoles.QUARTER],
    // });

    // this.addMenu({
    //   title: "Invoice",
    //   url: "providerinvoicesettings",
    //   icon: this.getIcon("invoices"),
    //   key: "invoices",
    //   position: 8,
    //   // roles: [UserRoles.ADMIN],
    //   roles: [UserRoles.QUARTER],
    // });

    // this.addSubMenu('reports', {
    //   title: "Driver Breakdown",
    //   url: "reports/breakdown",
    //   position: 3,
    //   roles: [UserRoles.COMPANY, UserRoles.DISPATCHER]
    // });

    // Support
    // this.addMenu({
    //   title: "Support",
    //   url: "driveralerts",
    //   icon: this.getIcon("support"),
    //   key: "driveralerts",
    //   position: 7,
    //   roles: [UserRoles.COMPANY, UserRoles.DISPATCHER],
    // });

    // this.addSubMenu('driveralerts', {
    //   title: "Queries & Support",
    //   url: "support/queries",
    //   position: 1,
    //   // roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
    //   roles: [UserRoles.QUARTER],
    // });

    // guide
    // this.addMenu({
    //   title: "Manual",
    //   url: "manual",
    //   icon: this.getIcon("help"),
    //   key: "help",
    //   position: 7,
    //   roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS],
    // });

    // this.addSubMenu('help', {
    //   title: "Helping Material",
    //   url: "manual/helpingmaterial",
    //   position: 1,
    //   roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
    // });

    // this.addSubMenu('help', {
    //   title: "Nemt Pro Tips",
    //   url: "manual/nemtprotips",
    //   position: 2,
    //   roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
    // });
  }

  getIcon(menu) {
    switch (menu) {
      case "home":
        return `<svg
          class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M6 19v1q0 .425-.287.712Q5.425 21 5 21H4q-.425 0-.712-.288Q3 20.425 3 20v-8l2.1-6q.15-.45.538-.725Q6.025 5 6.5 5h11q.475 0 .863.275q.387.275.537.725l2.1 6v8q0 .425-.288.712Q20.425 21 20 21h-1q-.425 0-.712-.288Q18 20.425 18 20v-1Zm-.2-9h12.4l-1.05-3H6.85ZM5 12v5Zm2.5 4q.625 0 1.062-.438Q9 15.125 9 14.5t-.438-1.062Q8.125 13 7.5 13t-1.062.438Q6 13.875 6 14.5t.438 1.062Q6.875 16 7.5 16Zm9 0q.625 0 1.062-.438Q18 15.125 18 14.5t-.438-1.062Q17.125 13 16.5 13t-1.062.438Q15 13.875 15 14.5t.438 1.062Q15.875 16 16.5 16ZM5 17h14v-5H5Z"
          />
        </svg>`;
      case "help":
        return `<svg
          class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          >
          <path fill="currentColor"
          d="M14 8.775q0-.225.163-.463T14.524 8q.725-.25 1.45-.375T17.5 7.5q.5 0 .988.063t.962.162q.225.05.388.25t.162.45q0 .425-.275.625t-.7.1q-.35-.075-.737-.113T17.5 9q-.65 0-1.275.125t-1.2.325q-.45.175-.737-.025T14 8.775Zm0 5.5q0-.225.163-.463t.362-.312q.725-.25 1.45-.375T17.5 13q.5 0 .988.063t.962.162q.225.05.388.25t.162.45q0 .425-.275.625t-.7.1q-.35-.075-.737-.113T17.5 14.5q-.65 0-1.275.113t-1.2.312q-.45.175-.738-.013T14 14.276Zm0-2.75q0-.225.163-.463t.362-.312q.725-.25 1.45-.375t1.525-.125q.5 0 .988.063t.962.162q.225.05.388.25t.162.45q0 .425-.275.625t-.7.1q-.35-.075-.737-.113t-.788-.037q-.65 0-1.275.125t-1.2.325q-.45.175-.737-.025t-.288-.65ZM6.5 16q1.175 0 2.288.263T11 17.05V7.2q-1.025-.6-2.175-.9T6.5 6q-.9 0-1.788.175T3 6.7v9.9q.875-.3 1.738-.45T6.5 16Zm6.5 1.05q1.1-.525 2.212-.788T17.5 16q.9 0 1.763.15T21 16.6V6.7q-.825-.35-1.713-.525T17.5 6q-1.175 0-2.325.3T13 7.2v9.85Zm-6-5.4Zm5 7.825q-.35 0-.663-.088t-.587-.237q-.975-.575-2.05-.862T6.5 18q-1.05 0-2.063.275T2.5 19.05q-.525.275-1.012-.025T1 18.15V6.1q0-.275.138-.525T1.55 5.2q1.15-.6 2.4-.9T6.5 4q1.45 0 2.838.375T12 5.5q1.275-.75 2.663-1.125T17.5 4q1.3 0 2.55.3t2.4.9q.275.125.413.375T23 6.1v12.05q0 .575-.487.875t-1.013.025q-.925-.5-1.937-.775T17.5 18q-1.125 0-2.2.288t-2.05.862q-.275.15-.588.238t-.662.087Z"/></svg>`;
      case "operations":
        return `<svg
          class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
        <path
          fill="currentColor"
          d="M24 21h2v5h-2zm-4-5h2v10h-2zm-9 10a5.006 5.006 0 0 1-5-5h2a3 3 0 1 0 3-3v-2a5 5 0 0 1 0 10z"
        />
        <path
          fill="currentColor"
          d="M28 2H4a2.002 2.002 0 0 0-2 2v24a2.002 2.002 0 0 0 2 2h24a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2Zm0 9H14V4h14ZM12 4v7H4V4ZM4 28V13h24l.002 15Z"
        />
      </svg>`;
      case "trips":
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M14.844 20H6.5C5.121 20 4 18.879 4 17.5S5.121 15 6.5 15h7c1.93 0 3.5-1.57 3.5-3.5S15.43 8 13.5 8H8.639a9.812 9.812 0 0 1-1.354 2H13.5c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5h-7C4.019 13 2 15.019 2 17.5S4.019 22 6.5 22h9.593a10.415 10.415 0 0 1-1.249-2zM5 2C3.346 2 2 3.346 2 5c0 3.188 3 5 3 5s3-1.813 3-5c0-1.654-1.346-3-3-3zm0 4.5a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 5 6.5z"
        />
        <path
          fill="currentColor"
          d="M19 14c-1.654 0-3 1.346-3 3c0 3.188 3 5 3 5s3-1.813 3-5c0-1.654-1.346-3-3-3zm0 4.5a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 19 18.5z"
        />
      </svg>`;
      case "credentialing":
        return `<svg class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32">
        <path fill="currentColor" d="M16 22a4 4 0 1 0-4-4a4 4 0 0 0 4 4zm0-6a2 2 0 1 1-2 2a2 2 0 0 1 2-2zM14 6h4v2h-4z" />
        <path fill="currentColor"
          d="M24 2H8a2.002 2.002 0 0 0-2 2v24a2.002 2.002 0 0 0 2 2h16a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2Zm-4 26h-8v-2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1Zm2 0v-2a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3v2H8V4h16v24Z" />
      </svg>`;
      case "mapview":
        return `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
      </svg>
      `;
      case "fares":
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"
        />
      </svg>`;
      case "reports":
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
        />
      </svg>`;
      case "quarters":
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
         xmlns="http://www.w3.org/2000/svg"
         fill="none"
         viewBox="0 0 24 24"
         stroke-width="1.5"
         stroke="currentColor"
         >
        <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
        />
      </svg>`;
      case "distribution":
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path fill="currentColor" d="M3 19v-2.077h2.077V19zm4.846 0v-2.077H21V19zM3
         13.038v-2.076h2.077v2.076zm4.846 0v-2.076H21v2.076zM3 7.077V5h2.077v2.077zm4.846 0V5H21v2.077z"/></svg>`;
      case "enterprises":
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="currentColor" d="M8 8h2v4H8zm0 6h2v4H8zm6-6h2v4h-2zm0 6h2v4h-2zm-6 6h2v4H8zm6 0h2v4h-2z"/>
        <path fill="currentColor" d="M30 14a2 2 0 0 0-2-2h-6V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v26h28ZM4 4h16v24H4Zm18 24V14h6v14Z"/>
        </svg>`;
      case "threshold":
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
        <path fill="currentColor" d="M10.45 15.5q.625.625 1.575.588T13.4 15.4L19 7l-8.4 5.6q-.65.45-.712 1.362t.562 1.538M5.1
        20q-.55 0-1.012-.238t-.738-.712q-.65-1.175-1-2.437T2 14q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 4q2.05
         0 3.85.775T19 6.888q1.35 1.337 2.15 3.125t.825 3.837q.025 1.375-.312 2.688t-1.038 2.512q-.275.475-.737.713T18.874 20z"/>
         </svg>`;
      case "invoices":
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 48 48"><g fill="none"
         stroke="currentColor"
         stroke-width="4"
         >
         <path
         stroke-linecap="round"
         stroke-linejoin="round"
         d="M24 6H9a3 3 0 0 0-3 3v22a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3v-5m-18 8v8m-10 0h20"/><circle cx="37" cy="13" r="3"/><path stroke-linecap="round" stroke-linejoin="round" d="M37 20v-4m0-6V6m-6.062 10.5l3.464-2m5.196-3l3.464-2m-12.124 0l3.464 2m5.196 3l3.464 2"
         /></g>
         </svg>`;
      case "support":
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
        />
      </svg>`;
      default:
        return '<svg class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" /></svg>';
    }
  }

  canView(menuRoles) {
    if (Array.isArray(this.userRole)) {
      // In case if user has multiple roles assigned to them.
      // Logic will be added here if needed.
      return false;
    }

    return menuRoles.includes(this.userRole);
  }
}
