// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://provider.qalbs.com/',
  FACILITY_NEMTROUTES_TOKEN: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWZiZGU5NWE5YjdhZDE2NzNkM2ViYjA1IiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiZmFjaWxpdHkiLCJpYXQiOjE3MDQ4ODgxMjF9.A-ts9zKmtl8NewZ-IBGEfidcHCO6QYUbcwIgM8Ij3c8",
  FACILITY_NEMT_TOKEN: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWZiZGU5NWE5YjdhZDE2NzNkM2ViYjA1IiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiZmFjaWxpdHkiLCJpYXQiOjE3MDQ4ODgxMjF9.A-ts9zKmtl8NewZ-IBGEfidcHCO6QYUbcwIgM8Ij3c8",
  NEW_SERVER_URL: "https://reskinned.nemtservices.com/",
  CREDENTIALING_ONBOARD_URL: "https://credentialing.nemtservices.com/",
  ENTERPRISE_ONBOARD_URL: "https://enterprisedev.nemtprime.com/",
  TRACKING_URL: "https://membercare.nemtsoftware.com/trip-tracking/"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
