import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-distribution-actions-dropdown',
  templateUrl: './distribution-actions-dropdown.component.html',
  styleUrls: ['./distribution-actions-dropdown.component.css']
})
export class DistributionActionsDropdownComponent implements OnInit, OnChanges {
  @Input() assign;
  @Input() status;

  @Output() distribute = new EventEmitter<any>();
  @Output() sendback = new EventEmitter<any>();
  @Output() assignModal = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() tripDetails = new EventEmitter<any>();
  @Output() tripLogs = new EventEmitter<any>();
  @Output() tripEligibility = new EventEmitter<any>();

  isClose = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  isStringExist(substrings, str) {
    if (substrings.some(v => str.toLowerCase().includes(v))) {
      return true;
    }
    return false;
  }

}
