import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { urls } from '../../utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }


  getStatistics(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_STATISTICS);

  }

  getStatisticsByDateRange(start, end): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_STATISTICS + '?start=' + start + '&end=' + end);

  }


  getKpiStatistics(startDate, endDate, companyToken) {



    return this.http.get<any>(urls.BASE_URL + urls.NEMT_STATISTICS_URL + '?startDate=' + startDate + '&endDate=' + endDate, companyToken);
  }

  getFacilityReport(data) {
    if (!data.providerStatus) {
      delete data.providerStatus
    }
    if (!data.endDate || !data.startDate) {
      delete data.endDate,
      delete data.startDate
    }
    delete data.dateRange;

    return this.http.post<any>(urls.BASE_URL + "facilitytrips/generatereport", data);
  }


  downloadFacilityReport(data, facility, startDate, endDate, jobFee, reportType) {
    return this.http.post<any>(urls.PDF_REPORT_URL + "report/facilities", {reportType, facility,reportData: data, startDate, endDate, jobFee});
  }

  downloadMemberReport(data, facility, startDate, endDate, jobFee, reportType, totalJobs, completedJobs, cancelledJobs) {
    console.log(totalJobs)
    console.log(completedJobs)
    console.log(cancelledJobs)
    return this.http.post(urls.PDF_REPORT_URL + "report/member", {reportType, facility,reportData: data, startDate, endDate, jobFee, totalJobs, completedJobs, cancelledJobs});
  }
}
