
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl } from '../../../utils/network-utils';
import { element } from 'protractor';


@Injectable({
  providedIn: 'root'
})
export class AssigTripsService {

  constructor(private http: HttpClient) {
  }

  getTripDetailsById(id): Observable<any> {
    return this.http
      .get<any>(
        urls.BASE_URL + 'facilitytrips' + "/" + id
      )
      .pipe(catchError(errorHandl));
  }

  saveTrip(tripId, payload) {
    const timeKeys = [
      "schedulePickupHour",
      "schedulePickupMinutes",
      "appointmentPickupHour",
      "appointmentPickupMinutes",
      "preScheduleTime",
    ];
    delete payload["companyType"];
    timeKeys.forEach((element) => {
      delete payload[element];
    });

    if (payload.tripLegs && payload.tripLegs.length) {
      payload.tripLegs = payload.tripLegs.splice(1, payload.tripLegs.length);
    }

    if (payload.tripLegs && payload.tripLegs.length === 0) {
      delete payload["tripLegs"];
    } else if (payload.tripLegs && payload.tripLegs.length > 0 && !payload.isMedical) {
      for (let i = 0; i < payload.tripLegs.length; i++) {
        delete payload.tripLegs[i].tripId;
      }
    }

    if (payload.tripLegs && payload.tripLegs.length) {
      payload['additionalOptions'] = "multiLeg";
    } else {
      payload['additionalOptions'] = "singleLeg";
    }

    if (payload.suggestedprovider === null || payload.suggestedprovider === "null") {
      delete payload.suggestedprovider;
    }

    if (tripId) {
      return this.http.put(urls.BASE_URL + 'facilitytrips/' + tripId, payload)
        .pipe(catchError(errorHandl));
    } else {
      return this.http.post(urls.BASE_URL + 'facilitytrips', payload)
        .pipe(catchError(errorHandl));
    }
  }

  distributeTrip(tripId) {
  
    return this.http.get(urls.BASE_URL + 'distribute/' + tripId, );
  }

  distributeSelectedTrip(_ids) {
    return this.http.put(urls.BASE_URL + 'driverdistribution',{_ids} );
  }


  getFacilityTripDetailById(unassignedTripId): Observable<any> {
    return this.http
      .get<any>(
        urls.BASE_URL + "facilitytrips" + "/" + unassignedTripId
      )
      .pipe(catchError(errorHandl));
  }

  getTripEligibilityDetailById(tripId): Observable<any> {
    return this.http
      .get<any>(
        urls.BASE_URL + "facilities" + "/" + tripId
      )
      .pipe(catchError(errorHandl));
  }
  createNewEnterpriseTrip(tripData): Observable<any> {
   
    const timeKeys = [
      "schedulePickupHour",
      "schedulePickupMinutes",
      "appointmentPickupHour",
      "appointmentPickupMinutes",
      "scheduledTime",
    ];
    delete tripData["companyType"]; // not require right now
    timeKeys.forEach((element) => {
      delete tripData[element];
    });
    // if(tripData.tripLegs){

    //   tripData.tripLegs = tripData.tripLegs.splice(1, tripData.tripLegs.length);
    // }
    // if (tripData.tripLegs.length === 0) {
    //   delete tripData["tripLegs"];
    // } else if (tripData.tripLegs.length > 0 && !tripData.isMedical) {
    //   for (let i = 0; i < tripData.tripLegs.length; i++) {
    //     delete tripData.tripLegs[i].tripId;
    //   }
    // }
    // console.log(tripData);
    // if (tripData._id) {
    //   return this.http
    //     .put<any>(
    //       urls.BASE_URL + "facilitytrips"+ "/" + tripData._id,
    //       tripData,
          
    //     )
    //     .pipe(catchError(errorHandl));
    // } else {
    //   delete tripData._id;
    //   return this.http
    //     .post<any>(urls.BASE_URL + "facilitytrips", tripData )
    //     .pipe(catchError(errorHandl));
    // }
    return this.http
        .post<any>(urls.BASE_URL + "facilitytrips", tripData )
        .pipe(catchError(errorHandl));
  }

  getAllFacilityTrips(endpoint) {
    
    let options = { params: endpoint };
    // console.log(search)
    return this.http.get(urls.BASE_URL + "facilitytrips", options);
  }

  getAssignList(endPoint): Observable<any> {
    let params = new HttpParams();
    params = params.append('isShowAllTrips', endPoint.isShowAllTrips);
    params = params.append('status', endPoint.status);

    if(endPoint.skip)
    params = params.append('skip', endPoint.skip);
    
    if (endPoint.date) {
      params = params.append('scheduleTime', endPoint.date);
    }


    return this.http.get<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getOutstandingTrips(payload): Observable<any> {
    // let params = new HttpParams();
    // params = params.append('driver', endPoint.driver);
    // params = params.append('scheduleTime', endPoint.scheduleTime);

    // return this.http.get<any>(urls.BASE_URL + urls.GET_OUTSTANDING_TRIPS_URL, { params })
    //   .pipe(
    //     catchError(errorHandl)
    //   );

    return this.http.post<any>(urls.BASE_URL + urls.GET_OUTSTANDING_TRIPS_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  getStandingOrders(): Observable<any> {

    return this.http.put<any>(urls.BASE_URL + urls.GET_OUTSTANDING_TRIPS_URL, {})
      .pipe(
        catchError(errorHandl)
      );
  }

  getOutstandingTripsForBucket(payload): Observable<any> {
    let params = new HttpParams();
    params = params.append('scheduleTime', payload.scheduleTime);

    return this.http.get<any>(urls.BASE_URL + urls.GET_OUTSTANDING_TRIPS_URL, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getUnparsedTrips(endPoint): Observable<any> {
    let params = new HttpParams();
    params = params.append('rawId', endPoint.rawId);

    return this.http.get<any>(urls.BASE_URL + urls.GET_UNPARSED_TRIPS_URL, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getSweepTripsList(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_SWEEP_TRIPS_LIST_URL)
      .pipe(
        catchError(errorHandl)
      );
  }

  removeSweepTrips(data): Observable<any> {

    return this.http.post<any>(urls.BASE_URL + urls.GET_SWEEP_TRIPS_LIST_URL + '?assignId=' + data._id, data)
      .pipe(
        catchError(errorHandl)
      );
  }

  sendTripListEmail(data): Observable<any> {

    return this.http.put<any>(urls.BASE_URL + urls.SEND_TRIP_LIST_EMAIL_URL + data._id, data)
      .pipe(
        catchError(errorHandl)
      );
  }


  calculateFare(id): Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/tempbrokerfare/${id}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  cloneTrip(id): Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/clonetrip/${id}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  removeTrip(data, id): Observable<any> {

    return this.http.post<any>(urls.BASE_URL + urls.ASSIGN_MULTIPLE_TRIPS_URL + `/${id}`, data)
      .pipe(
        catchError(errorHandl)
      );
  }
  UpdateTripStatusWithNgrx(payload): Observable<any> {
    const id = payload._id;
    
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  sendToMarketplace(payload): Observable<any> {
    const id = payload._id;
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  markCompletedTrip(payload): Observable<any> {
    const id = payload._id;
    return this.http.post<any>(urls.BASE_URL + urls.MARK_COMPLETED_TRIP + `?assignId=${id}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  addSpecialRate(payload): Observable<any> {
    const id = payload._id;
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  getAssignTripsList(requestData): Observable<any> {

    return this.http.post<any>(urls.BASE_URL + urls.GET_ASSIGNED_TRIPS_LIST, requestData)
      .pipe(
        catchError(errorHandl)
      );
  }

  getCorporateUserList(): Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.GET_CORPORATE_USER_LIST + '?accountStatus=' + 'active')
      .pipe(
        catchError(errorHandl)
      );
  }


  getCorporateAddonsList(id): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + 'addonservices/corporate?cooperate=' + id)
      .pipe(
        catchError(errorHandl)
      );
  }

  getTripsForAssignDashboard(): Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.TRIPS_FOR_ASSIGN_DASHBOARD_URL)
      .pipe(
        catchError(errorHandl)
      );
  }

  assignTrip(data): Observable<any> {

    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${data._id}`, data)
      .pipe(
        catchError(errorHandl)
      );
  }

  assignMultipleTrip(data): Observable<any> {
    
    return this.http.post<any>(urls.BASE_URL + urls.ASSIGN_MULTIPLE_TRIPS_URL, data)
      .pipe(
        catchError(errorHandl)
      );
  }

  addBillerNote(data): Observable<any> {

    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${data._id}`, data)
      .pipe(
        catchError(errorHandl)
      );
  }

  addAdditionalNote(data): Observable<any> {

    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${data._id}`, data)
      .pipe(
        catchError(errorHandl)
      );
  }

  updateTrip(payload): Observable<any> {
    const id = payload._id;
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }


  verifiedCancelTrip(data, id) {
    return this.http.post<any>(urls.BASE_URL + urls.VERIFIED_CANCEL_TRIP_URL + `/${id}`, data)
      .pipe(
        catchError(errorHandl)
      );
  }


  createNewTrip(tripData, tripId?): Observable<any> {
    
    const normalDispatchRemoveKeys = ['iSschUrgentCare', 'isUrgentCare', 'isWheelChair', 'isCarSeat', 'isLongDistance', 'memberId', 'tripId', 'dob'];
    const lcpDispatchRemoveKeys = ['cooperate'];
    const medicalDispatchRemoveKeys = ['iSschUrgentCare', 'isUrgentCare', 'isWheelChair', 'isCarSeat', 'isLongDistance', 'dob'];
    const corporateDispatchRemoveKeys = ['iSschUrgentCare', 'isUrgentCare', 'isWheelChair', 'isCarSeat', 'isLongDistance', 'memberId', 'tripId'];

    if (tripData.jobType === 'assigned') {
      if (tripData.companyType) {
        if (tripData.companyType === 'lcp') {
          lcpDispatchRemoveKeys.forEach(element => delete tripData[element]);
        } else {
          medicalDispatchRemoveKeys.forEach(element => delete tripData[element]);
        }
      } else {
        normalDispatchRemoveKeys.forEach(element => delete tripData[element]);
      }
      


      if (!tripData.cooperate || tripData.cooperate) {
        if (!tripId || tripData.cooperate === "") {
          delete tripData.cooperate;
        }
      }

    } else if (tripData.jobType === "cooperate") {
      corporateDispatchRemoveKeys.forEach(element => delete tripData[element]);
    }

    if (tripData.tripLegs) {
      tripData.tripLegs = tripData.tripLegs.splice(1, tripData.tripLegs.length)
      if (tripData.tripLegs.length === 0) {
        delete tripData['tripLegs'];
      } else if (tripData.tripLegs.length > 0 && !tripData.isMedical) {
        for (let i = 0; i < tripData.tripLegs.length; i++) {
          delete tripData.tripLegs[i].tripId;
        }

      }
    }

    if (tripId) {
      let c = tripData.companyNote.split('<|>');
      if (c.length) {
        tripData.companyNote = c[0];
      }
    }
    // added adition
    tripData.companyNote += `<|>`;


    if (tripData.addonServices) {
      for (let addons of tripData.addonServices) {
        tripData.companyNote += (tripData.companyNote.length ? `\n` : '') + `${addons.title} * ${addons.quantity}`
      }
    }

    if (tripData.originAddressAdditional) {
      if (tripData.originAddressAdditional.floor && tripData.originAddressAdditional.floor.length) {
        tripData.companyNote +=(tripData.companyNote.length ? `\n` : '') +  `Origin Address Additional\n`
        tripData.companyNote += `Floor :  ${tripData.originAddressAdditional.floor} \n`
      }

      if (tripData.originAddressAdditional.room && tripData.originAddressAdditional.room.length) {
        tripData.companyNote += `room :  ${tripData.originAddressAdditional.room} \n`
      }

      if (tripData.originAddressAdditional.aptOrSuite && tripData.originAddressAdditional.aptOrSuite.length) {
        tripData.companyNote += `Apt or Suite :  ${tripData.originAddressAdditional.aptOrSuite}`
      }
    }

    if (tripData.destinationAddressAdditional) {

      if (tripData.destinationAddressAdditional.floor && tripData.destinationAddressAdditional.floor.length) {
        tripData.companyNote +=(tripData.companyNote.length ? `\n` : '') +  `Destination Address Additional\n`
        tripData.companyNote += `Floor :  ${tripData.destinationAddressAdditional.floor} \n`
      }

      if (tripData.destinationAddressAdditional.room && tripData.destinationAddressAdditional.room.length) {
        tripData.companyNote += `room :  ${tripData.destinationAddressAdditional.room} \n`
      }

      if (tripData.destinationAddressAdditional.aptOrSuite && tripData.destinationAddressAdditional.aptOrSuite.length) {
        tripData.companyNote += `Apt or Suite :  ${tripData.destinationAddressAdditional.aptOrSuite}`
      }
    }

    if (tripData.weight) {
      tripData.companyNote +=(tripData.companyNote.length ? `\n` : '') +  `Weight :  ${tripData.weight} lbs`
    }

    if (tripId) {
      return this.http.put<any>(urls.BASE_URL + urls.CREATE_NEW_TRIP_URL + '/' + tripId, tripData)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      return this.http.post<any>(urls.BASE_URL + urls.CREATE_NEW_TRIP_URL, tripData)
        .pipe(
          catchError(errorHandl)
        );
    }
  }

  IsoStringConversion(tripData) {
    
    const dates = { scheduleTime: ['schedulePickupHour', 'schedulePickupMinutes'], appointmentTime: ['appointmentPickupHour', 'appointmentPickupMinutes'], returnScheduleTime: ['returnPickupHour', 'returnPickupMinutes'] };
    Object.keys(dates).forEach(key => {
      
      let date = tripData[key].split('T')[0];
      tripData[key] = date + 'T';
      dates[key].forEach(element => {
        
        tripData[key] = tripData[key] + tripData[element] + ':';
      })
      tripData[key] = tripData[key] + '00.000Z';
    })
    return tripData;
  }

  getUnassignedTripDetailById(unassignedTripId): Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.CREATE_NEW_TRIP_URL + '/' + unassignedTripId)
      .pipe(
        catchError(errorHandl)
      );
  }

  getAdjustedTrips(queryParams): Observable<any> {
    const params = new HttpParams({ fromObject: queryParams });
    return this.http.get<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getAssignTripLogsFile(payload, endPoint): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.CREATE_NEW_TRIP_URL + `/${endPoint}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  // downlodFile(url, name): Observable<Blob> {
  //     return this.http.get<any>(urls.BASE_URL + url , {responseType: 'blob'})
  //         .pipe(
  //             catchError(errorHandl)
  //         );
  // }



  generateSignature(payload, endPoint): Observable<any> {

    return this.http.put<any>(urls.BASE_URL + urls.GENRATE_TRIP_SIGNATURE_URL + `/${endPoint}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }


  updateAllTripsSignature(payload): Observable<any> {

    return this.http.post<any>(urls.BASE_URL + urls.UPDATE_ALL_TRIPS_SIGNATURE_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }


  bankedTrips(payload): Observable<any> {

    return this.http.post<any>(urls.BASE_URL + urls.BANKED_TRIP_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  getCompaniesTrips(payload): Observable<any> {

    return this.http.post<any>(urls.BASE_URL + urls.GET_OPERATIONS, payload)
      .pipe(
        catchError(errorHandl)
      );
  }


  getETA(tripId) {
    return this.http.get<any>(urls.BASE_URL + urls.SINGLE_TRIP + '/' + tripId)
      .pipe(
        catchError(errorHandl)
      );
  }

  getDirtRoadTrips(params) {
    return this.http.get(
      urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL, params);
  }

  sendSMS(payload): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.TRACKING_SMS_URL + '?smId=' + payload._id, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  generateTripsReport(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GENERATE_TRIPS_REPORT, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  generateTripsIntineraryReport(payload) {
    return this.http.put<any>(urls.BASE_URL + urls.GENERATE_TRIPS_REPORT, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  sendConfirmationText(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.SEND_CONFIRMATION_TEXT_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  sendMemberVerificationText(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.SEND_CONFIRMATION_TEXT_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  autoTripAdjustment(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.AUTO_TRIP_ADJUSTMENT_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  adjustTripOdometer(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.TRIP_ODOMETER_ADJUST_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  adjustAllTripsOdometer(payload) {
    return this.http.put<any>(urls.BASE_URL + urls.TRIP_ODOMETER_ADJUST_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadCallTheCarInvoice(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + '/callthecar', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadSoutheastransManifest(driverId, payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + '/manifest/' + driverId, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadLogisticareManifest(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + '/logisticmanifest', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadLcpManifest(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + '/lcpmanifest', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadAmeraManifest(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + '/ameramanifest', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadCorporateInvoiceReport(corporateUserId, payload) {
    return this.http.put<any>(urls.BASE_URL + urls.CORPORATE_USER_REPORT_URL + `/${corporateUserId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadCorporateInvoice(corporateUserId, payload) {
    return this.http.post<any>(urls.BASE_URL + urls.CORPORATE_USER_REPORT_URL + `/${corporateUserId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadMedtransDriverLog(driverId, payload) {
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/nmntrf/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadMtmTrf(driverId, payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/mtmtrf/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadMtmClaim(payload) {
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + '/claimfile', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadDriverLog(driverId, payload) {
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/mtmtrf/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadAllDriverLog(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + '/mtm/alldriverlogs', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  billedToModivcare(payload) {
    return this.http.put<any>(urls.BASE_URL + urls.BILLED_TO_MODIVCARE_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  vapremiumDriverLog(driverId, payload) {
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/vapremium/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  access2CareDriverLog(driverId, payload) {
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/access2care/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  veyoDriverLog(driverId, payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/access2care/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  tripsLog(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/tripslog`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  logistiCareTriplogs(driverId, payload) {
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/manifest/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  signatureUpdate(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/autosignature`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  bankedTrip(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/banked`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  claimFile(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/claimfile`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  cameraTripslog(payload) {
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/tripslog`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  corporateAeroInvoice(corporateId, payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/corporate/aero/${corporateId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  getTripAssistanceList(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_TRIP_ASSISTANCE_LIST)
      .pipe(
        catchError(errorHandl)
      );
  }

  processTripAssistance(id, payload) {
    return this.http.put<any>(urls.BASE_URL + urls.GET_TRIP_ASSISTANCE_LIST + `/${id}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }
  
  getAllTrips(search) {
    let options = { params: search };
    // console.log(search)
    return this.http.get(urls.BASE_URL + "assigns/trips", options);
  }


  getTripDetail(tripId) {
 
    return this.http.get(urls.BASE_URL + urls.SINGLE_TRIP +'/' + tripId);
  }


  getNotification(id) {
    // let authHeader = new HttpHeaders({
    //   authorization: "JWT " + localStorage.getItem("token"),
    // });
    // let options = {  };
    return this.http.get(urls.BASE_URL + "notifications?company=" + id);
  }

  markCompleteTrip(payload, token): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + token
    });
    let options = { headers: authHeader };
    const id = payload._id;
    return this.http.post<any>(urls.BASE_URL + `assigns/markcompleted?assignId=${id}`, payload, options);
  }

  cancelTrip(payload, token) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + token
    });

    let options = { headers: authHeader };
    return this.http.post(urls.BASE_URL + 'assigns' + `/${payload._id}`, payload , options);
  }

  getTripsCount() {
    return this.http.get<any>(urls.BASE_URL + urls.FACILITY_TRIPS_COUNTER_URL)
    .pipe(
      catchError(errorHandl)
    );
  }

  parseTrips(data, token) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + token
    });
    let options = { headers: authHeader };
    const formData = new FormData();
    formData.append('uploadExcel', data);
    return this.http.post(urls.BASE_URL + urls.PARSE_FACILITY_TRIPS, formData, options);
  }

  tripLogs(facilityId) {
    let options = {params: { facilityId } };
    return this.http.get<any>(urls.BASE_URL + urls.FACILITY_TRIPS_LOGS, options);
  }

  approveAuthorizedTrip(tripId, data) {
    return this.http.put(urls.BASE_URL + "tripauthorization/" + tripId, data);
  }

  getMemberTrips(endPoint: any): Observable<any> {
    let params = new HttpParams();
    params = params.append("type", "member");
    if(endPoint){

      if(endPoint.displayName && endPoint.displayName.length !== 0)
      params = params.append("displayName", endPoint.displayName);
      if(endPoint.contactNumber && endPoint.contactNumber.length !== 0)
      params = params.append("contactNumber", endPoint.contactNumber);
    }

    return this.http.get<any>(urls.BASE_URL + urls.FACILITY_TRIPS_URL , {params})
      .pipe(
        catchError(errorHandl)
      );
  }

  cancelFacilityTrip(tripId, changeReason){
    let obj = {
      changeReason
    }
    return this.http.post(urls.BASE_URL + urls.FACILITY_TRIPS_URL + "/" + tripId, obj);
  }

  getNotifications() {
    return this.http.get(urls.BASE_URL + "facilitytrips/logs");
  }


  getDocuments() {
    return this.http.get(urls.BASE_URL + "facilitytrips/logs");
  }
}
