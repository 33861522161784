import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';

import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';

import { EnterpriseService } from '../../medex/enterprises/enterprise-service/enterprise.service';
import { ReportsService } from '../../reports/reports.service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-member-reports',
  templateUrl: './member-reports.component.html',
  styleUrls: ['./member-reports.component.css']
})
export class MemberReportsComponent implements OnInit {

  currentUser: any;
  searchForm: FormGroup;
  facilities: any = [];
  submitted = false;
  memberReportsData;
  selectedFacility = null;
  startDate = null;
  endDate = null;
  totalJobFee = 0;
  totalJobs = 0;
  completedJobs = 0;
  cancelledJobs = 0;
  userPlatform: any = 'nemt';

  constructor(private fb: FormBuilder, private facilityService: EnterpriseService,
    private reportsService: ReportsService, private sharedData: SharedDataService) { }

  ngOnInit() {
 
    
      this.searchForm = this.fb.group({
        user: ['', Validators.required],
        reportType: ['member', Validators.required],
        providerStatus: [''],
        startDate: [null],
        endDate: [null],
        dateRange: ['',],
        displayName: ['', Validators.required]
      })
      this.getUser();
  }

  getUser(){
    this.sharedData.getUser()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      this.userPlatform = data.platform;
      this.currentUser = data;
    });
    this.getAllFacilities()
  }

  getAllFacilities(){
    this.facilityService.getAllFacilities().pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.facilities = data;
      })
  }


  onDateChange(event) {
    if (event) {
      this.searchForm.patchValue({
        startDate: moment(event[0]).startOf("day").toISOString(),
        endDate: moment(event[1]).endOf("day").toISOString()
      });
    }
  }

  get form() {
    return this.searchForm.controls;
  }



  onSubmit() {
    this.memberReportsData = undefined;
    console.log(this.searchForm.value)
    this.submitted = true;
    
    if (this.searchForm.invalid) {
      return
    }

    this.reportsService.getFacilityReport(this.searchForm.value).pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        console.log(data)
        if (data && data['length'] > 0) {
          this.memberReportsData = data[0].jobs;
          this.totalJobs = data[0].totalJobs;
          this.memberReportsData.sort((val1, val2)=> {return  moment(val1.scheduleTime).diff(moment(val2.scheduleTime), 'minutes') })
          this.completedJobs = data[0].completedJobs;
          this.cancelledJobs = data[0].cancelledJobs;

          this.facilities.forEach(element => {
            if (element._id === this.searchForm.value.user) {
              this.selectedFacility = element;
            }
          });
          this.startDate = this.searchForm.value.startDate;
          this.endDate = this.searchForm.value.endDate;
          this.calculateJobFee(this.memberReportsData);
        }
      })
  }

  calculateJobFee(trips) {
    this.totalJobFee = 0;
    trips.forEach(element => {
      this.totalJobFee += element.jobFee;
    });
  }

  downloadFacilityReport() {
    if (this.memberReportsData.length > 0) {
      console.log(this.selectedFacility)
      this.reportsService.
      downloadMemberReport(this.memberReportsData, this.selectedFacility, this.startDate, this.endDate, this.totalJobFee, 'Trips Report', this.totalJobs, this.completedJobs, this.cancelledJobs)
      .pipe(takeUntil(componentDestroyed(this)))  
      .subscribe(data => {
          console.log(data);
          // console.log(url, fileName);

          const body = <HTMLDivElement>document.body;
          const linkSource = data['pdfFile'];
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.target = '_blank';
          downloadLink.download = data['fileName'];
          body.appendChild(downloadLink);
          console.log(downloadLink)
          console.log(data['pdfFile'])
          console.log(data['fileName'])
          console.log(downloadLink.click())

          // downloadLink.click();
          // downloadLink.dispatchEvent(new MouseEvent('click'));

          var event = document.createEvent('Event');
          event.initEvent('click', true, true);
          downloadLink.dispatchEvent(event);

          // (window.URL || window.webkitURL).revokeObjectURL(downloadLink.href);

        })
    }
  }

  ngOnDestroy(): void {
  }
}
