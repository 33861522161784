import { Component, OnInit } from '@angular/core';
import { CredentialsService } from '../service/credentials.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { CompaniesService } from '../../companies/service/companies.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';

@Component({
  selector: 'app-credentials',
  templateUrl: './credentials.component.html',
  styleUrls: ['./credentials.component.css']
})
export class CredentialsComponent implements OnInit {
  companies = [];
  search;
  canView = false;
  viewAccess = false;
  user;

  constructor(
    private sharedData: SharedDataService,
    private credentialsService: CredentialsService,
    private companyService: CompaniesService
  ) {  
    document.addEventListener('keyup', e => {
    // e.ctrlKey &&
    if (e.altKey && (e.key.toLowerCase() === 'v' || e.key.toLowerCase() === '√') && this.viewAccess) {
      this.canView = !this.canView;
    }
  });
}

  ngOnInit() {
    this.sharedData.getUser()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      if (data) {
        this.user = data;
        if (data.roleType === 'admin') {
          this.viewAccess = true;
        }
      }
    });
    this.getCompanies();
  }

  getCompanies() {
    this.credentialsService
      .getAlivisCompanies()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.companies = data;
        }
      });
  }

  openOnboardPanel(companyId) {
    this.companyService.getCompanyToken(companyId)
      .subscribe((data: any) => {
        if(environment.production) {
          window.open(`${environment.CREDENTIALING_ONBOARD_URL}dashboard?auth_token=${data.companyToken}`, '_blank');
        }
        else {
          window.open(`${environment.CREDENTIALING_ONBOARD_URL}dashboard?auth_token=${data.companyToken}`, '_blank');
        }

      });
  }

  ngOnDestroy(): void {
  }
  
}
