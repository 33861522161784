import { Component, OnDestroy, OnInit } from '@angular/core';
import { EnterpriseService } from '../../enterprises/enterprise-service/enterprise.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { ThresholdService } from '../threshold-service/threshold.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { sweetAlert } from 'src/app/utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-threshold-list',
  templateUrl: './threshold-list.component.html',
  styleUrls: ['./threshold-list.component.css']
})
export class ThresholdListComponent implements OnInit, OnDestroy {
  search
  form: FormGroup;
  companies = [];

  constructor(
    private thresholdService: ThresholdService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      providerthreshold: this.fb.array([]),
    });

    this.getCompaniesThreshold();
  }

  getCompaniesThreshold() {
    this.thresholdService
      .getProviderThresholds()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.companies = data;
          for (let i = 0; i < data.length; i++) {
            this.providerThresholdFormArray.push(
              this.fb.group({
                _id: [""],
                company: data[i].company,
                maxMilePerTrip: [""],
                radius: [""],
                totalFare: [""],
                farePerTrip: [""],
                totalMiles: [""],
                totalTrips: [0],
              })
            );
            for (let val of data[i].providerthreshold) {
              this.providerThresholdFormArray.at(i).patchValue({
                ...val,
              });
            }
          }
        }
      });
  }

  get providerThresholdFormArray() {
    return this.form.controls.providerthreshold as FormArray;
  }
  onSubmit(index) {
    let payload = this.providerThresholdFormArray.at(index).value;

    payload = {
      ...payload,
      farePerTrip: ( payload.farePerTrip && payload.farePerTrip >0 ) ? payload.farePerTrip : "0",
      maxMilePerTrip:(  payload.maxMilePerTrip && payload.maxMilePerTrip >0 )? payload.maxMilePerTrip : "0",
      radius: ( payload.radius && payload.radius >0 )? payload.radius : "0",
      totalFare:(  payload.totalFare && payload.totalFare >0 )? payload.totalFare : "0",
      totalMiles:(  payload.totalMiles && payload.totalMiles >0 )? payload.totalMiles : "0",
      totalTrips: ( payload.totalTrips && payload.totalTrips >0 )? payload.totalTrips : "0",
    };

    if (payload._id !== "") {
      this.thresholdService.updateThresholds(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        sweetAlert('Success', "Company threshold is successfully updated", 'success', 'OK');
        this.patchValues(data);
      });
    } else {
      delete payload._id;
      this.thresholdService.createThresholds(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        sweetAlert('Success', "Company threshold is successfully updated", 'success', 'OK');
        this.patchValues(data);
      });
    }
  }

  patchValues(data) {
    this.providerThresholdFormArray.value.forEach((element, index) => {
      if (element._id === data._id) {
        this.providerThresholdFormArray.at(index).patchValue({
          ...data,
        });
      }
    });
  }

  ngOnDestroy(): void {
    
  }
}
