import { Component, OnInit } from "@angular/core";
import { ReportsService } from "../reports.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpParams } from "@angular/common/http";
import { CompaniesService } from "../../companies/service/companies.service";
import * as moment from "moment-timezone";
import { data } from "jquery";
import { start } from "repl";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";

@Component({
  selector: "app-nemt-statistics",
  templateUrl: "./nemt-statistics.component.html",
  styleUrls: ["./nemt-statistics.component.css"],
})
export class NemtStatisticsComponent implements OnInit {
  dateForm: FormGroup;
  companiesList: any;
  selectedCompany: any = "";
  selectedTimeZone = "America/New_York";
  selectedCompanyId = "Select Company";
  statistics: any;
  companyToken: any
  companies;
  startDate = moment().startOf("day").toISOString();
  endDate = moment().endOf("day").toISOString();
  pretimePickerDate = [this.startDate, this.endDate];

  constructor(
    private companyService: CompaniesService,
    private fb: FormBuilder,
    private reportservice: ReportsService
  ) { }

  ngOnInit() {
    this.dateForm = this.fb.group({
      dateRange: [""],
    });
    this.getCompaniesList();
  }

  getCompaniesList() {
    const queryParams = {
      accountStatus: 'active',
      isRemoveIAAL: false
    }

    this.companyService.getCompanies(queryParams)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data) {
        this.companiesList = data;

      }
    });
  }


  selectRange(event) {
    this.startDate = moment(event[0]).startOf("day").toISOString();
    this.endDate = moment(event[1]).endOf("day").toISOString();
    if(this.selectedCompany){
      this.getCompanyToken();
    }
  }

  getCompanyToken(): any {
    if (!this.selectedCompany) {
      return;
    }
    this.companyService.getCompanyToken(this.selectedCompany._id)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data: any) => {
      if (data && data.companyToken) {
        const newStartDate = moment(this.startDate)
          .tz(this.selectedCompany.timeZone, true)
          .toISOString();
        const newEndDate = moment(this.endDate)
          .tz(this.selectedCompany.timeZone, true)
          .toISOString();

        this.getNEMTStatistics(newStartDate, newEndDate, data.companyToken);
      }
    })

  }

  getNEMTStatistics(startDate, endDate, companyToken) {
    this.reportservice
      .getKpiStatistics(startDate, endDate, companyToken)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (data && data.length) {

          this.companies = data[0]
        }
      });
  }
  onDropdownChange() {
    // this.selectedCompanyId = evt.target.value;
    this.statistics = null;
    this.selectedCompany = this.companiesList.find((x) => x._id === this.selectedCompanyId);
    this.getCompanyToken();
  }

  onCompanyChange() { }

  ngOnDestroy(): void {
  }
}