import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../reports.service';
import { data } from 'jquery';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { CompaniesService } from '../../companies/service/companies.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {
  dateForm: FormGroup;
  staffList = [];
  search;
  companiesList;
  companiesById;

  from = moment().startOf("day").toISOString();
  to = moment().endOf("day").toISOString();

  providers;
  constructor(
    private fb: FormBuilder,
    private reportservice: ReportsService,
    private companyService: CompaniesService

  ) { }

  ngOnInit() {

    this.dateForm = this.fb.group({
      dateRange: ['']
    });
    // this.getStatisticsByDateRange();

    this.from = moment().format('MM/DD/YYYY');
    this.to = moment(this.from, ['MM/DD/YYYY']).subtract(1, 'week').format('MM/DD/YYYY');

    const end = moment(this.from, 'MM/DD/YYYY').toISOString();
    const start = moment(this.to, 'MM/DD/YYYY').toISOString();

    this.dateForm.patchValue({
      dateRange: [moment(start).toDate(), moment(end).toDate()]
    });

    this.reportservice.getStatisticsByDateRange(this.to, this.from)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.providers = data[0];
        }
      })
    this.getCompaniesList();
  }

  // getStatistics() {
  //   this.reportservice.getStatistics().subscribe(data => {
  //     if (data) {
  //       this.providers = data[0];
  //     }
  //   })
  // }

  getStatisticsByDateRange() {
    const startdate = moment(this.dateForm.value.dateRange[0]).toISOString();
    const endDate = moment(this.dateForm.value.dateRange[1]).toISOString();

    // New to amd from value to populate
    this.to = moment(startdate).format('L');
    this.from = moment(endDate).format('L');

    const start = moment(startdate).format('L');
    const end = moment(endDate).format('L');

    this.reportservice.getStatisticsByDateRange(start, end)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.providers = data[0];
        }
      })
  }

  getCompaniesList() {
    const queryParams = {
      accountStatus: 'active',
      isRemoveIAAL: true
    }

    this.companyService.getCompanies(queryParams)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.companiesList = data;
          this.companiesById = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
        }
      })
  }
  
  ngOnDestroy(): void {
  }

}
