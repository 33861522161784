import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-company-notes-modal',
  templateUrl: './company-notes-modal.component.html',
  styleUrls: ['./company-notes-modal.component.css']
})
export class CompanyNotesModalComponent implements OnInit {
  @Input() trip: any;
  @Output() onCloseModal = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    
  }

  closeNotesModal(){
    this.onCloseModal.emit();
  }

  breakString(str) {
    if (!str) return "";
    return str.replace(/\n/g, "<br>");
  }

}
