import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cancel-reason-modal',
  templateUrl: './cancel-reason-modal.component.html',
  styleUrls: ['./cancel-reason-modal.component.css']
})
export class CancelReasonModalComponent implements OnInit {
  @Output() onClose = new EventEmitter<any>();
  reason;

  constructor() { }

  ngOnInit() {
  }

  submit() {

    if(!this.reason) {
      return;
    }

    this.onClose.emit(this.reason);
  }

  closeModal() {
    this.onClose.emit();
  }
}
