import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CredentialsService } from '../service/credentials.service';
import { getFileNameFromUrl, companyFieldsList, fillingStatus, driverFieldsList, vehicleFieldsList } from '../../../utils/utils.common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StaffService } from '../../staff/staff.service';
import { ToastrService } from 'ngx-toastr';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { error } from 'console';

@Component({
  selector: "app-credentials-detail",
  templateUrl: "./credentials-detail.component.html",
  styleUrls: ["./credentials-detail.component.css"],
})
export class CredentialsDetailComponent implements OnInit {
  companyId: any;
  selectedCompanyId;
  companyDetail: any;
  drivers: any;
  staffList: any;
  selectedDriver: any;
  selectedVehicle: any;
  vehicles: any;
  approveForm: FormGroup;
  selectedTab = "company";
  search;

  constructor(
    private toastr: ToastrService,
    private staffService: StaffService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private credentialsService: CredentialsService
  ) {}

  ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.companyId = params["id"];
      });

    this.approveForm = this.formBuilder.group({
      preApprovedDate: [""],
      approvedDate: ["", ],
      approvedBy: ["", Validators.required],
      isApproved: [true],
    });

    this.getCompanydetail();
    this.getStaffList();
  }

  getCompanydetail() {
    this.credentialsService
      .getCompaniesDetails(this.companyId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        console.log("Company Data", data);
        if (data) {
          this.companyDetail = data;
          this.selectedCompanyId = data.company;
          this.approveForm.patchValue({
            preApprovedDate: this.companyDetail.approvedDate,
            approvedDate: this.companyDetail.approvedDate,
            approvedBy: this.companyDetail.approvedBy
              ? this.companyDetail.approvedBy
              : "",
            isApproved: this.companyDetail.isApproved,
          });
        }
      });
  }

  getDrivers() {
    this.credentialsService
      .getDrivers(this.companyDetail.company)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        console.log("Driver Data", data);
        if (data && data.length) {
          this.drivers = data;
          this.onSelectDriver(this.drivers[0]);
        }
      });
  }

  getVehicles() {
    this.credentialsService
      .getVehiclesList(this.companyDetail.company)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (data) {
          this.vehicles = data;
          this.onSelectVehicle(this.vehicles[0]);
        }
      });
  }

  onSelectDriver(driver) {
    this.credentialsService
      .getDriverById(driver._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        console.log("Driver Data", data);
        if (data) {
          this.selectedDriver = data;
        }
      });
  }

  getStaffList() {
    this.staffService
      .getStaffData("")
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        this.staffList = data;
      });
  }

  onSelectVehicle(vehicle) {
    if(vehicle && vehicle._id){
      this.credentialsService
      .getVehicleById(vehicle._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (data) {
          console.log("selected Vehicle data",data);
          this.selectedVehicle = data;
          this.approveForm.patchValue({
            preApprovedDate: this.selectedVehicle.approvedDate,
            approvedDate: this.selectedVehicle.approvedDate,
            approvedBy: this.selectedVehicle.approvedBy
              ? this.selectedVehicle.approvedBy
              : "",
            isApproved: this.selectedVehicle.isApproved,
          });
        }
      });
    }

  }

  getFileName(url) {
    return getFileNameFromUrl(url);
  }

  updateCompany(name, value) {
    let object = JSON.parse(JSON.stringify(this.companyDetail));
    object[name] = value;
    const errorList = [];
    for (let item of companyFieldsList()) {
      for (let sub in item) {
        if (!object[sub]) {
          errorList.push(item[sub]);
        }
      }
    }
    const object1 = {
      [name]: value,
      fillingStatus: errorList.length > 0 ? true : false,
    };
    this.sendCompanyServerRequest(object1, this.companyId);
  }

  sendCompanyServerRequest(object, id) {
    this.credentialsService
      .updateCompany(object, id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.companyDetail = data;
          this.approveForm.patchValue({
            preApprovedDate: this.companyDetail.approvedDate,
            approvedDate: this.companyDetail.approvedDate,
            approvedBy: this.companyDetail.approvedBy
              ? this.companyDetail.approvedBy
              : "",
            isApproved: this.companyDetail.isApproved,
          });
          // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Update Successfully');
          this.toastr.success("Update Successfully", "Alert");
        }
      });
  }

  sendDriverServerRequest(object, id) {
    this.credentialsService.updateDriver(object, id)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data) {
        const objIndex = this.drivers.findIndex((obj) => obj._id === data._id);
        this.drivers[objIndex] = data;
        this.selectedDriver = data;
        this.approveForm.patchValue({
          preApprovedDate: this.selectedDriver.approvedDate,
          approvedDate: this.selectedDriver.approvedDate,
          approvedBy: this.selectedDriver.approvedBy
            ? this.selectedDriver.approvedBy
            : "",
          isApproved: this.selectedDriver.isApproved,
        });
        // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Update Successfully');
        this.toastr.success("Update Successfully", "Alert");
      }
    });
  }

  sendVehicleServerRequest(object, id) {
    this.credentialsService.updateVehicle(object, id)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data) {
        const objIndex = this.vehicles.findIndex((obj => obj._id === data._id));
        this.vehicles[objIndex] = data;
        this.selectedVehicle = data;
        this.approveForm.patchValue({
          preApprovedDate: this.selectedVehicle.approvedDate,
          approvedDate: this.selectedVehicle.approvedDate,
          approvedBy: this.selectedVehicle.approvedBy ? this.selectedVehicle.approvedBy : '',
          isApproved: this.selectedVehicle.isApproved
        });
        // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Update Successfully');
        this.toastr.success('Update Successfully','Alert');
      }
    });
  }

  fillingStatus(object) {
    return fillingStatus(object);
  }

  onSelectTab(tab) {
    this.selectedTab = tab;
    if (this.selectedTab === "drivers") {
      this.getDrivers();
    } else if (this.selectedTab === "vehicles") {
      this.getVehicles();
    }
  }

  companyFieldsList() {
    return companyFieldsList();
  }

  driverFieldsList() {
    return driverFieldsList();
  }

  vehicleFieldsList() {
    return vehicleFieldsList();
  }
  onSubmit() {
    if (this.approveForm.invalid) {
      console.log("Error in form");
    } else if(this.selectedTab === 'company'){
      console.log('company request');
      this.sendCompanyApprovalEmail()
    }else if (this.selectedTab === 'vehicles'){
      console.log("Vehicle Request");
      this.sendVehicleApprovalEmail()

    }else if(this.selectedTab === 'drivers'){
      console.log("Driver Request");
      this.sendDriverApprovalEmail()
    }
  }

  getValue(key) {
    return this.companyDetail[key] ? this.companyDetail[key] : "N/A";
  }

  approveDateChange(event) {
    console.log(event);
    const date = moment.utc(event).local().format();
    console.log(date);
    this.approveForm.patchValue({
      approvedDate: date,
    });
  }

  sendCompanyErrorEmail() {
    const errorList = [];
    for (let item of companyFieldsList()) {
      for (let sub in item) {
        if (!this.companyDetail[sub]) {
          errorList.push(item[sub]);
        }
      }
    }
    const object = {
      profileMessage: `Company ${this.companyDetail.companyName}`,
      errorList,
    };
    console.log(object);
    this.credentialsService
      .sendErrorEmail(object, this.selectedCompanyId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Send Email Successfully');
          this.toastr.success("Send Email Successfully", "Alert");
        }
      });
  }

  sendDriverErrorEmail() {
    const errorList = [];
    for (let item of driverFieldsList()) {
      for (let sub in item) {
        if (!this.selectedDriver[sub]) {
          errorList.push(item[sub]);
        }
      }
    }
    const object = {
      profileMessage: `Driver ${this.selectedDriver.firstName}  ${this.selectedDriver.lastName}`,
      errorList,
    };
    console.log(object);
    this.credentialsService
      .sendErrorEmail(object, this.selectedCompanyId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Send Email Successfully');
          this.toastr.success("Send Email Successfully", "Alert");
        }
      });
  }

  sendVehicleErrorEmail() {
    const errorList = [];
    for (let item of vehicleFieldsList()) {
      for (let sub in item) {
        if (!this.selectedVehicle[sub]) {
          errorList.push(item[sub]);
        }
      }
    }
    const object = {
      profileMessage: `Vehicle VIN #${this.selectedVehicle.vin} `,
      errorList
    }
    console.log(object);
    this.credentialsService.sendErrorEmail(object, this.selectedCompanyId)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data) {
        // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Send Email Successfully');
        this.toastr.success('Send Email Successfully','Alert');
      }
    });
  }

  updateDriver(name, value) {
    console.log(name, value);
    let object = JSON.parse(JSON.stringify(this.selectedDriver));
    object[name] = value;
    const errorList = [];
    for (let item of driverFieldsList()) {
      for (let sub in item) {
        if (!object[sub]) {
          errorList.push(item[sub]);
        }
      }
    }
    const object1 = {
      [name]: value,
      fillingStatus: errorList.length > 0 ? true : false,
    };
    this.sendDriverServerRequest(object1, this.selectedDriver._id);

  }

  updateVehicle(name, value) {
    let object = JSON.parse(JSON.stringify(this.selectedVehicle));
    object[name] = value;
    const errorList = [];
    for (let item of vehicleFieldsList()) {
      for (let sub in item) {
        if (!object[sub]) {
          errorList.push(item[sub]);
        }
      }
    }


        const object1 = {
          [name]: value,
          fillingStatus: errorList.length > 0 ? true : false
        }
        this.sendVehicleServerRequest(object1, this.selectedVehicle._id);



  }

  sendCompanyApprovalEmail() {
    const object = {
      ...this.approveForm.value,
      isApproved: this.companyDetail.isApproved? false: true ,
      _id: this.companyDetail._id,
      quarterUser: this.staffList.find(data => data._id === this.approveForm.value.approvedBy).displayName,
      profileMessage: `Company ${this.companyDetail.companyName}`,
      method: 'alivicompany' // alivicompany alividriver alivifleet
    }
    console.log("approval mail",object);
    this.credentialsService.sendApprovalEmail(object, this.selectedCompanyId)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data) {
        this.companyDetail = data;
        // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Send Email Successfully');
        this.toastr.success('Send Email Successfully', 'Alert');
      }
    });

  }

  sendVehicleApprovalEmail() {
    const object = {
      ...this.approveForm.value,
      isApproved: this.selectedVehicle.isApproved ? false : true,
      _id: this.selectedVehicle._id,
      quarterUser: this.staffList.find(e => e._id === this.approveForm.value.approvedBy).displayName,
      profileMessage: `Vehicle VIN #${this.selectedVehicle.vin} `,
      method :'alivifleet' // alivicompany alividriver alivifleet
    }
    console.log(object);
    this.credentialsService.sendApprovalEmail(object,  this.selectedCompanyId)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data) {
        const objIndex = this.vehicles.findIndex((obj => obj._id === data._id));
        this.vehicles[objIndex] = data;
        this.selectedVehicle = data;
        // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Send Email Successfully');
        this.toastr.success('Send Email Successfully','Alert');
      }
    });
  }

sendDriverApprovalEmail() {
  const object = {
    ...this.approveForm.value,
    _id: this.selectedDriver._id,
    isApproved: this.selectedDriver.isApproved ? false : true,
    quarterUser: this.staffList.find(data => data._id === this.approveForm.value.approvedBy).displayName,
    profileMessage: `Driver ${this.selectedDriver.firstName}  ${this.selectedDriver.lastName}`,
    method :'alividriver'
  }

  console.log(object);

  this.credentialsService.sendApprovalEmail(object, this.selectedCompanyId)
  .pipe(takeUntil(componentDestroyed(this)))
  .subscribe((data) => {
    if (data) {
      const objIndex = this.drivers.findIndex((obj => obj._id === data._id));
      this.drivers[objIndex] = data;
      this.selectedDriver = data;
      // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Send Email Successfully');
      this.toastr.success('Send Email Successfully','Alert');
    }
  });
}


  ngOnDestroy(): void {}
}
