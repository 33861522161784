import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { urls } from '../../../utils/url-utils';


@Injectable({
  providedIn: 'root'
})
export class AlarmingService {



  constructor(private http: HttpClient) { }

  getAlarmingTrips(queryParams?): Observable<any> {
    let params;
    if (queryParams) {
      params = new HttpParams({fromObject: queryParams});
    }
    return this.http.get<any>(urls.BASE_URL + urls.GET_ALARMING_TRIPS, {params});
  }



  getTripsByCompanies(companyIds): Observable<any> {
    let options = {};
    let searchParam = { companyIds: companyIds }
    if (companyIds) {
      options = {
        // headers: authHeader,
        params: searchParam
      };
    }

    return this.http.get(urls.BASE_URL + urls.GET_ALARMING_TRIPS, options);
  }

}
