import { Component, OnDestroy, OnInit } from '@angular/core';
import { EnterpriseService } from '../../enterprises/enterprise-service/enterprise.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { ActivatedRoute } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.css']
})
export class MembersListComponent implements OnInit, OnDestroy {
  selectedTab = 'member';
  memberData = [];
  searchFacility: any
  userPlatform = 'nemt';
  status = "active";
  search;
  // profileRole: facility
  // profileRole: member
  constructor(
    private enterpriseService: EnterpriseService,
    private sharedData: SharedDataService
    ) { }

  ngOnInit() {
    this.getUser()
    
  }

  getUser(){
    this.sharedData.getUser()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      if(data){
        this.getEnterprises();
      }
    });
  }
  getEnterprises(){
    this.enterpriseService.getAllFacilities('member')
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data =>{
      if(data){
        this.memberData = data
      }
    })
  }

  ngOnDestroy(): void {
    
  }
}
