import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { CompaniesService } from "../service/companies.service";
import { sweetAlert } from "src/app/utils/swal";
import { imageProcessing } from "src/app/utils/image-processing-utils";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import * as moment from "moment-timezone";
import { states } from "src/app/utils/utils.common";
@Component({
  selector: "app-company-create",
  templateUrl: "./company-create.component.html",
  styleUrls: ["./company-create.component.css"],
})
export class CompanyCreateComponent implements OnInit {
  user: any;
  updateForm: FormGroup;
  submitted = false;
  url: any;
  urlImage: any = "";
  fileToUpload: any;
  mismatch: any;
  address: any;
  companyId: any;
  timeZone = 'America/New_York';
  @ViewChild("fileInput", { static: true }) el: ElementRef;
  @ViewChild("password", { static: true }) pass: ElementRef;
  @ViewChild("cpassword", { static: true }) cpass: ElementRef;
  @ViewChild("rpassword", { static: true }) rpass: ElementRef;
  @ViewChild("address", { static: true }) inputAddress: any;
  selectedTimeZone = "America/New_York";
  showHiddenFields = false;
  states = states;
  isShowPassword = false;
  isShowConfirmPassword = false;

  companies = [];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {
    singleSelection: false,
    idField: "_id",
    textField: "displayName",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
  };

  constructor(
    private formBuilder: FormBuilder,
    private sharedData: SharedDataService,
    private companyService: CompaniesService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    // this.companyId = this.activatedRoute.snapshot.paramMap.get('companyId');
    // this.getUsers();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.companyId = params["id"];
    });
    this.updateForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      address: ["", Validators.required],
      contactNumber: ["", Validators.required],
      displayName: ["", Validators.required],
      password: ["", Validators.required],
      latitude: [""],
      longitude: [""],
      cpassword: [""],
      timeZone: ["", Validators.required],
      platform: ["", Validators.required],
      state: ["", Validators.required],
      accountStatus: ["active", Validators.required],
      // isNearBy: [false, Validators.required],
      // iaal: [false, Validators.required],
      // isSms: [false, Validators.required],

      isTime: [false],
      isCamera: [false],
      skipSignature: [false],
      isAttendee: [false],
      isTripAssistance: [false],
      isNearBy: [false],
      isSms: [false],
      isAlcTrip: [false],
      forcedAcceptTrips: [false],
      iaal: [false],
      isMedicalType: [false],
      calculateLocalMiles: [false],
      isRoute: [false],
      isShowDeadMiles: [false],
      isListView: [false],
      isCheckListOdometer: [false],
      stateCode: ["", Validators.required],
      reviewURL: [""],
      extension: [""],
      npi: [""],
      taxId: [""],
      showCTCTrips: [false],
      isShowCMS1500: [false],
      isCorporateOdometer: [false],
      companies: [[]],
      faxNumber: [""],
      focalPerson: this.formBuilder.group({
        displayName: ["", Validators.required],
        contactNumber: ["", Validators.required],
      }),
      applyCaTimeSheet: [false],
      takeTripEndImage: [false],
      note: [""],
      timeinAt: [""],
      onboardStatus: ["trial", Validators.required],
      trialExpireDate: [moment().toISOString(), Validators.required],
      preTrialEnd: [moment()],
      dateFormat: ["", Validators.required],
      timeFormat: ["", Validators.required],
      caTimeCaution: [0],
    });

    this.updateForm.controls["isTime"].valueChanges.subscribe((value) => {
      console.log(value);
      if (value) {
        this.updateForm.get("timeinAt").setValidators([Validators.required]);
      } else {
        this.updateForm.get("timeinAt").clearValidators();
      }
      this.updateForm.get("timeinAt").updateValueAndValidity();
    });

    this.updateForm.controls["onboardStatus"].valueChanges.subscribe(
      (value) => {
        if (value === "trial") {
          this.updateForm
            .get("trialExpireDate")
            .setValidators([Validators.required]);
        } else {
          this.updateForm.get("trialExpireDate").clearValidators();
        }
        this.updateForm.get("trialExpireDate").updateValueAndValidity();
      }
    );
    this.getUsers();
    this.getCompanies();
    this.onboardStatusValueChanges();
  }

  onboardStatusValueChanges() {
    this.updateForm.controls.onboardStatus.valueChanges.subscribe((value) => {
      if (value && (value === "trial" || value === "contractsent")) {
        this.updateForm.controls.trialExpireDate.setValidators([
          Validators.required,
        ]);
      } else {
        this.updateForm.controls.trialExpireDate.clearValidators();
      }
      this.updateForm.controls.trialExpireDate.updateValueAndValidity();
    });
  }

  getUsers() {
    this.sharedData
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((user) => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
          this.updateForm.patchValue({ platform: this.user.platform });
        }
      });
  }

  getCompanies() {
    const queryParams = {
      accountStatus: "active",
      isRemoveIAAL: true,
    };
    this.companyService
      .getCompanies(queryParams)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (data && data.length) {
          this.companies = data;
          this.dropdownList = data.map((company) => {
            return { _id: company._id, displayName: company.displayName };
          });
          this.setUpView();
        }
      });
  }

  setUpView() {
    if (this.companyId) {
      this.companyService
        .getCompanyById(this.companyId)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((data) => {
          if (data) {
            this.updateForm.patchValue({
              ...data,
              latitude: data.latitude,
              longitude: data.longitude,
              address: data.address,
              preTrialExpireDate:
                data.trialExpireDate === "null" || data.trialExpireDate === ""
                  ? moment().tz(this.timeZone, true)
                  : data.trialExpireDate,
              trialExpireDate:
                data.trialExpireDate === "null" || data.trialExpireDate === ""
                  ? moment().tz(this.timeZone, true).toISOString
                  : data.trialExpireDate,
              caTimeCaution: data.caTimeCaution ? data.caTimeCaution : 0,
            });
            this.url = data.profileImageURL;
            this.urlImage = this.url;
            this.fileToUpload = null;

            this.updateForm.controls.password.clearValidators();
            this.updateForm.controls.password.setErrors(null);
            this.selectedTimeZone = data.timeZone;

            this.selectedItems = this.dropdownList.filter((item) => {
              const selectedItem =
                data && data.companies.find((item1) => item1 === item._id);
              if (selectedItem) {
                return true;
              }
              return false;
            });
          }
        });
    } else {
      if (this.user.platform !== "nemt") {
        this.updateForm.patchValue({
          isNearBy: true,
        });
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.updateForm.invalid) return;

    if (
      this.companyId == null &&
      this.updateForm.value.password !== this.updateForm.value.cpassword
    ) {
      this.mismatch = true;
      return;
    }

    if (!this.companyId && !this.fileToUpload) {
      return false;
    }

    const companiesIds = this.selectedItems.map((data) => data._id);
    this.updateForm.patchValue({
      companies: companiesIds,
    });

    let payload = this.updateForm.value;

    if (this.companyId && !payload.password) {
      delete payload.password;
    }
    if (this.fileToUpload) {
      payload["newProfilePicture"] = this.fileToUpload;
    }

    this.companyService
      .saveCompany(this.companyId, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data.success) {
            sweetAlert(
              "Success",
              "Company Saved Successfully",
              "success",
              "OK"
            );
            this.router.navigateByUrl("credentialing/companies");
          }
        },
        (error) => {}
      );
  }

  processFile(imageInput: any) {
    imageProcessing(imageInput)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((result) => {
        this.fileToUpload = result.file;
        this.url = result.src;
      });
  }

  getAddress(place) {
    if (place.formatted_address) {
      this.updateForm.patchValue({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        address: place.formatted_address,
      });
    }
  }

  togglePassword() {
    this.isShowPassword = !this.isShowPassword;
  }

  toggleConfirmPassword() {
    this.isShowConfirmPassword = !this.isShowConfirmPassword;
  }

  changeTimezone(event: any) {
    this.updateForm.patchValue({ timeZone: event });
  }

  onItemSelect(item: any) {}

  onSelectAll(items: any) {}

  get form() {
    return this.updateForm.controls;
  }

  onTrialEndDateChange(evt) {
    this.updateForm.patchValue({
      trialExpireDate: moment(evt).tz(this.timeZone, true).toISOString(),
    });
  }

  ngOnDestroy(): void {}
}
