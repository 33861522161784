import { Component, OnInit } from '@angular/core';
import { SignupRequestService } from '../signup-request.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { sweetAlert } from 'src/app/utils/swal';
import { ToastrService } from 'ngx-toastr';
import * as moment from "moment-timezone";

@Component({
  selector: 'app-signup-request',
  templateUrl: './signup-request.component.html',
  styleUrls: ['./signup-request.component.css']
})
export class SignupRequestComponent implements OnInit {
  SignupRequestCompanies = [];
  search;
  comapnyId;
  loader = {
    isloading: false,
  };
  today = moment();
  timeZone = 'America/New_York';
  todaySignupRequests = [];

  constructor(private sharedService: SharedDataService,
    private signUpRequestService: SignupRequestService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((user) => {
        if (user) {
          this.timeZone = user.timeZone;
          this.today = moment().tz(this.timeZone);
          console.log('today', this.today.toISOString());
          this.getSignUpRequests(); 
        }
      });
  }

  getSignUpRequests(){
    this.signUpRequestService.getSignUpRequests()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          const signupRequests = data;
          signupRequests.forEach(signupRequest => {
            const createdAt = moment(signupRequest.created).tz(this.timeZone);
            const diff = createdAt.diff(this.today, 'days');
            if (diff === 0) {
              this.todaySignupRequests.push(signupRequest);
            } else {
              this.SignupRequestCompanies.push(signupRequest);
            }
          });
        }
      })
  }

  approve(signupRequestId) {
    sweetAlert(
      "Alert",
      "Are you sure you want to create the company?",
      "warning",
      "Yes",
      "No").then((result: any) => {
        if (result.value) {
          this.comapnyId = signupRequestId;
          this.loader.isloading = true;
          this.approveRequest(signupRequestId);
        }
      });
  }

  approveRequest(signupRequestId) {
    this.signUpRequestService
      .approveSignupRequest(signupRequestId)
      .subscribe((data) => {
        this.loader.isloading = false;
        if (data.success) {
          this.sharedService.addSignupRequests({ success: true });
          this.getSignUpRequests();
          this.toastr.success("Company Created Successfully", "Alert");
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
        this.loader.isloading = false
      });
  }

  ngOnDestroy(): void { }

}
