import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CircleTimerModule } from '@flxng/circle-timer';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FilterByKeyPipe } from './pipe/list-filterbykey-pipe/list-filterbykey-pipe';
import { AgmCoreModule } from '@agm/core';
import { TokenInterceptorService } from './service/token-interceptor-service/token-interceptor.service';
import { EditUserProfileComponent } from './components/user profile/edit-user-profile/edit-user-profile.component';
import { AuthorizationGuard } from './service/auth-guard/authorization-guard.service';
// we are using this in edit profile for all time zone list
import { TimezonePickerModule } from 'ng2-timezone-selector';
// import { TripsHistoryComponent } from './component/dispatch-center/trips-history/trips-history.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from 'ng-pick-datetime-moment';

import { DashboardComponent } from './components/assigned-trips/dashboard/dashboard.component';
import { GooglePlaceDirective } from './directives/google-places-directive/google-place.directive';
import { SocketIoModule, SocketIoConfig, Socket } from 'ngx-socket-io';
import { AgmOverlays } from 'agm-overlays';

import { AgmDirectionModule } from 'agm-direction';

import { TimeagoModule } from 'ngx-timeago';
import {
  NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig,
  SPINNER, POSITION, PB_DIRECTION
} from 'ngx-ui-loader';

import { SideNavComponent } from './components/shared/layout/side-nav/side-nav.component';
import { HeaderComponent } from './components/shared/layout/header/header.component';
import { NotificationsSlideoutComponent } from './components/shared/slideouts/notifications-slideout/notifications-slideout.component';
import { OutsideClickDirective } from './directives/outside-click-directive/outside-click.directive';


import { ToastrModule } from 'ngx-toastr';
import { AuthTokenComponent } from './components/auth-token/auth-token.component';
import { ClockTimePipe } from './pipe/clock-time/clock-time.pipe';
import { CamelCasePipe } from './pipe/camelcase.filter/camelcase.filter.pipe';
import { FilterPipe } from './pipe/list-filter-pipe/list-filter-pipe';
import { SortByDatePipe } from './pipe/sort-by-date-pipe/sort-by-date.pipe';
import { StatisticsComponent } from './components/reports/statistics/statistics.component';
import { NemtStatisticsComponent } from './components/reports/nemt-statistics/nemt-statistics.component';
import { MapviewComponent } from './components/mapview/mapview.component';
import { StaffCreateComponent } from './components/staff/staff-create/staff-create.component';
import { StaffListComponent } from './components/staff/staff-list/staff-list.component';
import { CompanyCreateComponent } from './components/companies/company-create/company-create.component';
import { CompanyListComponent } from './components/companies/company-list/company-list.component';
import { SignupRequestComponent } from './components/signup-request/signup-request/signup-request.component';
import { CredentialsComponent } from './components/credentials/credentials/credentials.component';
import { HomeComponent } from './components/home/home.component';
import { TripActionsDropdownComponent } from './components/shared/trip-actions-dropdown/trip-actions-dropdown.component';
import { DatePipe } from './pipe/date-pipe/date.pipe';
import { TripAssignModalComponent } from './components/shared/slideouts/trip-assign-modal/trip-assign-modal.component';
import { CancelReasonModalComponent } from './components/shared/slideouts/cancel-reason-modal/cancel-reason-modal.component';
import { CompanyNotesModalComponent } from './components/shared/slideouts/company-notes-modal/company-notes-modal.component';
import { AlarmingComponent } from './components/alarming/alarming-trips/alarming.component';
import { TripDetailModalComponent } from './components/shared/slideouts/trip-detail-modal/trip-detail-modal.component';
import { CompaniesFilterModalComponent } from './components/shared/slideouts/companies-filter-modal/companies-filter-modal.component';
import { CreateFareComponent } from './components/companies/fares/create-fare/create-fare.component';
import { FareListComponent } from './components/companies/fares/fare-list/fare-list.component';
import { CredentialsDetailComponent } from './components/credentials/credentials-detail/credentials-detail.component';
import { NotificationDetailsComponent } from './components/notification/notification-details/notification-details.component';
import { SocketService } from './service/socket-service/socket-service.service';
import { MySocketWrapper } from './service/socket-service/socket-wrapper';
import {NgPipesModule} from 'ngx-pipes';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormatTitleCasePipe } from './pipe/format-titlecase.filter/format-titlecase.filter.pipe';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SortByPipe } from './pipe/order-by/order-by.pipe';
import { SortByNumberPipe } from './pipe/sortby-number/sort-by-number.pipe';
import { PopperDirective } from './directives/popper-tooltip/popper.directive';
import { FormatNumberDirective } from './directives/number-format/format-number.directive';
import { NumberDirective } from './directives/number-only-directive/number-only-directive';
import { DistributionDashboardComponent } from './components/medex/trips-distribution/distribution-dashboard/distribution-dashboard.component';
import { EnterprisesListComponent } from './components/medex/enterprises/enterprises-list/enterprises-list.component';
import { EnterprisesAddComponent } from './components/medex/enterprises/enterprises-add/enterprises-add.component';
import { TripsComponent } from './components/medex/trips-distribution/trips/trips.component';
import { NumbersOnlyDirective } from './directives/numbers-only/numbers-only.directive';
import { MembersListComponent } from './components/medex/members/members-list/members-list.component';
import { ParseTripModalComponent } from './components/shared/slideouts/parse-trip-modal/parse-trip-modal.component';
import { ThresholdListComponent } from './components/medex/threshold/threshold-list/threshold-list.component';
import { NewTripComponent } from './components/medex/trips-distribution/new-trip/new-trip.component';
import { DistributionActionsDropdownComponent } from './components/shared/slideouts/distribution-actions-dropdown/distribution-actions-dropdown.component';
import { CompanyAssignModalComponent } from './components/shared/slideouts/company-assign-modal/company-assign-modal.component';
import { TripLogsComponent } from './components/shared/slideouts/trip-logs/trip-logs.component';
import { DistributionTripDetailModalComponent } from './components/shared/slideouts/distribution-trip-detail-modal/distribution-trip-detail-modal.component';
import { EditTripModalComponent } from './components/medex/trips-distribution/edit-trip-modal/edit-trip-modal.component';
import { EnterpriseCancelTripModalComponent } from './components/shared/slideouts/enterprise-cancel-trip-modal/enterprise-cancel-trip-modal.component';
import { DashboardCredentialsComponent } from './components/credentials/dashboard-credentials/dashboard-credentials.component';
import { AddLevelOfServiceComponent } from './components/level-of-service/add-level-of-service/add-level-of-service.component';
import { LevelOfServiceComponent } from './components/level-of-service/level-of-service-list/level-of-service-list.component';
import { OnboardingListComponent } from './components/onboarding/onboarding-list/onboarding-list.component';
import { OnboardingCompanyComponent } from './components/onboarding/onboarding-company/onboarding-company.component';
import { OnboardingDriverComponent } from './components/onboarding/onboarding-driver/onboarding-driver.component';
import { OnboardingVehicleComponent } from './components/onboarding/onboarding-vehicle/onboarding-vehicle.component';
import { OnboardingWrapperComponent } from './components/onboarding/onboarding-wrapper/onboarding-wrapper.component';
import { MemberReportsComponent } from './components/distribution-reports/member-reports/member-reports.component';
import { EnterprisesReportsComponent } from './components/distribution-reports/enterprises-reports/enterprises-reports.component';
import { CompanyReportsComponent } from './components/distribution-reports/company-reports/company-reports.component';
import { DocumentsSlideoutComponent } from './components/shared/slideouts/documents-slideout/documents-slideout.component';
import { DocumentDetailsComponent } from './components/document/document-details/document-details.component';
import { LosThresholdComponent } from './components/los-threshold/los-threshold.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig  = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 80,
  bgsType: 'ball-spin-clockwise',
  blur: 0,
  fgsColor: '#727cf5',
  fgsPosition: 'center-center',
  fgsSize: 0,
  fgsType: 'ball-spin',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 150,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: '#ffffff',
  pbColor: '#727cf5',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
};

export const MY_MOMENT_FORMATS = {
  fullPickerInput: 'MM/DD/YYYY HHmm',
  parseInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SignInComponent,
    ForgotPasswordComponent,
    FilterByKeyPipe,
    EditUserProfileComponent,
    GooglePlaceDirective,
    DashboardComponent,
    SideNavComponent,
    NotificationsSlideoutComponent,
    OutsideClickDirective,
    AuthTokenComponent,
    ClockTimePipe,
    CamelCasePipe,
    SortByPipe,
    FilterPipe,
    SortByDatePipe,
    StatisticsComponent,
    NemtStatisticsComponent,
    MapviewComponent,
    StaffCreateComponent,
    StaffListComponent,
    CompanyCreateComponent,
    CompanyListComponent,
    SignupRequestComponent,
    CredentialsComponent,
    TripAssignModalComponent,
    CancelReasonModalComponent,
    HomeComponent,
    TripActionsDropdownComponent,
    DatePipe,
    CompanyNotesModalComponent,
    AlarmingComponent,
    TripDetailModalComponent,
    CompaniesFilterModalComponent,
    CreateFareComponent,
    FareListComponent,
    CredentialsDetailComponent,
    NotificationDetailsComponent,
    SortByNumberPipe,
    FormatTitleCasePipe,
    NotFoundComponent,
    PopperDirective,
    FormatNumberDirective,

    NumberDirective,
    DistributionDashboardComponent,
    EnterprisesListComponent,
    EnterprisesAddComponent,
    TripsComponent,
    NumbersOnlyDirective,
    MembersListComponent,
    ParseTripModalComponent,
    NewTripComponent,
    DistributionActionsDropdownComponent,
    ThresholdListComponent,
    CompanyAssignModalComponent,
    TripLogsComponent,
    DistributionTripDetailModalComponent,
    EditTripModalComponent,
    EnterpriseCancelTripModalComponent,
    DashboardCredentialsComponent,
    AddLevelOfServiceComponent,
    LevelOfServiceComponent,
    OnboardingListComponent,
    OnboardingCompanyComponent,
    OnboardingDriverComponent,
    OnboardingVehicleComponent,
    OnboardingWrapperComponent,
    MemberReportsComponent,
    EnterprisesReportsComponent,
    CompanyReportsComponent,
    DocumentsSlideoutComponent,
    DocumentDetailsComponent,
    LosThresholdComponent,

    ],

  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgPipesModule,
    TimeagoModule.forRoot(),
    HttpClientModule, // import HttpClientModule
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), // import NgxUiLoaderModule
    NgxUiLoaderHttpModule.forRoot({ exclude:['http://provider.qalbs.com/api/assigns/dashboard', 'http://provider.qalbs.com/api/notifications', 'http://provider.qalbs.com/api/assigns/corporate',
    'http://provider.qalbs.com/api/users/me',  'http://provider.qalbs.com/api/drivers/nearby'],
     showForeground: true }),
     CircleTimerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBNoxWXagcapR0tH-bxnJFPK28y_Oro2O0',
      libraries: ['places']
    }),
    SocketIoModule,
    AgmDirectionModule,
    TimezonePickerModule,
    BrowserAnimationsModule,

    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    AgmOverlays,
    ToastrModule.forRoot({
    timeOut: 5000,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true,
    progressBar:true
    }),
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot()
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: AuthorizationGuard, useClass: AuthorizationGuard },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },

    { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    MySocketWrapper,
    SocketService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
