import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { AssigTripsService } from 'src/app/components/assigned-trips/assigntrips-service/assigntrips-service';
import { sweetAlert } from 'src/app/utils/swal';

@Component({
  selector: 'app-distribution-dashboard',
  templateUrl: './distribution-dashboard.component.html',
  styleUrls: ['./distribution-dashboard.component.css']
})
export class DistributionDashboardComponent implements OnInit, OnDestroy {
  trips;
  selectedFile = null;
  submitted = false;
  htmlText = null;
  selectedCompany = null;
  invalidFileType = false;
  selectedCompanyName = 'epic';
  isDissable = false;
  brokers = [
    {
      name : "EPIC",
      imageURL : "assets/img/epic.png",
       isShow: false,
      value : "epic"
    }
  ]

  importModalObj = false;
  constructor(private assignTripsService: AssigTripsService,
     private router: Router) { }

  ngOnInit() {
    this.getTripsCount();
  }

  getTripsCount() {
    this.assignTripsService.getTripsCount()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      this.trips = data;
    });
  }

  viewImportModal() {
    this.importModalObj = true;
  }

  navigateToTrips(status: string) {
    this.router.navigate(['/distribution/trips'], { state: { status : status } });
  }

  ngOnDestroy(): void {
    
  }
}