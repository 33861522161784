import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { MenuService } from '../menu-service/menu.service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { Events } from 'src/app/utils/event-utils';
import { SignupRequestService } from 'src/app/components/signup-request/signup-request.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  user;
  menus;
  selectedMenu;
  // isSubMenu = false;
  isShowSmallScreenMenu = false;
  selectedMenukey;
  signupRequest;

  constructor(
    private signupRequestService: SignupRequestService,
    private socketService: SocketService,
    private sharedDataService: SharedDataService,
    private menuService: MenuService,
    public sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.router.events
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url) {
          // if (!this.selectedMenu) {
          //   const url = event.url.split("/");
            
          //   if (url[1] === "dashboard" || url[1] === "") {
          //     this.selectedMenu = "dashboard";
          //   } else {
          //     this.selectedMenu = url[1];
          //   }
          //   this.onSelectMenu(this.menus ? this.menus[this.selectedMenu] : null);
          // }
          const url = event.url.split("/");
          if (url[1] === "dashboard" || url[1] === "") {
            this.selectedMenu = "dashboard";
          } else {
            this.selectedMenu = url[1];
          }
          this.onSelectMenu(this.menus ? this.menus[this.selectedMenu] : null);
        }
      }
    });
    this.getUser();
    this.getSubMenus();
    this.getSmallScreenMenuState();
    this.listenSocket();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
 
          if (this.user && this.user.roles && this.user.platform) {
            if (this.user.roles === 'quarter') {
              if (this.user.roleType == 'admin') {
                
                this.menus = this.menuService.getMenu(this.user.roles ,this.user.platform );
                
              }
              else
                this.menus = this.menuService.getMenu(this.user.roleType , this.user.platform);
            }
            Object.keys(this.menus).forEach(key => {
              this.menus[key].sanitizedIcon = this.sanitizer.bypassSecurityTrustHtml(this.menus[key].icon);
            });
          }
        }
      });
  }

  sortMethod(a, b) {
    return a.value.position > b.value.position ? 1 : -1;
  }

  onSelectMenu(menu) {
    
    this.selectedMenu = menu;
    this.sharedDataService.saveSubMenus(menu ? menu : null);
  }

  getSubMenus() {
    this.sharedDataService
      .getSubMenus()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(menu => {
        if (menu && this.selectedMenu) {
          if (this.selectedMenu.key !== menu.key) {
            this.selectedMenu = menu;
          }
        }
      });
  }

  onSelectMenuSmallScreen(menu) {
    this.selectedMenu = menu;
    this.sharedDataService.saveSubMenus(menu ? menu : null);
    if (this.selectedMenukey === menu.key) {
      this.selectedMenukey = '';
    } else {
      this.selectedMenukey = menu.key;
    }
    this.sharedDataService.setSmallScreenMenuState(false);
  }

  getSmallScreenMenuState() {
    this.sharedDataService
      .getSmallScreenMenuState()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.isShowSmallScreenMenu = data;
      });
  }

  closeSmallScreenMenu() {
    this.sharedDataService.setSmallScreenMenuState(false);
  }

  setMenuCss(evt) {
    
    return 'bg-nav-light text-nav-light';
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (location.href) {
      let currentUrl = location.href.replace('http://', '');
      currentUrl = currentUrl.replace('https://', '');
      const url = currentUrl.split("/");
      if (url[1] === "dashboard" || url[1] === "") {
        this.selectedMenu = "dashboard";
      } else {
        this.selectedMenu = url[1];
      }
      this.onSelectMenu(this.menus ? this.menus[this.selectedMenu] : null);
    }
  }

  listenSocket() {
    this.socketService
      .addListener(Events.GET_SIGNUP_REQUEST_NOTIFICATION)
      .subscribe(data => {
        if (data) {
          this.toastr.success('Signup Request Received', 'Alert');
        }
      });
  }

  ngOnDestroy(): void {
    this.socketService.removeListener(Events.GET_SIGNUP_REQUEST_NOTIFICATION);
  }
  
}
