import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import * as _ from 'underscore';
import * as moment from 'moment-timezone';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { Events } from 'src/app/utils/event-utils';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { downloadFile } from 'src/app/utils/pdf-downloader';
import { urls } from 'src/app/utils/url-utils';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ToastrService } from 'ngx-toastr';
import { FilterByKeyPipe } from 'src/app/pipe/list-filterbykey-pipe/list-filterbykey-pipe';
import { FilterPipe } from 'src/app/pipe/list-filter-pipe/list-filter-pipe';
import { AssigTripsService } from 'src/app/components/assigned-trips/assigntrips-service/assigntrips-service';
import { MenuService } from 'src/app/components/shared/layout/menu-service/menu.service';
import { sweetAlert } from 'src/app/utils/swal';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.css']
})
export class TripsComponent implements OnInit {

  @ViewChild("timer", { static: false }) timer;
  // payorList = PAYOR;
  duration = 10 * 1000; // 10 seconds
  dataList = [];
  subMenus = [];
  filterdataList = [];
  todayDate;
  status = "";
  isTimerEnabled = false;
  search: any;
  assignModalObj;
  timeZone = 'America/New_York';
  settingInterval;
  user;
  orderBy = "scheduleTime";
  toggleActionButtons: boolean = false;
  view = "grid";
  selectedDropdownIndex: any;
  tripDetailModalObj;
  skip = 0;
  editTripModalObj;
  notesModalObj;
  cancelReasonModalObj
  tripLogsModalObj;
  isAuthorized = false;
  isForDriver = false;
  ifNoTrips;
  isFromDashboard = false;
  showDropdownFilter = false;
  selectedTrips = [];
  isAllTripsSelected: boolean = false;
  showSlideFilter = false;
  isMedical = false;
  isCorporate = false;
  isMarketplace = false;
  selectedBroker = "";
  selectedPayor = "";
  selectedDriver = "";
  selectedCooperate = "";
  selectedTripPurpose = '';
  selectedVehicle = '';


  constructor(
    private assignTripService: AssigTripsService,
    private sharedDataService: SharedDataService,
    private socketService: SocketService,
    private menuService: MenuService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    const routeData = this.router.getCurrentNavigation().extras.state;
    if (routeData && routeData.status) {
      this.isFromDashboard = true;
      this.status = routeData.status;
    }
 }

  ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      if(url[1].path === 'authorization'){
        this.isAuthorized = true;
      }
      this.getUser();
    });

  }

  setTripStatus(status, isSkipCheck?) {
    if (!isSkipCheck && this.status === status) return;
    this.skip = 0;
    this.clearTrips();
    this.selectedDropdownIndex = -1;
    this.status = status;
    this.getTrips();
    this.reset();
  }

  reset() {
    this.orderBy = "scheduleTime";
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.timeZone = data.timeZone;
          this.user = data;
          // this.todayDate = moment.tz(this.timeZone).startOf('day').toISOString();
          this.todayDate = moment.tz(this.timeZone).startOf("day").toISOString();
          if (this.user.hasOwnProperty("isListView") && this.user.isListView) {
            this.view = "list";
          }

          if (this.isAuthorized) {
            this.status = 'authorization';
            this.getTrips();
          } else {
            this.getStaffRoles();
          }
        }

      });

  }

  getTrips() {
    // this.filterdataList = [];
    // this.dataList = [];
    let object = {}

    if (this.status) {
      // search['status'] = this.status;

      object = {
        ...object,
        scheduleTime: this.todayDate,
        status: this.status
      };
    }
    this.ifNoTrips = false;
    // this.store.dispatch(new GetUnassignedList(object));
    this.assignTripService
      .getAllFacilityTrips(object)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data: any) => {
          this.ifNoTrips = true;
          this.filterdataList = data;
        }, err => {
          sweetAlert('Error', err, 'warning', 'OK');
        });
  }

  clearTrips() {
    this.skip = 0;
    this.filterdataList = [];
    this.dataList = [];
  }



  onDateChange(event) {
    this.reset();
    this.skip = 0;
    this.clearTrips();
    this.todayDate = event.toISOString()
    this.getTrips();
  }

  showActionButtons(idx) {
    this.selectedDropdownIndex = idx;
    this.toggleActionButtons = true;
  }

  closeActionMenu(evt) {
    this.selectedDropdownIndex = -1;
    this.toggleActionButtons = false;
  }


  // Update Job - Mark Ready, Mark VIP, Mark Confirm, Mark Voicemail
  updateJob(assign, assignKey) {
    let params = {
      assignId: assign._id,
    };
    params[assignKey] = !assign[assignKey];
    this.socketService.emit(Events.UPDATE_ASSIGN_TRIP, params);
  }

  viewTripDetails(trip) {
    this.tripDetailModalObj = trip;
  }

  viewTripLogs(trip) {
    this.tripLogsModalObj = trip;
  }

  editTrip(assign) {
    this.editTripModalObj = assign._id;
  }

  onEditTrip(event) {
    if (event) {
      const idx = this.filterdataList.findIndex((t) => t._id === event._id);
      // const index = this.filterdataList.findIndex(event._id);
      if (
        moment(event.appointmentTime).format("YYYY-MM-DD") !==
        moment(this.filterdataList[idx].appointmentTime).format(
          "YYYY-MM-DD"
        ) ||
        moment(event.scheduleTime).format("YYYY-MM-DD") !==
        moment(this.filterdataList[idx].scheduleTime).format("YYYY-MM-DD")
      ) {
        // this.clearTrips();
        // this.getTrips();
        this.filterdataList.splice(idx, 1);
      } else {
        this.filterdataList[idx] = event;
      }
      this.editTripModalObj = null;
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.settingInterval);
    this.socketService.removeListener(Events.UPDATE_ASSIGN_TRIP);
    this.sharedDataService.toggleDatePicker(false);
  }

  getStaffRoles() {
    if (this.user && this.user.roles) {
      if (typeof this.user.roles === "string") {
        if (this.user.sRoles)
          this.subMenus = this.menuService.getAssignedSubMenu(this.user.sRoles);
        else
          this.subMenus = this.menuService.getAssignedSubMenu(this.user.roles);
      } else if (typeof this.user.roles === "object") {
        this.subMenus = this.menuService.getAssignedSubMenu(this.user.roles[0]);
      }
    }

    if (this.subMenus && this.subMenus.length) {
      if (!this.isFromDashboard) {
      if (this.subMenus[0].value === "all" && this.subMenus.length > 1)
        this.status = this.subMenus[1].value;
      else this.status = this.subMenus[0].value;
      }
      this.getTrips();
    }
  }

  onTimerComplete(): void {
    this.timer.init();
    // this.clearTrips();
    this.skip = 0;
    this.getTrips();
  }

  toggleTimer() {
    this.isTimerEnabled = !this.isTimerEnabled;
    if (this.isTimerEnabled) {
      this.skip = 0;
      this.getTrips();
    } else {
      this.timer.init();
    }
  }

  viewNotes(trip) {
    this.notesModalObj = trip;
  }

  openAssignModal(trip, isForDriver) {
    this.assignModalObj = trip;
    if (isForDriver !== null) {
      this.isForDriver = isForDriver;
    }
  }

  onCloseAssignModal(evt) {
    if (evt) {
      this.getTrips();
    }
    this.assignModalObj = null;
    this.isForDriver = false;
  }

  distributeTrip(tripId) {
    this.assignTripService.distributeTrip(tripId)
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.toastr.success('Trip distributed successfully', 'Alert');
        } else {
          this.toastr.error('Trip distribution Fail', 'Alert');
        }
        this.getTrips();
      }, (e) => {
        this.toastr.error(e.error.message, 'Alert');
        this.getTrips();
      });
  }

  distributeMultiTrips(){
    this.assignTripService.distributeSelectedTrip(this.selectedTrips)
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (data) {
          for(let myTrip of data.assignedTrips) {
            this.filterdataList.forEach((assign) => {
              if(assign._id === myTrip._id) {
                this.toastr.success(`Trip '${myTrip.tripId}'` + ' distributed successfully', 'Alert');
              }
            })
          }

          for(let myTrip of data.unassignedTrips) {
            this.filterdataList.forEach((assign) => {
              if(assign._id === myTrip._id) {
                this.toastr.error(`Trip '${myTrip.tripId}'` + ' distribution fail', 'Alert');
              }
            })
          }
        } else {
          this.toastr.error('Trip distribution Fail', 'Alert');
        }
        this.getTrips();
      }, (e) => {
        this.toastr.error(e.error.message, 'Alert');
      });
  }

  closeCancelModal(cancelReason) {
    if (!cancelReason) {
      this.cancelReasonModalObj = null;
      return;
    }
    this.cancelReasonModalObj = null;
    this.reFreshCall(cancelReason);
  }

  cancelModal(trip) {
    this.cancelReasonModalObj = trip;
  }

  openEditTripModal(trip) {
    this.editTripModalObj = trip._id;
  }

  onCloseModal(evt) {
    if (evt) {
      this.editTripModalObj = null
      this.getTrips();
    } else {
      this.editTripModalObj = null
    }
  }

  reFreshCall(evt) {
    this.getTrips();
  }

  selectAllTrips() {
    this.filterdataList.forEach((assign) => {
      if (assign.status === "cancelled" && assign.job && assign.job._id) {
        return;
      }
      if (assign.issues && assign.issues.length) {
        return;
      }
      assign.isSelected = true;
      if(!this.selectedTrips.includes(assign._id))
      this.selectedTrips.push(assign._id);
    });
    // }

    this.isAllTripsSelected =
      this.selectedTrips.length === this.filterdataList.length;
    return this.isAllTripsSelected;
  }

  unSelectAllTrips() {
    const unselectedTrips = [];
    this.filterdataList.forEach((trip) => {
      trip.isSelected = false;
      unselectedTrips.push(trip._id);
    });

    this.selectedTrips = this.selectedTrips.filter(x => !unselectedTrips.includes(x));
    this.isAllTripsSelected = false;
  }

  onSelectTrip(trip) {
    const index = this.selectedTrips.indexOf(trip._id);
    if (index > -1) {
      trip.isSelected = false;
      this.selectedTrips.splice(index, 1);
    } else {
      trip.isSelected = true;
      this.selectedTrips.push(trip._id);
    }
  }

  showFilter() {
    this.showSlideFilter = !this.showSlideFilter;
  }

  closeFilter() {
    this.showSlideFilter = false;
  }

  filterTripsByModel() {
    this.filterdataList = this.dataList;
    this.filterdataList = new FilterPipe().transform(
      this.filterdataList,
      this.search
    );
  }

  setTripFilter(tripType) {
    this.filterTripsByModel();
  }
}
