import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { EnterpriseService } from '../../medex/enterprises/enterprise-service/enterprise.service';
import { ReportsService } from '../../reports/reports.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-enterprises-reports',
  templateUrl: './enterprises-reports.component.html',
  styleUrls: ['./enterprises-reports.component.css']
})
export class EnterprisesReportsComponent implements OnInit, OnDestroy {
  searchForm: FormGroup;
  enterprises = [];
  submitted = false;
  enterpriseReportData = [];
  selectedFacility = null;
  startDate = null;
  endDate = null;
  totalJobFee = 0;

  constructor(private enterpriseService: EnterpriseService,
    private fb: FormBuilder,
    private reportsService: ReportsService) { }

  ngOnInit() {
     this.searchForm = this.fb.group({
        user: ['', Validators.required],
        reportType: ['facility', Validators.required],
        providerStatus: [''],
        startDate: [null],
        endDate: [null],
        dateRange: ['']
      })

      this.getEnterprises();
  }

  getEnterprises(){
    this.enterpriseService.getAllFacilities('facility')
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data =>{
      if(data){
        this.enterprises = data
      }
    })
  }

  onDateChange(event) {
    if (event) {
      this.searchForm.patchValue({
        startDate: moment(event[0]).startOf("day").toISOString(),
        endDate: moment(event[1]).endOf("day").toISOString()
      });
    }
  }

  get form() {
    return this.searchForm.controls;
  }

  onSubmit() {
    this.enterpriseReportData = undefined;
    this.submitted = true;
    if (this.searchForm.invalid) {
      return
    }

    this.reportsService.getFacilityReport(this.searchForm.value)
      .subscribe(data => {
        if (data && data['length'] > 0) {
          this.enterpriseReportData = data[0].jobs;
          this.enterpriseReportData.sort((val1, val2)=> {return  moment(val1.scheduleTime).diff(moment(val2.scheduleTime), 'minutes') })
          this.enterprises.forEach(element => {
            if (element._id === this.searchForm.value.user) {
              this.selectedFacility = element;
            }
          });
          this.startDate = this.searchForm.value.startDate;
          this.endDate = this.searchForm.value.endDate;
          this.calculateJobFee(this.enterpriseReportData);
        }
      })
  }

  calculateJobFee(trips) {
    this.totalJobFee = 0;
    trips.forEach(element => {
      this.totalJobFee += element.jobFee;
    });
  }

  downloadFacilityReport() {
    if (this.enterpriseReportData.length > 0) {
      this.reportsService.downloadFacilityReport(this.enterpriseReportData,this.selectedFacility, this.startDate, this.endDate, this.totalJobFee, 'Enterprise Trips Report')
        .subscribe(data => {
          console.log(data);
          // console.log(url, fileName);
          const body = <HTMLDivElement>document.body;
          const linkSource = data['pdfFile'];
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.target = '_blank';
          downloadLink.download = data['fileName'];
          body.appendChild(downloadLink);
          console.log(downloadLink)
          console.log(data['pdfFile'])
          console.log(data['fileName'])
          console.log(downloadLink.click())
          // downloadLink.click();
          // downloadLink.dispatchEvent(new MouseEvent('click'));
          var event = document.createEvent('Event');
          event.initEvent('click', true, true);
          downloadLink.dispatchEvent(event);
          // downloadBase64File(data.content, data.pdfName);
        })
    }
  }

  ngOnDestroy(): void {
    
  }
}
