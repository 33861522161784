import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class ThresholdService {

  constructor(
    private http: HttpClient
  ) { }

  getProviderThresholds(): Observable<any> {
    return this.http.get(urls.BASE_URL + "providerthresholds");
  }

  updateThresholds(payload) {
    return this.http.put(urls.BASE_URL + "providerthresholds/" + payload._id, payload);
  }

  createThresholds(payload) {
    return this.http.post(urls.BASE_URL + "providerthresholds",payload);
  }
}
