import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-companies-filter-modal',
  templateUrl: './companies-filter-modal.component.html',
  styleUrls: ['./companies-filter-modal.component.css']
})
export class CompaniesFilterModalComponent implements OnInit, OnChanges {

  @Input() companiesdata: any[];
  @Output() closeModal = new EventEmitter<any>();

  @Output() selectedCompanies = new EventEmitter<any>();


  form: FormGroup;
  companyFilter = '';

  constructor(private fb: FormBuilder,) { }
  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
    this.form = this.fb.group({
      companyIds: this.fb.array([])
    })
  }

  onCloseModal() {
    this.closeModal.emit(null);
  }

  onCompanyCheckboxChange(e) {
    const companyIds: FormArray = this.form.get('companyIds') as FormArray;

    if (e.target.checked) {
      companyIds.push(new FormControl(e.target.value));

    } else {
      let i: number = 0;
      companyIds.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          companyIds.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  // fetchAlarmingTrips() {
  //   // this.alarmingTrips = [];
  //   this.alarmingTripService
  //   .getAlarmingTrips(this.form.value.companyIds.toString())
  //   .subscribe((data: any) => {
  //     if (data && data.assigns && data.assigns.length) {
  //       this.alarmingTrips = [];
  //       this.alarmingTrips = data.assigns;
  //     } else {
  //       this.alarmingTrips = [];
  //     }
  //   });
  // }

  isCompanySelected(companyId) {
    const companiesIds = this.form.value.companyIds;
    let found = false;
    if (companiesIds) {
      if (companiesIds.indexOf(companyId) > -1) {
        found = true;
      }
    }

    return found;
  }

  onSubmit() {
    this.selectedCompanies.emit(this.form.value.companyIds);

    this.onCloseModal();
  }

}
