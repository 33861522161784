import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { EditUserProfileComponent } from './components/user profile/edit-user-profile/edit-user-profile.component';
import { AuthorizationGuard } from './service/auth-guard/authorization-guard.service';
import { DashboardComponent } from './components/assigned-trips/dashboard/dashboard.component';
import { AuthTokenComponent } from './components/auth-token/auth-token.component';
import { StaffListComponent } from './components/staff/staff-list/staff-list.component';
import { StaffCreateComponent } from './components/staff/staff-create/staff-create.component';
import { CompanyCreateComponent } from './components/companies/company-create/company-create.component';
import { CompanyListComponent } from './components/companies/company-list/company-list.component';
import { SignupRequestComponent } from './components/signup-request/signup-request/signup-request.component';
import { NemtStatisticsComponent } from './components/reports/nemt-statistics/nemt-statistics.component';
import { CredentialsComponent } from './components/credentials/credentials/credentials.component';
import { MapviewComponent } from './components/mapview/mapview.component';
import { HomeComponent } from './components/home/home.component';
import { AlarmingComponent } from './components/alarming/alarming-trips/alarming.component';
import { CreateFareComponent } from './components/companies/fares/create-fare/create-fare.component';
import { FareListComponent } from './components/companies/fares/fare-list/fare-list.component';
import { CredentialsDetailComponent } from './components/credentials/credentials-detail/credentials-detail.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { DistributionDashboardComponent } from './components/medex/trips-distribution/distribution-dashboard/distribution-dashboard.component';
import { EnterprisesListComponent } from './components/medex/enterprises/enterprises-list/enterprises-list.component';
import { EnterprisesAddComponent } from './components/medex/enterprises/enterprises-add/enterprises-add.component';
import { TripsComponent } from './components/medex/trips-distribution/trips/trips.component';
import { MembersListComponent } from './components/medex/members/members-list/members-list.component';
import { NewTripComponent } from './components/medex/trips-distribution/new-trip/new-trip.component';
import { ThresholdListComponent } from './components/medex/threshold/threshold-list/threshold-list.component';
import { OnboardingListComponent } from './components/onboarding/onboarding-list/onboarding-list.component';
import { OnboardingWrapperComponent } from './components/onboarding/onboarding-wrapper/onboarding-wrapper.component';
import { DashboardCredentialsComponent } from './components/credentials/dashboard-credentials/dashboard-credentials.component';
import { AddLevelOfServiceComponent } from './components/level-of-service/add-level-of-service/add-level-of-service.component';
import { LevelOfServiceComponent } from './components/level-of-service/level-of-service-list/level-of-service-list.component';
import { StatisticsComponent } from './components/reports/statistics/statistics.component';
import { NotificationDetailsComponent } from './components/notification/notification-details/notification-details.component';
import { MemberReportsComponent } from './components/distribution-reports/member-reports/member-reports.component';
import { EnterprisesReportsComponent } from './components/distribution-reports/enterprises-reports/enterprises-reports.component';
import { CompanyReportsComponent } from './components/distribution-reports/company-reports/company-reports.component';
import { DocumentDetailsComponent } from './components/document/document-details/document-details.component';


const routes: Routes = [
  { path: 'login', component: SignInComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },

  // Dispatch Center
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: HomeComponent, canActivate: [AuthorizationGuard] },


  { path: 'auth-token/:token', component: AuthTokenComponent },

  // Operations
  // { path: 'dashboard', component: DashboardComponent, canActivate: [AuthorizationGuard] },

  // Operations
  { path: 'operations', component: DashboardComponent, canActivate: [AuthorizationGuard] },

  // alarming

  { path: 'alarming', component: AlarmingComponent, canActivate: [AuthorizationGuard] },

  // Profile
  { path: 'profile', component: EditUserProfileComponent, canActivate: [AuthorizationGuard] },

  //Distribution
  {path : 'distribution', redirectTo: 'distribution/dashboard', pathMatch: 'full' },
  {path : "distribution/dashboard" , component:DistributionDashboardComponent, canActivate: [AuthorizationGuard]},
  {path : "distribution/authorization" , component:TripsComponent, canActivate: [AuthorizationGuard]},
  {path : "distribution/trips" , component:TripsComponent, canActivate: [AuthorizationGuard]},
  {path : "distribution/new" , component:NewTripComponent, canActivate: [AuthorizationGuard]},


  //Enterprises
  {path : 'enterprises', redirectTo: 'enterprises/enterprise', pathMatch: 'full' },
  {path:'enterprises/enterprise', component:EnterprisesListComponent, canActivate:[AuthorizationGuard]},
  {path:'enterprises/enterprise/add', component:EnterprisesAddComponent, canActivate:[AuthorizationGuard]},
  {path:'enterprises/enterprise/:id/edit', component:EnterprisesAddComponent, canActivate:[AuthorizationGuard]},

  //Member
  {path:'enterprises/members', component: MembersListComponent, canActivate:[AuthorizationGuard]},
  {path:'enterprises/members/add', component: EnterprisesAddComponent, canActivate:[AuthorizationGuard]},
  {path:'enterprises/members/:id/edit', component: EnterprisesAddComponent, canActivate:[AuthorizationGuard]},



  // credentialing

  { path: 'credentialing', redirectTo: 'credentialing/companies', pathMatch: 'full' },

  // credentials
  { path: 'credentialing/dashboard', component: DashboardCredentialsComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentialing/onboarding', component: OnboardingListComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentialing/onboarding/:id', component: OnboardingWrapperComponent, canActivate: [AuthorizationGuard] },

  // Signup Request
  { path: 'credentialing/signuprequest', component: SignupRequestComponent, canActivate: [AuthorizationGuard] },

  // staff
  { path: 'credentialing/staff', component: StaffListComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentialing/staff/add', component: StaffCreateComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentialing/staff/:id/edit', component: StaffCreateComponent, canActivate: [AuthorizationGuard] },

  // companies
  { path: 'credentialing/companies', component: CompanyListComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentialing/companies/add', component: CompanyCreateComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentialing/companies/:id/edit', component: CompanyCreateComponent, canActivate: [AuthorizationGuard] },

  //fares
  // { path: 'credentialing/companies/fares/:id/list/add', component: CreateFareComponent, canActivate: [AuthorizationGuard] },
  // { path: 'credentialing/companies/fares/list/:id/edit', component: CreateFareComponent, canActivate: [AuthorizationGuard] },
  // { path: 'credentialing/companies/fares/:id/list', component: FareListComponent, canActivate: [AuthorizationGuard] },

  // Fares - New
  { path: 'credentialing/onboarding/:id/fares/add', component: CreateFareComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentialing/onboarding/:id/fares/:id/edit', component: CreateFareComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentialing/onboarding/:id/fares', component: FareListComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentialing/onboarding/:id', component: OnboardingWrapperComponent, canActivate: [AuthorizationGuard] },

  // Mapview
  { path: 'credentialing/threshold', component: ThresholdListComponent, canActivate: [AuthorizationGuard] },

  // mapview
  { path: 'map-view', component: MapviewComponent, canActivate: [AuthorizationGuard] },

  // reports
  { path: 'reports/statistics', component: StatisticsComponent, canActivate: [AuthorizationGuard] },
  { path: 'reports/nemtstatistics', component: NemtStatisticsComponent, canActivate: [AuthorizationGuard] },
  { path: 'reports/member', component: MemberReportsComponent, canActivate: [AuthorizationGuard] },
  { path: 'reports/enterprise', component: EnterprisesReportsComponent, canActivate: [AuthorizationGuard] },
  { path: 'reports/company', component: CompanyReportsComponent, canActivate: [AuthorizationGuard] },



  { path: 'credentialing/levelofservice/:id/edit', component: AddLevelOfServiceComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentialing/levelofservice/add', component: AddLevelOfServiceComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentialing/levelofservice', component: LevelOfServiceComponent, canActivate: [AuthorizationGuard] },

  { path: 'notifications', component: NotificationDetailsComponent, canActivate: [AuthorizationGuard] },
  { path: 'documents', component: DocumentDetailsComponent, canActivate: [AuthorizationGuard] },


  // Not Found
  { path: '**', component: NotFoundComponent, canActivate: [AuthorizationGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
