import { Component, OnInit, OnDestroy } from '@angular/core';
import { sweetAlert } from 'src/app/utils/swal';
import { AssigTripsService } from '../../assigned-trips/assigntrips-service/assigntrips-service';

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.css']
})
export class DocumentDetailsComponent implements OnInit {
  search = '';
  documents = [];
  constructor(
    private tripService: AssigTripsService
  ) {
  }

  ngOnInit() {
    this.getDocuments();
  }

  getDocuments() {
    this.tripService
      .getDocuments()
      .subscribe((data: any) => {
        if (data) {
          this.documents = data;
        }
      }, err => {
        // sweetAlert('Error', err, 'warning', 'OK');
      });
}
}