import { Component, OnInit } from '@angular/core';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { CompaniesService } from '../../companies/service/companies.service';
import { OnboardingService } from '../onboarding-service/onboarding.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { environment } from 'src/environments/environment';
import * as moment from "moment-timezone";

@Component({
  selector: 'app-onboarding-list',
  templateUrl: './onboarding-list.component.html',
  styleUrls: ['./onboarding-list.component.css']
})
export class OnboardingListComponent implements OnInit {
  today = moment();
  companies = [];
  todayOnboarding = [];
  search;
  canView = false;
  viewAccess = false;
  user;
  timeZone = 'America/New_York';

  constructor(
    private sharedData: SharedDataService,
    private onboardingService: OnboardingService,
    private companyService: CompaniesService
  ) {  
    document.addEventListener('keyup', e => {
    // e.ctrlKey &&
    if (e.altKey && (e.key.toLowerCase() === 'v' || e.key.toLowerCase() === '√') && this.viewAccess) {
      this.canView = !this.canView;
    }
  });
}

  ngOnInit() {
    this.sharedData.getUser()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      if (data) {
        this.user = data;
        this.timeZone = this.user.timeZone;
        if (data.roleType === 'admin') {
          this.viewAccess = true;
        }
        this.getCompanies();
      }

    });
    
  }

  getCompanies() {
    this.onboardingService
      .getAlivisCompanies()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          // this.companies = data;
          const onboardingCompanies = data;
          onboardingCompanies.forEach(company => {
            const createdAt = moment(company.createdAt).tz(this.timeZone);
            const diff = createdAt.diff(this.today, 'days');
            if (diff === 0) {
              this.todayOnboarding.push(company);
            } else {
              this.companies.push(company);
            }
          });
        }
      });
  }

  openOnboardPanel(companyId) {
    this.companyService.getCompanyToken(companyId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (environment.production) {
          window.open(`${environment.CREDENTIALING_ONBOARD_URL}dashboard?auth_token=${data.companyToken}`, '_blank');
        }
        else {
          window.open(`${environment.CREDENTIALING_ONBOARD_URL}dashboard?auth_token=${data.companyToken}`, '_blank');
        }

      });
  }

  ngOnDestroy(): void {
  }
}
