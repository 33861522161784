import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import {
  ImageSnippet,
  imageProcessing,
} from "src/app/utils/image-processing-utils";
import { UserProfileService } from "src/app/components/user profile/user-profile-service/user-profile.service";
import { AuthService } from "src/app/service/auth-service/auth.service";
import { sweetAlert } from "src/app/utils/swal";
import { EnterpriseService } from "../enterprise-service/enterprise.service";
import { data } from "jquery";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-enterprises-add",
  templateUrl: "./enterprises-add.component.html",
  styleUrls: ["./enterprises-add.component.css"],
})
export class EnterprisesAddComponent implements OnInit {
  editProfileForm: FormGroup;
  submitted = false;
  userData = null;
  profileImageUrl = null;
  fileToUpload: ImageSnippet = null;
  image = null;

  timeZone = "America/New_York";

  placeholderString = "Select timezone";

  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  toggleCurrentPassword = true;
  toggleNewPassword = true;
  toggleConfirmPassword = true;
  user;
  facilityUserId = '';
  currentRoute;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private sharedDataService: SharedDataService,
    private enterpriseService: EnterpriseService,
    private activatedRoute: ActivatedRoute
  ) {}
  
  get form() {
    return this.editProfileForm.controls;
  }

  ngOnInit() {
    this.getUser();
    this.activatedRoute.params
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        if (params && params["id"]) {
          this.facilityUserId = params["id"];
          this.getEnterprise();
        }
      });



    this.activatedRoute.url.subscribe((urlSegments) => {
      this.currentRoute = urlSegments[urlSegments.length - 2].path;
    });

    this.editProfileForm = this.fb.group({
      displayName: ["", Validators.required],
      email: ["", Validators.required],
      address: ["", Validators.required],
      timeZone: [this.timeZone, Validators.required],
      contactNumber: ["", Validators.required],
      password: ["",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(16),
        ],
      ],
      latitude: ["", Validators.required],
      longitude: ["", Validators.required],
      profileRole: ["", Validators.required],
    });

    if (this.facilityUserId) {
      this.editProfileForm.controls.password.clearValidators();
      this.editProfileForm.controls.password.updateValueAndValidity();

    }

  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((user) => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
          this.userData = user;
        }
      });
  }

  getEnterprise() {
    this.enterpriseService
      .getFacilityToEdit(this.facilityUserId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.editProfileForm.patchValue({
            ...data,
            password: '',
          });
          this.profileImageUrl = data.profileImageURL;
          this.timeZone = data.timeZone;
        }
      });
  }

  togglePassword(fieldName: string) {
    switch (fieldName) {
      case "current":
        this.toggleCurrentPassword = !this.toggleCurrentPassword;
        break;
      case "new":
        this.toggleNewPassword = !this.toggleNewPassword;
        break;
      case "confirm":
        this.toggleConfirmPassword = !this.toggleConfirmPassword;
        break;
    }
  }

  processFile(imageInput: any) {
    this.submitted = false;
    imageProcessing(imageInput)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((result) => {
        this.fileToUpload = result.file;
        this.profileImageUrl = result.src;
      });
  }

  changeTimezone() {
    this.editProfileForm.patchValue({ timeZone: this.timeZone });
  }

  onSubmit() {
    this.submitted = true;

    if (!this.facilityUserId) {
      this.editProfileForm.patchValue({
        profileRole: this.currentRoute == 'enterprise' ? 'facility' : 'member'
      });
    }

    if (this.editProfileForm.invalid) {
      return;
    }

    const payload = this.editProfileForm.value;
    if(this.facilityUserId && this.editProfileForm.value.password == ''){
      delete payload.password;
    }
    this.enterpriseService
      .createNewFacility(payload, this.fileToUpload, this.facilityUserId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {

          this.router.navigateByUrl("enterprises");
        }
      });

  }

  getAddress(place) {
    if (place.formatted_address) {
      this.editProfileForm.patchValue({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        address: place.formatted_address,
      });
    }
  }

  onChangePassword(event) {    
    if(this.facilityUserId){
        this.editProfileForm.controls.password.setValidators([Validators.minLength(6), Validators.maxLength(16)]);
        this.editProfileForm.controls.password.updateValueAndValidity();
    }
    
  }

  ngOnDestroy(): void { }
}
