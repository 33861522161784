import { Pipe, PipeTransform } from '@angular/core';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import * as moment from 'moment-timezone';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {
  timeZone = 'America/New_York';

  constructor(
    private sharedDataService: SharedDataService
  ) {
    // console.log('TEST100:DatePipe:constructor:');
    if (!this.timeZone) {
      this.sharedDataService
        .getUser()
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(user => {
          if (user && user.timeZone) {
            this.timeZone = user.timeZone;
          }
        });
    }
  }

  transform(
    value: any, 
    format?: string, 
    preferredTimeZone?: string
  ): any {
    const timeZone = preferredTimeZone ? preferredTimeZone : this.timeZone;
    const dateFormat = format ? format : "MM/DD/YYYY HHmm";
    value = moment.utc(value).tz(timeZone).format(dateFormat);
    return value;
  }

  ngOnDestroy(): void { }
  
}
