import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { EnterpriseService } from '../enterprise-service/enterprise.service';
import { CompaniesService } from 'src/app/components/companies/service/companies.service';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
@Component({
  selector: 'app-enterprises-list',
  templateUrl: './enterprises-list.component.html',
  styleUrls: ['./enterprises-list.component.css']
})
export class EnterprisesListComponent implements OnInit, OnDestroy {
  enterprises = [];
  searchFacility: any
  status = 'active'
  search

  canView = false;
  viewAccess = false;

  constructor(private enterpriseService: EnterpriseService,
    private sharedData: SharedDataService,
    private companyService: CompaniesService
  ) {
    document.addEventListener('keyup', e => {
      // e.ctrlKey &&
      if (e.altKey && (e.key.toLowerCase() === 'v' || e.key.toLowerCase() === '√') && this.viewAccess) {
        this.canView = !this.canView;
      }
    });
  }

  ngOnInit() {
    this.getUser()
  }

  getUser() {
    this.sharedData.getUser()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      if(data){
        if (data.roleType === 'admin') {
          this.viewAccess = true;
        }
      }
    });
    this.getEnterprises()
  }
  getEnterprises(){
    this.enterpriseService.getAllFacilities('facility')
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data =>{
      console.log('Facility Data',data);
      if(data){

        this.enterprises = data
      }
    })
  }

  openPanel(id) {
    this.companyService.getCompanyToken(id,'facility')
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if(environment.production) {
          window.open(`${environment.ENTERPRISE_ONBOARD_URL}auth-token/${data.companyToken}`, '_blank');
        }
        else {
          window.open(`${environment.ENTERPRISE_ONBOARD_URL}auth-token/${data.companyToken}`, '_blank');
        }

      });
  }
  
  ngOnDestroy(): void {
    
  }
}
