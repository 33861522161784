import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { CompaniesService } from 'src/app/components/companies/service/companies.service';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { Events } from 'src/app/utils/event-utils';
import { sweetAlert } from 'src/app/utils/swal';

@Component({
  selector: 'app-trip-assign-modal',
  templateUrl: './trip-assign-modal.component.html',
  styleUrls: ['./trip-assign-modal.component.css']
})
export class TripAssignModalComponent implements OnInit {
  @Input('trip') assignDriverModalObj: any;
  @Input() selectedCompany: any;
  @Output() onCloseModal = new EventEmitter<any>();
  cancelReasonModalObj;
  isSubmitted = false;
  nearbyDrivers = [];
  selectedDriver;
  driverIndex = -1;
  search: any;

  constructor (
    private socketService: SocketService,
    private companyService: CompaniesService
  ) { }

  ngOnInit() {
    this.getNearbyDriver(
      this.assignDriverModalObj.jobOriginLatitude,
      this.assignDriverModalObj.jobOriginLongitude
    );

    this.listenSocket();
  }

  listenSocket() {
    this.socketService.addListener(Events.UPDATE_ASSIGN_TRIP)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data.success) {
          if (data.assign) {
            const trip = data.assign;
            sweetAlert('Success', "Trip Updated Succesfully", 'success', 'OK')
            this.onCloseModal.emit(trip);
            // if (this.assigns && this.assigns.length) {
            //   const idx = this.assigns.findIndex((assign) => assign._id == trip._id);
            //   this.assigns[idx].isReadyForPickup = trip.isReadyForPickup;
            //   this.assigns[idx].isVipTrip = trip.isVipTrip;
            //   this.assigns[idx].isConfirmedTrip = trip.isConfirmedTrip;
            //   this.assigns[idx].isVoiceMail = trip.isVoiceMail;
            // }
          }
        }
      });

    this.socketService
      .addListener(Events.GET_MARK_READY_NOTIFICATION)
      .subscribe(data => {
        if (this.assignDriverModalObj && data && data.assign) {
          const trip = data.assign;
          if (this.assignDriverModalObj._id === trip._id) {
            this.assignDriverModalObj.isReadyForPickup = trip.isReadyForPickup;
          }
        }
      });
  }

  getNearbyDriver(lat, lng) {
    const data = {
      company: this.selectedCompany,
      isReassigned: false,
      isNearBy: true,
      jobOriginLatitude: lat,
      jobOriginLongitude: lng,
    };

    this.companyService
      .getNearbyDrivers(data)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response: any) => {
        this.nearbyDrivers = response.drivers;

      });
  }

  getMiles(driverIndex, driver) {
    // console.log('driverIndex', driverIndex)
    this.driverIndex = -1;

    if (this.assignDriverModalObj.jobOriginLatitude && this.assignDriverModalObj.jobOriginLongitude && this.assignDriverModalObj.jobDestinationLatitude && this.assignDriverModalObj.jobDestinationLongitude) {
      const locationParams = {
        origin: this.assignDriverModalObj.jobOriginLatitude + ' , ' + this.assignDriverModalObj.jobOriginLongitude,
        destination: driver.coords[1] + ' , ' + driver.coords[0]
        // destination: vm.assign.jobDestinationLatitude + ' , ' + vm.assign.jobDestinationLongitude
      };
      locationParams['dbMiles'] = driver.miles || 0;
      locationParams['isLog'] = true;

      this.socketService.emit(Events.GET_DRIVER_MILES_FOR_ASSIGN_MODAL, locationParams);
      // Socket.emit(‘assignTripManualFormETA’, locationParams);
      this.driverIndex = driverIndex;
    } else {
      sweetAlert('Error', 'Please Select Pickup and DropOff Address', 'warning', 'OK');
    }
  }

  onSelectDriver(driver) {
    this.selectedDriver = driver;
  }

  updateTrip(type, event?) {
    let data: any = {
      company: this.selectedCompany,
      selectedTrips: [this.assignDriverModalObj._id],
      status: type, // unassigned marketplaced archived cancelled offered
    };

    if (type === "offered") {
      if (this.selectedDriver) {
        data = {
          ...data,
          driver: this.selectedDriver, // send in offered status
          note: this.assignDriverModalObj.note, // optional in offered case
        };
      } else {
        return;
      }
    } else if (type === "cancelled") {
      data = {
        ...data,
        changeReason: event,
      };
    }

    this.companyService
      .assiginDriver(data)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', "Trip Updated Succesfully", 'success', 'OK')
          this.onCloseModal.emit(data);
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  toggleMarkReady() {
    let params = {
      assignId: this.assignDriverModalObj._id,
      isReadyForPickup: !this.assignDriverModalObj.isReadyForPickup
    }
    this.socketService.emit(Events.UPDATE_ASSIGN_TRIP, params);
  }

  closeModal() {
    this.onCloseModal.emit();
  }

  openCancelModal(trip) {
    this.cancelReasonModalObj = trip;
  }

  closeCancelModal(evt) {
    this.cancelReasonModalObj = null;
    if (evt) {
      this.updateTrip('cancelled', evt);
    }
  }

  getETA(trip) {
    this.companyService
      .getETA(trip._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (data) {
          this.assignDriverModalObj.etaMessage = data.etaMessage;
        }
      });
  }
  
  ngOnDestroy(): void {
  }
}
