import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { urls } from '../../utils/url-utils';
import { catchError } from 'rxjs/operators';
import { errorHandl, setFormData, setFormDataV2 } from 'src/app/utils/network-utils';


@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor(private http:HttpClient) { }


  getQuarterlist(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_QUARTER_LIST);
      
  }



  
  
  getStaffData(id){
    return this.http.get<any>(urls.BASE_URL + urls.GET_QUARTER_LIST + `/${id}`);
  }
  // createStaff(formData):Observable<any> {
  //   return this.http.post<any>(urls.BASE_URL + urls.GET_QUARTER_LIST, formData);
  // }

  // updateStaff(id, payload):Observable<any> {

   
  //   return this.http.put(urls.BASE_URL + urls.GET_QUARTER_LIST + `/${id}`, payload).pipe(
  //     catchError(errorHandl)
  //   )
  // }


  saveStaff(id, payload): Observable<any> {
    const formData = setFormDataV2(payload, 'quarterpanel');
    
    if (id) {
      return this.http.put<any>(urls.BASE_URL + urls.GET_QUARTER_LIST + '/' + id, formData).pipe(
        catchError(errorHandl)
      );
    } else {
      return this.http.post<any>(urls.BASE_URL + urls.GET_QUARTER_LIST, formData).pipe(
        catchError(errorHandl)
      );
    }
  }

}
