import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { AssigTripsService } from 'src/app/components/assigned-trips/assigntrips-service/assigntrips-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { EnterpriseService } from '../../enterprises/enterprise-service/enterprise.service';
import { CompaniesService } from 'src/app/components/companies/service/companies.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-new-trip',
  templateUrl: './new-trip.component.html',
  styleUrls: ['./new-trip.component.css']
})
export class NewTripComponent implements OnInit, OnDestroy {
  @Input() tripId: any;
  @Output() onCloseModal = new EventEmitter<any>();
  private readonly searchSubject = new Subject<string | undefined>();
  private readonly inputSubject = new Subject<any>();
  tripDetails;
  legId = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  newTripForm: FormGroup;
  submitted = false;
  todayDate = moment().tz("America/New_York");
  user: any;
  timeZone: any = "America/New_York";
  legsForm: FormArray;
  enterprises;
  companies;
  assignedTrips = [];
  search;

  constructor(
    private tripService: AssigTripsService,
    private enterpriseService: EnterpriseService,
    private companyService:CompaniesService,
    private sharedDataService: SharedDataService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {

    this.searchSubject
    .pipe(debounceTime(1000))
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((name) => {
      if (name.length >= 3 && !this.tripId) {
        this.getMemberTrips(name);
      }
    });
    
    this.newTripForm = this.fb.group({
      // Origin Address
      jobOriginAddress: ["", Validators.required],
      jobOriginLatitude: [0, Validators.required],
      jobOriginLongitude: [0, Validators.required],

      // Destination Address
      jobDestinationAddress: ["", Validators.required],
      jobDestinationLatitude: [0, Validators.required],
      jobDestinationLongitude: [0, Validators.required],

      // Schedule & Appointment Time
      preScheduleTime: [this.todayDate],
      scheduleTime: [this.todayDate.toISOString(), Validators.required],
      preAppointmentTime: [moment(this.todayDate).add(1, 'hours')],
      appointmentTime: [moment(this.todayDate).add(1, 'hours').toISOString(), Validators.required],

      // Member Informtion
      priorityClient: this.fb.group({
        displayName: ["", Validators.required],
        contactNumber: ["", Validators.required],
        cellPhone: [""],
        clientId: ["", Validators.required]
      }),
      dob: ["", Validators.required],

      // Legs
      additionalOptions: ["singleLeg"],
      tripLegs: this.fb.array([this.createLeg(0)]),

      // Doctor
      tripReason: ["Doctor Appointment"],
      doctorName: ["", Validators.required],
      doctorContactNumber: ["", Validators.required],
      companyNote: [""],

      // Additional Member
      additionalMember: [false],
      additionalPersonName: [""],
      additionalPersonGender: [""],

      // Services Required
      isMedical: [true],
      iSschUrgentCare: [false],
      isUrgentCare: [true],
      isWheelChair: [false],
      isCarSeat: [false],
      isLongDistance: [false],
      isOxygen: [false],

      // Vehicle
      tripRequirement: ["AMB"],

      // Payor Information
      payorType: ["Medicaid"],
      medicalId: ["", Validators.required],
      memberHomeZipCode: ["", Validators.required],

      facility: ['',Validators.required],
      suggestedprovider: [null],

      // Not Used in View
      driverInstructions: [""],
      prefferedProvider: [{ value: false, disabled: true }],
      companyType: [{ value: "", disabled: true }],
      jobPassengerNumber: [1],
      jobType: ["assigned"],
      memberId: [""],
      milage: [null],
      isWillCall: [false],
      memberNotes: [""],
      changeReason: [""]
    });
    this.formValueChangeListener();
    this.getEnterprises();
    this.getProviders();
    this.getUser();
  }

  onChangeClientName(event: Event) {
    const searchQuery = (event.target as HTMLInputElement).value;
    this.searchSubject.next(searchQuery);
  }

  onChangeEnterprise() {
    const facility = this.enterprises.find(
      (e) => e._id === this.newTripForm.value.facility
    );
    if (facility) {
      this.timeZone = facility.timeZone;
      const preSchedule = this.newTripForm.value.preScheduleTime;
      const newPreSchedule = preSchedule.clone().tz(this.timeZone, true);
      const preAppointment = this.newTripForm.value.preAppointmentTime;
      const newPreAppointment = preAppointment.clone().tz(this.timeZone, true);

      // Apply changes according to the timezone of the selected enterprise/member (Add/Edit).
      this.newTripForm.patchValue({
        preScheduleTime: newPreSchedule,
        scheduleTime: newPreSchedule.toISOString(),
        preAppointmentTime: newPreAppointment,
        appointmentTime: newPreAppointment.toISOString(),
      });

      if (!this.tripId) {
        // Only for Add Op.
        // Apply timezone changes to legs' scheduletime.
        this.newTripForm.value.tripLegs.forEach((leg, index) => {
          const preSchedule = leg.preScheduleTime;
          const newPreSchedule = preSchedule.clone().tz(this.timeZone, true);
          this.legsForm.at(index).patchValue({
            preScheduleTime: newPreSchedule,
            scheduleTime: newPreSchedule.toISOString()
          });
        });
      }
      console.log('TST10: ', this.newTripForm.value);
    }
  }

  setDatesAccordingToEnterprise(enterpriseId) {
    const facility = this.enterprises.find(e => e._id === enterpriseId);
    if (facility) {
      this.timeZone = facility.timeZone;
      if (this.tripId) {
        this.newTripForm.patchValue({
          preScheduleTime: moment(this.tripDetails.scheduleTime).tz(this.timeZone),
          scheduleTime: moment(this.tripDetails.scheduleTime).tz(this.timeZone).toISOString(),
          preAppointmentTime: moment(this.tripDetails.appointmentTime).tz(this.timeZone),
          appointmentTime: moment(this.tripDetails.appointmentTime).tz(this.timeZone).toISOString(),
        });
      }
    }
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
          this.todayDate = moment().tz(this.timeZone);
          console.log('timezone: ', this.user.timeZone);
          this.newTripForm.patchValue({
            preScheduleTime: this.todayDate,
            scheduleTime: this.todayDate.toISOString(),
            preAppointmentTime: moment(this.todayDate).add(1, 'hours'),
            appointmentTime: moment(this.todayDate).add(1, 'hours').toISOString(),
          });

          // Get Trip Information (Edit)
          if (this.tripId) {
            this.getTripDetails();
          }
        }
      });
  }

  getEnterprises(){
    this.enterpriseService.getAllFacilities('facility')
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data)=>{
      if(data){
        this.enterprises = data;
      }
    });
  }

  getProviders(){
    const queryParams = {
      accountStatus: 'active',
      isRemoveIAAL: true
    }
    this.companyService.getCompanies(queryParams)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data)=>{
      if(data){
        this.companies = data
      }
    });
  }

  getTripDetails() {
    this.tripService
      .getTripDetailsById(this.tripId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.tripDetails = data;
          if (data.facility && data.facility.timeZone) {
            this.timeZone = data.facility.timeZone; 
          }
          this.newTripForm.patchValue({
            ...data,
            preScheduleTime: moment(data.scheduleTime).tz(this.timeZone),
            scheduleTime: moment(data.scheduleTime).tz(this.timeZone).toISOString(),
            preAppointmentTime: moment(data.appointmentTime).tz(this.timeZone),
            appointmentTime: moment(data.appointmentTime).tz(this.timeZone).toISOString(),

            companyType: data.companyType ? data.companyType : "",
            facility: data.facility ? data.facility._id : '',
            // tripRequirement: data && data.isMedical ? data.tripRequirement : "AMB",
            // isMedical: true,
          });

          if (data && data.additionalOptions === "multiLeg" && data.tripLegs) {
            this.clearTripLegs();
            this.patchLegData(data.tripLegs);
          } else {
            this.newTripForm.patchValue({
              additionalOptions: "singleLeg",
            });
          }
        }
      });
  }

  formValueChangeListener() {
    // additionalMember
    this.newTripForm
      .controls
      .additionalMember
      .valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((checked) => {
        if (checked) {
          this.newTripForm.controls.additionalPersonName.setValidators([Validators.required]);
          this.newTripForm.controls.additionalPersonGender.setValidators([Validators.required]);
        } else {
          this.newTripForm.patchValue({
            additionalPersonName: "",
            additionalPersonGender: "",
          });
          this.newTripForm.controls.additionalPersonName.clearValidators();
          this.newTripForm.controls.additionalPersonName.setErrors(null);
          this.newTripForm.controls.additionalPersonGender.clearValidators();
          this.newTripForm.controls.additionalPersonGender.setErrors(null);
        }
        this.newTripForm.updateValueAndValidity();
      });

    // PrefferedProvider
    this.newTripForm
      .controls
      .prefferedProvider
      .valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((checked) => {
        if (checked) {
          // this.newTripForm.controls.companyType.setValidators([Validators.required]);
        } else {
          this.newTripForm.patchValue({
            companyType: "",
          });
          this.newTripForm.controls.companyType.clearValidators();
          this.newTripForm.controls.companyType.setErrors(null);
        }
        this.newTripForm.updateValueAndValidity();
      }
      );
  }

  // ====================== Addresses & Date Starts ======================
  getOriginAddress(place) {
    this.newTripForm.patchValue({
      jobOriginAddress: place.formatted_address,
      jobOriginLatitude: place.geometry.location.lat(),
      jobOriginLongitude: place.geometry.location.lng(),
    });
    if (this.legsForm) {
      this.legsForm.at(0).patchValue({
        jobOriginAddress: place.formatted_address,
        jobOriginLatitude: place.geometry.location.lat(),
        jobOriginLongitude: place.geometry.location.lng(),
      });
    }
  }

  getDestinationAddress(place) {
    this.newTripForm.patchValue({
      jobDestinationAddress: place.formatted_address,
      jobDestinationLatitude: place.geometry.location.lat(),
      jobDestinationLongitude: place.geometry.location.lng(),
    });
    if (this.legsForm) {
      this.legsForm.at(0).patchValue({
        jobDestinationAddress: place.formatted_address,
        jobDestinationLatitude: place.geometry.location.lat(),
        jobDestinationLongitude: place.geometry.location.lng(),
      });
    }
  }

  onScheduleDateChange(evt) {
    this.newTripForm.patchValue({
      scheduleTime: moment(evt).tz(this.timeZone, true).toISOString(),
    });

    if (!this.tripId) {
      this.newTripForm.patchValue({
        preAppointmentTime: moment(evt).tz(this.timeZone, true).add(1, 'hours'),
        appointmentTime: moment(evt).tz(this.timeZone, true).add(1, 'hours').toISOString()
      });
    }
  }

  onAppointmentDateChange(evt) {
    this.newTripForm.patchValue({
      appointmentTime: moment(evt).tz(this.timeZone, true).toISOString()
    });
  }
  // ====================== Address & Date Ends ======================

  patchBooleanValues(type) {
    console.log(type);
    this.newTripForm.patchValue({
      [type]: !this.newTripForm.controls[type].value,
    });
  }

  getPatchBooleanValues(type) {
    return this.newTripForm.controls[type].value;
  }

  setTripRequirement(type) {
    this.newTripForm.patchValue({
      tripRequirement: type,
    });
  }

  // ==================================== Legs Code Starts ====================================
  createLeg(index): FormGroup {
    return this.fb.group({
      preScheduleTime: [this.todayDate, index > 0 ? Validators.required : null],
      scheduleTime: [this.todayDate.toISOString(), index > 0 ? Validators.required : null],

      jobOriginAddress: ["", index > 0 ? Validators.required : null],
      jobOriginLatitude: ["", index > 0 ? Validators.required : null],
      jobOriginLongitude: ["", index > 0 ? Validators.required : null],

      jobDestinationAddress: ["", index > 0 ? Validators.required : null],
      jobDestinationLatitude: ["", index > 0 ? Validators.required : null],
      jobDestinationLongitude: ["", index > 0 ? Validators.required : null],
      dob: [""],
    });
  }

  addLeg(noOfLegs) {
    console.log('Add Leg: ', noOfLegs);
    noOfLegs++;
    this.legsForm.push(this.createLeg(noOfLegs));
    if (noOfLegs === 1) {
      this.legsForm.at(noOfLegs).patchValue({
        jobOriginAddress: this.newTripForm.value.jobDestinationAddress,
        jobOriginLatitude: this.newTripForm.value.jobDestinationLatitude,
        jobOriginLongitude: this.newTripForm.value.jobDestinationLongitude,

        jobDestinationAddress: this.newTripForm.value.jobOriginAddress,
        jobDestinationLatitude: this.newTripForm.value.jobOriginLatitude,
        jobDestinationLongitude: this.newTripForm.value.jobOriginLongitude,

        preScheduleTime: moment(this.newTripForm.value.preAppointmentTime).add(1, 'hours'),
        scheduleTime: moment(this.newTripForm.value.preAppointmentTime).add(1, 'hours').toISOString(),
      });
      this.legsForm.at(0).patchValue({
        jobOriginAddress: this.newTripForm.value.jobDestinationAddress,
        jobOriginLatitude: this.newTripForm.value.jobDestinationLatitude,
        jobOriginLongitude: this.newTripForm.value.jobDestinationLongitude,
        jobDestinationAddress: this.newTripForm.value.jobOriginAddress,
        jobDestinationLatitude: this.newTripForm.value.jobOriginLatitude,
        jobDestinationLongitude: this.newTripForm.value.jobOriginLongitude
      });
    } else if (noOfLegs > 1) {
      this.legsForm.at(noOfLegs - 1).patchValue({
        jobDestinationAddress: "",
        jobDestinationLatitude: 0,
        jobDestinationLongitude: 0,
      });
      this.legsForm.at(noOfLegs).patchValue({
        jobDestinationAddress: this.newTripForm.value.jobOriginAddress,
        jobDestinationLatitude: this.newTripForm.value.jobOriginLatitude,
        jobDestinationLongitude: this.newTripForm.value.jobOriginLongitude,
        preScheduleTime: moment(this.legsForm.at(noOfLegs - 1).value.preScheduleTime).add(2, 'hours'),
        scheduleTime: moment(this.legsForm.at(noOfLegs - 1).value.preScheduleTime).add(2, 'hours').toISOString()
      });
    }

    // this.setTripLegTime(noOfLegs);
    if (this.newTripForm.value.tripId) {
      this.legsForm.at(noOfLegs).patchValue({
        tripId:
          this.newTripForm.value.tripId.split("-")[0] +
          "-Leg(" +
          this.legId[noOfLegs] +
          ")",
      });
    }
    console.log('Leg Added', this.newTripForm.value.tripLegs);
  }

  removeLeg(index) {
    console.log('Remove Leg: ', index);
    if (index === 0 && this.newTripForm.value.tripLegs.length > 2) {
      this.legsForm.removeAt(index + 1);
      this.legsForm.at(index + 1).patchValue({
        jobOriginAddress: this.newTripForm.value.jobDestinationAddress,
        jobOriginLatitude: this.newTripForm.value.jobDestinationLatitude,
        jobOriginLongitude: this.newTripForm.value.jobDestinationLongitude,
      });
    } else if (this.newTripForm.value.tripLegs.length > index + 1) {
      this.legsForm.at(index).patchValue({
        jobDestinationAddress: this.newTripForm.value.tripLegs[index + 1]
          .jobDestinationAddress,
        jobDestinationLatitude: this.newTripForm.value.tripLegs[index + 1]
          .jobDestinationLatitude,
        jobDestinationLongitude: this.newTripForm.value.tripLegs[index + 1]
          .jobDestinationLongitude,
      });
      this.legsForm.removeAt(index + 1);
    } else {
      this.legsForm.removeAt(index + 1);
    }
    // this.bindReturnTripId();
  }

  getDropOffAddress(place, index) {
    this.legsForm.at(index).patchValue({
      jobDestinationAddress: place.formatted_address,
      jobDestinationLatitude: place.geometry.location.lat(),
      jobDestinationLongitude: place.geometry.location.lng()
    });
    if (this.legsForm.length > (index + 1)) {
      this.legsForm.at(index + 1).patchValue({
        jobOriginAddress: place.formatted_address,
        jobOriginLatitude: place.geometry.location.lat(),
        jobOriginLongitude: place.geometry.location.lng()
      });
    }
  }

  getPickUpAddress(place, index) {
    console.log(place, index)
    this.legsForm.at(index).patchValue({
      jobOriginAddress: place.formatted_address,
      jobOriginLatitude: place.geometry.location.lat(),
      jobOriginLongitude: place.geometry.location.lng()
    });
  }

  onLegScheduleDateChange(evt, index) {
    this.legsForm.at(index).patchValue({
      scheduleTime: moment(evt).tz(this.timeZone, true).toISOString(),
    });
  }

  setTripLegTime(index?) {
    console.log(index);
    if (index) {
      if (index === 1) {
        if (this.newTripForm.value.appointmentPickupHour === "23") {
          this.legsForm.at(index).patchValue({
            schedulePickupHour: this.newTripForm.value.appointmentPickupHour,
            schedulePickupMinutes: this.newTripForm.value
              .appointmentPickupMinutes,
          });
        } else {
          if (this.newTripForm.value.appointmentPickupHour) {
            // tslint:disable-next-line: radix
            let schedulePickTime =
              parseInt(this.newTripForm.value.appointmentPickupHour) + 1;
            let updatedScheduePickTime = schedulePickTime.toString();
            if (schedulePickTime.toString().length === 1) {
              updatedScheduePickTime = "0" + updatedScheduePickTime;
            }
            this.legsForm.at(index).patchValue({
              schedulePickupHour: updatedScheduePickTime,
              schedulePickupMinutes: this.newTripForm.value
                .appointmentPickupMinutes,
            });
          }
        }
      } else {
        if (
          this.newTripForm.value.tripLegs[index - 1].schedulePickupHour === "23"
        ) {
          this.legsForm.at(index).patchValue({
            schedulePickupHour: this.newTripForm.value.tripLegs[index - 1]
              .schedulePickupHour,
            schedulePickupMinutes: this.newTripForm.value.tripLegs[index - 1]
              .schedulePickupMinutes,
          });
        } else {
          if (this.newTripForm.value.tripLegs[index - 1].schedulePickupHour) {
            // tslint:disable-next-line: radix
            let schedulePickTime =
              parseInt(
                this.newTripForm.value.tripLegs[index - 1].schedulePickupHour
              ) + 1;
            let updatedScheduePickTime = schedulePickTime.toString();
            if (schedulePickTime.toString().length === 1) {
              updatedScheduePickTime = "0" + updatedScheduePickTime;
            }
            this.legsForm.at(index).patchValue({
              schedulePickupHour: updatedScheduePickTime,
              schedulePickupMinutes: this.newTripForm.value.tripLegs[index - 1]
                .schedulePickupMinutes,
            });
          }
        }
      }
    } else {
      console.log(this.legsForm);
      if (this.legsForm) {
        this.legsForm.at(0).patchValue({
          schedulePickupHour: this.newTripForm.value.appointmentPickupHour,
          schedulePickupMinutes: this.newTripForm.value
            .appointmentPickupMinutes,
        });
      }
      for (let k = 1; k < this.newTripForm.value.tripLegs.length; k++) {
        // tslint:disable-next-line: radix
        const time = parseInt(this.newTripForm.value.appointmentPickupHour) + k;
        if (time >= 23) {
          this.legsForm.at(k).patchValue({
            schedulePickupHour: "23",
            schedulePickupMinutes: this.newTripForm.value
              .appointmentPickupMinutes,
          });
        } else {
          let updatedTime = time.toString();
          if (time.toString().length === 1) {
            updatedTime = "0" + updatedTime.toString();
          }
          this.legsForm.at(k).patchValue({
            schedulePickupHour: updatedTime,
            schedulePickupMinutes: this.newTripForm.value
              .appointmentPickupMinutes,
          });
        }
      }

      console.log(this.newTripForm.value);


      // }
    }
  }

  clearTripLegs() {
    for (let i = this.newTripForm.value.tripLegs.length; i > 0; i--) {
      const linesFormArray = this.newTripForm.get("tripLegs") as FormArray;
      linesFormArray.removeAt(i);
    }
  }

  patchLegData(dataArray) {
    // auto selected field
    const linesFormArray = this.newTripForm.get("tripLegs") as FormArray;
    linesFormArray.removeAt(0);

    for (let item = 0; item < dataArray.length; item++) {

      let myLegForm = this.fb.group({
        scheduleTime: [dataArray[item].scheduleTime],
        schedulePickupHour: [dataArray[item].schedulePickupHour],
        schedulePickupMinutes: [dataArray[item].schedulePickupMinutes],
        jobOriginAddress: [dataArray[item].jobOriginAddress],
        jobOriginLatitude: [dataArray[item].jobOriginLatitude],
        jobOriginLongitude: [dataArray[item].jobOriginLongitude],
        jobDestinationAddress: [dataArray[item].jobDestinationAddress],
        jobDestinationLatitude: [dataArray[item].jobDestinationLatitude],
        jobDestinationLongitude: [dataArray[item].jobDestinationLongitude],
        // tripId: [dataArray[item].tripId],
      });
      const linesFormArray = this.newTripForm.get("tripLegs") as FormArray;
      // myLegForm.patchValue(dataArray[item]);
      linesFormArray.push(myLegForm);
    }
  }
  // ==================================== Legs Code Ends ====================================

  onSubmit() {
    console.log('Form Object: ', this.newTripForm);
    console.log('Form Values: ', this.newTripForm.value);
    this.submitted = true;

    if (this.newTripForm.invalid) {
      return;
    }

    // Adding DOB to all legs
    if (this.legsForm && this.legsForm.length > 0) {
      for (let i = 1; i < this.legsForm.length; i++) {
        this.legsForm.at(i).patchValue({
          dob: this.newTripForm.value.dob
        });
      }
    }

    const payload = this.newTripForm.value;

    this.tripService
      .saveTrip(this.tripId, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          if (this.tripId) {
            this.onCloseModal.emit(data);
          } else {
            this.router.navigateByUrl('distribution/trips');
          }
        }
      });
  }

   patchTripData(selectedTrip) {
    if (selectedTrip.facility && selectedTrip.facility.timeZone) {
      this.timeZone = selectedTrip.facility.timeZone; 
      this.todayDate = moment().tz(this.timeZone);
    }
    this.newTripForm.patchValue({
      ...selectedTrip,
      jobPassengerNumber: 1,
      preScheduleTime: this.todayDate,
      scheduleTime: this.todayDate.toISOString(),
      preAppointmentTime: moment(this.todayDate).add(1, 'hours'),
      appointmentTime: moment(this.todayDate).add(1, 'hours').toISOString(),
      facility: selectedTrip.facility ? selectedTrip.facility._id :'',
      suggestedprovider: selectedTrip.company ? selectedTrip.company._id :null
    });
  }

  getMemberTrips(name) {
    this.assignedTrips = [];
    const payload = {
      displayName: name
    };
    this.tripService
      .getMemberTrips(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          console.log(data)
          this.assignedTrips = data;
        }
      });
  }

  get form() {
    return this.newTripForm.controls;
  }

  get legsElement() {
    this.legsForm = this.newTripForm.controls.tripLegs as FormArray;
    return this.legsForm;
  }

  ngOnDestroy(): void {
    
  }
}
