import { Component, OnInit } from '@angular/core';
import { getBrokersList } from 'src/app/utils/utils.common';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CompaniesService } from '../../service/companies.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-create-fare',
  templateUrl: './create-fare.component.html',
  styleUrls: ['./create-fare.component.css']
})
export class CreateFareComponent implements OnInit {
  id; // It can be fareId or companyId based on route being hit
  user: any;
  platform: string;
  companies = getBrokersList;
  vehicleTypes = ['Standard', 'Premium', 'SUV', 'WAV', 'Stretcher'];

  fareForm: FormGroup
  isEditingFare = false;
  isSubmitted = false;

  public zoneFares: FormArray;
  zoneFareForm: FormGroup;
  zoneFareSubmitted: boolean = false;
  singleZoneFormSubmitted = false;
  isEditingZone: boolean = false;
  zoneIndex: number = -1;
  public newZoneFare = {
    fare: null,
    from: null,
    to: null
  }

  constructor(
    private companyService: CompaniesService,
    private sharedData: SharedDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.getUser();
    this.fareForm = this.fb.group({
      _id: [''],
      vehicleType: ['Standard', Validators.required],
      pickupSurcharge: this.fb.group({
        fare: [null, Validators.required],
        per: [null, Validators.required],
        after: [null, Validators.required]
      }),
      baseFare: this.fb.group({
        fare: [null, Validators.required],
        for: [null, Validators.required]
      }),
      zones: this.fb.array([]),
      perMile: [null, Validators.required],
      minimumFare: [null, Validators.required],
      cancelCharges: [null, Validators.required],
      perMin: [null, Validators.required],
      waitTime: this.fb.group({
        fare: [null, Validators.required],
        per: [null, Validators.required],
        after: [null, Validators.required]
      }),
      additionalPassengerCharges: [null, Validators.required],
      company: [null, Validators.required],
      companyType: [null, Validators.required],
      for: ['broker', Validators.required]
    });

    this.zoneFares = this.fareForm.get("zones") as FormArray;

    // It can be fareId or companyId based on route being hit
    this.id = this.activatedRoute.snapshot.paramMap.get('id');

    // this.activatedRoute.params.subscribe((params) => {
    //   this.fareId = params["id"];
    // });

    if (this.router.url.indexOf('edit') > -1) {
      this.isEditingFare = true;
      this.getFareDetails();
    } else {
      this.fareForm.patchValue({
        company: this.id,
        companyType: this.user.platform
      });
    }
  }

  getUser() {
    this.sharedData
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
        }
      });
  }

  getFareDetails() {
    this.companyService
      .getFareDetails(this.id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.fareForm.patchValue(data);
        const zones = data.zones;
        if (zones.length > 0) {
          zones.forEach(zoneFare => {
            this.zoneFares.push(this.fb.group({ ...zoneFare }));
          });
        }
      });
  }

  onSelectVehicle(vehicle) {
    this.fareForm.patchValue({
      vehicleType: vehicle,
    });
  }

  isVehicleSelected(type) {
    return type === this.fareForm.controls.vehicleType.value;
  }

  // Zone Methods Starts
  addZone() {
    this.zoneFareSubmitted = true;
    this.zoneFares = this.fareForm.get("zones") as FormArray;
    //const newZoneFare = this.fareForm.controls['newZone'].value;
    const t = this.newZoneFare;
    const newZoneFare = this.newZoneFare;
    let isNewZoneValid = Object.values(newZoneFare);
    if (isNewZoneValid.includes(null) || isNewZoneValid.includes("")) {
      return;
    }

    this.zoneFares.push(this.fb.group({ ...newZoneFare }));
    this.newZoneFare.fare = null;
    this.newZoneFare.from = null;
    this.newZoneFare.to = null;
    this.zoneFareSubmitted = false;
    // let newZone = this.fb.group({
    //   fare: [newZoneFare.fare, Validators.required],
    //   from: [newZoneFare.from, Validators.required],
    //   to: [newZoneFare.to, Validators.required],
    // });

    // this.zoneFares.push(newZone);
    //this.fareForm.controls['newZone'].reset();
  }

  editZone(index) {
    this.zoneIndex = index;
    this.newZoneFare = { ...this.zoneFares.at(index).value };
    this.isEditingZone = true;
  }

  saveZone() {
    //debugger;
    this.zoneFareSubmitted = true;
    const newZoneFare = this.newZoneFare;
    let isNewZoneValid = Object.values(newZoneFare);
    this.isEditingZone = true;
    if (isNewZoneValid.includes(null) || isNewZoneValid.includes("")) {
      return;
    }
    this.zoneFares.at(this.zoneIndex).patchValue(this.newZoneFare);
    this.clearZone();
    this.zoneFareSubmitted = false;
  }

  clearZone() {
    this.zoneIndex = -1;
    this.newZoneFare = { fare: null, from: null, to: null };
    this.isEditingZone = false;
  }

  deleteZone(index) {
    this.zoneFares.removeAt(index);
  }
  // Zone Methods Ends

  onSubmit() {
    this.isSubmitted = true;

    if (this.fareForm.invalid) {
      return false;
    }

    this.fareForm.patchValue({ companyType: this.user.platform });
    const fare = this.fareForm.value;
    delete fare._id;

    const id = this.isEditingFare ? this.id : '';
    this.companyService
      .saveFare(id, fare)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.router.navigate([`/credentialing/companies/${this.fareForm.controls.company.value}/fares`]);
        }
      });
  }

  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46, 190];
    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }

  get form(): any {
    return this.fareForm.controls;
  }

  get zones(): FormArray {
    return this.fareForm.get('zones') as FormArray;
  }

  ngOnDestroy(): void {
  }
  
}