import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { urls } from '../../utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class SignupRequestService {

  constructor(private http: HttpClient) { }

  getSignUpRequests(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.SIGNUP_REQUESTS);

  }
  approveSignupRequest(id): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let obj = {}
    let options = { headers: authHeader };
    return this.http.post<any>(urls.BASE_URL + urls.SIGNUP_REQUESTS + '/' + id, obj, options);
  }


}
