import { Component, OnInit } from '@angular/core';
import { CompaniesService } from '../service/companies.service';
import { HttpParams } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from "moment-timezone";
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { urls } from 'src/app/utils/url-utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css']
})
export class CompanyListComponent implements OnInit {
  companiesList = [];
  search:any;
  user;
  userPlatform: any = 'nemt';
  viewAccess = false;
  timeZone = 'America/New_York';
  today;
  accountStatus = "active"
  canView = false;
  
  
  constructor(private companyService:CompaniesService,
    private sharedData: SharedDataService) { 
      document.addEventListener('keyup', e => {
        console.log(e);
        // e.ctrlKey &&
        if (e.altKey && (e.key.toLowerCase() === 'v' || e.key.toLowerCase() === '√') && this.viewAccess) {
          this.canView = !this.canView;
        }
      });
    }
  

  ngOnInit() {
    this.sharedData.getUser()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      if (data) {
        this.user = data;
        this.timeZone = data.timeZone;
        this.userPlatform = data.platform;
        if (data.roleType === 'admin') {
          this.viewAccess = true;
        }
        this.today = moment().tz(this.timeZone);
      }
    });
    this.getCompanyList();
  }

  getCompanyList(){
    const queryParams = {
      accountStatus: this.accountStatus,
      isRemoveIAAL: true
    }

    this.companyService.getCompanies(queryParams)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data=>{
      if(data){
        this.companiesList = data;
      }
    })
  }

  getTrialStatus(date) {
    const trialEndDate = moment(date).tz(this.timeZone);
    return trialEndDate.diff(this.today, 'days');
  }

  onChangeStatus(){
    this.getCompanyList();
  }

  openProviderPanel(companyId, isNewPanel) {
    this.companyService.getCompanyToken(companyId)
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (isNewPanel) {
          window.open(`${urls.NEW_SERVER_URL}auth-token/${data.companyToken}`, '_blank');
        } else {
          window.open(`${urls.SERVER_URL}auth_token?authtoken=${data.companyToken}`, '_blank');
        }
      });
  }

  openOnboardPanel(companyId) {
    this.companyService.getCompanyToken(companyId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (environment.production) {
          window.open(`${environment.CREDENTIALING_ONBOARD_URL}dashboard?auth_token=${data.companyToken}`, '_blank');
        }
        else {
          window.open(`${environment.CREDENTIALING_ONBOARD_URL}dashboard?auth_token=${data.companyToken}`, '_blank');
        }

      });
  }

  ngOnDestroy(): void {
  }
  
}
