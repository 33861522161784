import { Pipe } from '@angular/core';

// tslint:disable-next-line: use-pipe-transform-interface
@Pipe({
    name: 'formatTitleCase'
})

export class FormatTitleCasePipe {
    transform(value: string) {
        if (typeof value !== 'string' || value === '') {
            return value;
        }
        const words = [];
        let start = 0;
        for (let i = 1; i < value.length; i++) {
            const char = value[i];
            if (char === char.toUpperCase() && char !== char.toLowerCase()) {
                words.push(value.slice(start, i));
                start = i;
            }
        }
        words.push(value.slice(start));
        const formattedWords = words.map((word, index) => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });

        return formattedWords.join(' ');

    }
}

