import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth-service/auth.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import * as moment from 'moment-timezone';
import { animate, style, transition, trigger } from "@angular/animations";
import { MenuService } from '../menu-service/menu.service';
import { Events } from "src/app/utils/event-utils";
import { ToastrService } from 'ngx-toastr';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger("openCloseProfile", [
      transition(":enter", [
        style({ opacity: 0, transform: "scale(0.95)" }), //apply default styles before animation starts
        animate(
          "500ms ease-in-out",
          style({ opacity: 1, transform: "scale(1)" })
        )
      ]),
      transition(":leave", [
        style({ opacity: 1, }), //apply default styles before animation starts
        animate(
          "300ms ease-in-out",
          style({ opacity: 0, transform: "scale(.95)" })
        )
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('audioOption', { static: true }) audioPlayerRef: ElementRef;
  subMenus;
  showUserOptions = false;
  showNotifications = false;
  showDocuments = false;
  currentPath = '';
  isHome = false;
  timeZone;
  showQuickMenu = false;
  user: any;
  displayName: any;
  profileImage: any;
  intervalTimeID: any;
  userRole: any;
  currentTime = moment().toISOString();
  menus;
  isSound = true;

  constructor(
    private sharedDataService: SharedDataService,
    private authService: AuthService,
    private menuService: MenuService,
    private socket: SocketService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.getUser();
    this.router.events
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url) {
            let path = event.url;
            this.currentPath = path.substring(1);
            this.getSubMenus();
          }
        }
      });

    this.intervalTimeID = setInterval(() => {
      this.currentTime = moment().toISOString();
    }, 1000);

    this.listenSocketEvents();
    this.setSoundState();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.displayName = user.displayName
          this.profileImage = this.user.profileImageURL;
          this.userRole = user.roleType;
          this.timeZone = user.timeZone;
          this.menus = this.menuService.getMenu(this.user.role, this.user.platform);
        }
      });
  }

  listenSocketEvents() {
    // General Notifications
    this.socket.addListener(Events.GET_QUARTER_NOTIFICATIONS)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data.sucess) {
          this.onAudioPlay();
          this.toastr.success(data.message, 'Alert');
        }
      });

    // Marketplace Notification
    this.socket.addListener(Events.GET_MARKETPLACE_NOTIFICATION)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          if(data.status === 'accepted') {
            this.toastr.success(data.text,'Trip Accepted');
          } else {
            this.toastr.error(data.text,'Trip Rejected');
          }
          this.onAudioPlay();
        }
      });

      this.socket
      .addListener(Events.GET_MARK_READY_NOTIFICATION)
      .subscribe(data => {
        if (data && data.assign) {
          this.toastr.success(data.message, 'Alert');
        }
      });
  }

  getSubMenus() {
    this.sharedDataService
      .getSubMenus()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.subMenus = [];
        if (data) {
          this.isHome = this.currentPath === data.key ? true : false;
          if (data.subMenus) {
            this.subMenus = data.subMenus;
          }
        }
      });
  }

  onQuickMenuClick(menuKey) {
    this.subMenus = [];
    const menu = this.menus[menuKey];
    this.sharedDataService.saveSubMenus(menu ? menu : null);

  }

  viewSmallScreenMenu() {
    this.sharedDataService.setSmallScreenMenuState(true);
  }

  onClickDropdownMenu() {
    this.sharedDataService.saveSubMenus(null);
    this.showUserOptions = !this.showUserOptions
  }

  setSoundState() {
    // Setting the sound state if there is any value present in localstorage
    if (localStorage.getItem("sound")) {
      this.isSound = JSON.parse(localStorage.getItem("sound"));
    }
  }

  toggleSound() {
    this.isSound = !this.isSound;
    localStorage.setItem("sound", JSON.stringify(this.isSound));
  }

  onAudioPlay() {
    if (this.isSound) {
      this.audioPlayerRef.nativeElement.play();
    }
  }

  logout() {
    this.socket.disconnectSocket();
    this.authService.removeToken();
    this.sharedDataService.saveUser(null);
    this.router.navigateByUrl('login');
  }

  ngOnDestroy(): void {
    if (this.intervalTimeID) {
      clearInterval(this.intervalTimeID);
    }

    this.socket.removeListener(Events.GET_QUARTER_NOTIFICATIONS);
    this.socket.removeListener(Events.GET_MARKETPLACE_NOTIFICATION);
    this.socket.removeListener(Events.GET_MARK_READY_NOTIFICATION);
  }
}
