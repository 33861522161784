import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { AssigTripsService } from 'src/app/components/assigned-trips/assigntrips-service/assigntrips-service';
import { sweetAlert } from 'src/app/utils/swal';

@Component({
  selector: 'app-enterprise-cancel-trip-modal',
  templateUrl: './enterprise-cancel-trip-modal.component.html',
  styleUrls: ['./enterprise-cancel-trip-modal.component.css']
})
export class EnterpriseCancelTripModalComponent implements OnInit, OnDestroy {
  @Input() trip;
  @Output() onClose = new EventEmitter<any>();
  reason;

  constructor(private assignTripService: AssigTripsService) { }

  ngOnInit() {
  }

  submit() {

    if (!this.reason) {
      return;
    }

    this.assignTripService
      .cancelFacilityTrip(this.trip._id, this.reason)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', "Trip Updated Succesfully", 'success', 'OK')
          this.onClose.emit(data);
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });

  }


  closeModal() {
    this.onClose.emit();
  }

  ngOnDestroy(): void {

  }
}
