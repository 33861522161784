import { data } from 'jquery';
import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { CredentialsService } from '../credentials/service/credentials.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ThresholdService } from '../medex/threshold/threshold-service/threshold.service';
import { sweetAlert } from 'src/app/utils/swal';
 'src/app/utils/swal';

@Component({
  selector: 'app-los-threshold',
  templateUrl: './los-threshold.component.html',
  styleUrls: ['./los-threshold.component.css']
})
export class LosThresholdComponent implements OnInit {
  driverData;
  fleetData;
  vehicleForm: FormGroup;

  vehicleTypes = [{ key: 'standard', name: 'Standard' },
   { key: 'premium', name: 'Premium' },
   { key: 'suv', name: 'SUV' },
   { key: 'wav', name: 'WAV' },
   { key: 'stretcher', name: 'Stretcher' }];

  constructor(private credentialsService: CredentialsService,
    private thresholdService: ThresholdService,
    private fb: FormBuilder
    ) { }

  ngOnInit() {
    this.vehicleForm = this.fb.group({
      vehiclethreshold: this.fb.array([]),
    });

    this.getDashboardDetail();
    this.getCompaniesThreshold();
  }

  getDashboardDetail() {
    this.credentialsService
      .getDasboardDetails()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data:any) => {
        this.driverData = data.driveralivi[0];
        this.fleetData = data.fleetalivi[0];
      });
  }

  getCompaniesThreshold() {
    this.thresholdService
      .getProviderThresholds()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          // this.companies = data;
          console.log(data, "verify data");
          for (let i = 0; i < data.length; i++) {
            this.losThresholdFormArray.push(
              this.fb.group({
                _id: [""],
                totalTrips: [0],
              })
            );
            // for (let val of data[i].vehiclethreshold) {
            //   this.losThresholdFormArray.at(i).patchValue({
            //     ...val,
            //   });
            // }
          }
        }
      });
  }

  onSubmit(index) {
    return;
    let payload = this.losThresholdFormArray.at(index).value;

    payload = {
      ...payload,
      totalTrips: ( payload.totalTrips && payload.totalTrips >0 )? payload.totalTrips : "0"
    };

    if (payload._id !== "") {
      this.thresholdService.updateThresholds(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        sweetAlert('Success', "Company threshold is successfully updated", 'success', 'OK');
        this.patchValues(data);
      });
    } else {
      delete payload._id;
      this.thresholdService.createThresholds(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        sweetAlert('Success', "Company threshold is successfully updated", 'success', 'OK');
        this.patchValues(data);
      });
    }
  }

  patchValues(data) {
    this.losThresholdFormArray.value.forEach((element, index) => {
      if (element._id === data._id) {
        this.losThresholdFormArray.at(index).patchValue({
          ...data,
        });
      }
    });
  }


  get losThresholdFormArray() {
    return this.vehicleForm.controls.vehiclethreshold as FormArray;
  }

  ngOnDestroy(): void {
  }
}
