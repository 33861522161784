import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { CompaniesService } from 'src/app/components/companies/service/companies.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { Events } from 'src/app/utils/event-utils';
import { sweetAlert } from 'src/app/utils/swal';
@Component({
  selector: 'app-company-assign-modal',
  templateUrl: './company-assign-modal.component.html',
  styleUrls: ['./company-assign-modal.component.css']
})
export class CompanyAssignModalComponent implements OnInit {

  @Input('assignDriverModalObj') trip: any;
  @Input() isForDriver: any;
  @Output() onCloseModal = new EventEmitter<any>();
  nearbyOperaters = [];
  selectedOperaters;
  search: any;
  user;
  noProviderMessage;

  constructor (
    private socketService: SocketService,
    private sharedDataService: SharedDataService,
    private companyService: CompaniesService
  ) { }

  ngOnInit() {
    this.getUser();
    this.listenSocket();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.user = data;

          if (this.isForDriver) {
            this.getNearbyDriver();
          } else {
            this.getNearbyCompany(
              this.trip.jobOriginLatitude,
              this.trip.jobOriginLongitude
            );
          }
        }
      });

  }


  listenSocket() {
    this.socketService.addListener(Events.UPDATE_ASSIGN_TRIP)
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data.success) {
          if (data.assign) {
            const trip = data.assign;
            sweetAlert('Success', "Trip Updated Succesfully", 'success', 'OK')
            this.onCloseModal.emit(trip);
          }
        }
      });
  }

  getNearbyDriver() {
    const payload = {
      _id: this.trip._id,
        jobOriginLongitude: this.trip.jobOriginLongitude,
        jobOriginLatitude: this.trip.jobOriginLatitude,
        jobDestinationLongitude: this.trip.jobDestinationLongitude,
        jobDestinationLatitude: this.trip.jobDestinationLatitude,
        scheduleTime: this.trip.scheduleTime,
        appointmentTime: this.trip.appointmentTime,
        timeZone: this.user.timeZone,
        milage: this.trip.milage,
        tripRequirement: this.trip.tripRequirement,
        readySince: this.trip.readySince,
        isReadyForPickup: this.trip.isReadyForPickup,
        tripId: this.trip.tripId
    }

    this.companyService
      .assignNearbyDriver(this.user.timeZone, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response: any) => {
        if (response.drivers) {
          this.nearbyOperaters = response.drivers;
          if (response.foundDriver) {
            this.nearbyOperaters = this.nearbyOperaters.map(d => {
              if (response.foundDriver._id === d._id) {
                d.isPreffered = true;
              }
              return d;
            })
          }
        }
        if (response.message) {
          this.noProviderMessage = response.message
        }
      });
  }

  getNearbyCompany(lat, lng) {
    const carType = this.trip.jobCarType
    this.companyService
      .assignCompany(lat,lng,carType)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response: any) => {
        this.nearbyOperaters = response.companies;
      });
  }

  onSelectOperaters(operater) {
    this.selectedOperaters = operater;
  }

  updateTrip() {

    let payload: any = {
      company: {
        _id: this.selectedOperaters.id,
        displayName: this.selectedOperaters.text
      },
      providerStatus: 'offered',
      previousCompany: this.trip.company ? this.trip.company : undefined
    }

    if (this.isForDriver) {
      payload.providerStatus = 'accepted';
      payload.status = 'offered';
      payload.jobStatus = 'offered';
      payload.driver = this.selectedOperaters.id;
      payload.company= {
        _id: this.selectedOperaters.company._id,
        displayName: this.selectedOperaters.company.displayName
      }
    }
    this.companyService
      .assignEnterpriseTrip(this.trip._id,payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', "Trip Updated Succesfully", 'success', 'OK')
          this.onCloseModal.emit(data);
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  closeModal() {
    this.onCloseModal.emit();
  }

  ngOnDestroy(): void {
  }

}
