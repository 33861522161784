import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { OnboardingService } from '../onboarding-service/onboarding.service';
import { StaffService } from '../../staff/staff.service';
import { ToastrService } from 'ngx-toastr';
import { driverFieldsList, fillingStatus, getFileNameFromUrl } from 'src/app/utils/utils.common';
import * as moment from 'moment';


@Component({
  selector: 'app-onboarding-driver',
  templateUrl: './onboarding-driver.component.html',
  styleUrls: ['./onboarding-driver.component.css']
})
export class OnboardingDriverComponent implements OnInit {
  @Input() company
  drivers: any;
  staffList: any;
  selectedDriver: any;
  approveForm: FormGroup;
  search;
  submitted =  false;

  constructor(
    private toastr: ToastrService,
    private staffService: StaffService,
    private formBuilder: FormBuilder,
    private onboardingService: OnboardingService
  ) {}

  ngOnInit() {


    this.approveForm = this.formBuilder.group({
      preApprovedDate: [""],
      approvedDate: ["", Validators.required],
      approvedBy: ["", Validators.required],
      isApproved: [false],
    });
    this.getDrivers();
    this.getStaffList();
  }

  getDrivers() {
    this.onboardingService
      .getDrivers(this.company)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (data && data.length) {
          this.drivers = data;
          this.onSelectDriver(this.drivers[0]);
        }
      });
  }
  onSelectDriver(driver) {
    this.onboardingService
      .getDriverById(driver._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (data) {
          this.selectedDriver = data;
          this.approveForm.patchValue({
            preApprovedDate: this.selectedDriver.approvedDate,
            approvedDate: this.selectedDriver.approvedDate,
            approvedBy: this.selectedDriver.approvedBy
              ? this.selectedDriver.approvedBy
              : "",
            isApproved: this.selectedDriver.isApproved,
          });
        }
      });
  }

  getStaffList() {
    this.staffService
      .getStaffData("")
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        this.staffList = data;
      });
  }

  getFileName(url) {
    return getFileNameFromUrl(url);
  }

  sendDriverServerRequest(object, id) {
    this.onboardingService.updateDriver(object, id)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data) {
        const objIndex = this.drivers.findIndex((obj) => obj._id === data._id);
        this.drivers[objIndex] = data;
        this.selectedDriver = data;
        this.approveForm.patchValue({
          preApprovedDate: this.selectedDriver.approvedDate,
          approvedDate: this.selectedDriver.approvedDate,
          approvedBy: this.selectedDriver.approvedBy
            ? this.selectedDriver.approvedBy
            : "",
          isApproved: this.selectedDriver.isApproved,
        });
        // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Update Successfully');
        this.toastr.success("Update Successfully", "Alert");
      }
    });
  }

  fillingStatus(object) {
    return fillingStatus(object);
  }

  driverFieldsList() {
    return driverFieldsList();
  }

  approveDateChange(event) {
    const date = moment.utc(event).local().format();
    this.approveForm.patchValue({
      approvedDate: date,
    });
  }

  sendDriverErrorEmail() {
    const errorList = [];
    for (let item of driverFieldsList()) {
      for (let sub in item) {
        if (!this.selectedDriver[sub]) {
          errorList.push(item[sub]);
        }
      }
    }
    const object = {
      profileMessage: `Driver ${this.selectedDriver.firstName}  ${this.selectedDriver.lastName}`,
      errorList,
    };
    this.onboardingService
      .sendErrorEmail(object, this.company)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Send Email Successfully');
          this.toastr.success("Send Email Successfully", "Alert");
        }
      });
  }

  updateDriver(name, value) {
    let object = JSON.parse(JSON.stringify(this.selectedDriver));
    object[name] = value;
    const errorList = [];
    for (let item of driverFieldsList()) {
      for (let sub in item) {
        if (!object[sub]) {
          errorList.push(item[sub]);
        }
      }
    }
    const object1 = {
      [name]: value,
      fillingStatus: errorList.length > 0 ? true : false,
    };
    this.sendDriverServerRequest(object1, this.selectedDriver._id);

  }
  sendDriverApprovalEmail() {
    const object = {
      ...this.approveForm.value,
      _id: this.selectedDriver._id,
      isApproved: this.selectedDriver.isApproved ? false : true,
      quarterUser: this.staffList.find(data => data._id === this.approveForm.value.approvedBy).displayName,
      profileMessage: `Driver ${this.selectedDriver.firstName}  ${this.selectedDriver.lastName}`,
      method :'alividriver'
    }

    this.onboardingService.sendApprovalEmail(object, this.company)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data) {
        const objIndex = this.drivers.findIndex((obj => obj._id === data._id));
        this.drivers[objIndex] = data;
        this.selectedDriver = data;
        // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Send Email Successfully');
        this.toastr.success('Send Email Successfully','Alert');
      }
    }, err => {
      this.toastr.warning('Duplicate Key Found.','Alert');
    });
  }

  onSubmit() {
    this.submitted = true
  if(this.approveForm.invalid){
    return ;
  }else{
    this.sendDriverApprovalEmail()
  }
  }

  get form() {
    return this.approveForm.controls;
  }

  ngOnDestroy(): void {}

}
