import { data } from 'jquery';
import { Component, OnInit } from '@angular/core';
import { CredentialsService } from '../service/credentials.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-dashboard-credentials',
  templateUrl: './dashboard-credentials.component.html',
  styleUrls: ['./dashboard-credentials.component.css']
})
export class DashboardCredentialsComponent implements OnInit {

  driverData;
  fleetData;

  constructor( private credentialsService: CredentialsService) { }

  ngOnInit() {
    this.getDashboardDetail();
  }

  getDashboardDetail() {
    this.credentialsService
      .getDasboardDetails()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data:any) => {
        this.driverData = data.driveralivi[0];
        this.fleetData = data.fleetalivi[0];
      });
  }


  ngOnDestroy(): void {
  }

}
