import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-edit-trip-modal',
  templateUrl: './edit-trip-modal.component.html',
  styleUrls: ['./edit-trip-modal.component.css']
})
export class EditTripModalComponent implements OnInit, OnDestroy {
  @Input() tripId: any;
  @Input() trip?: any;
  @Input() isManifest: boolean = false;
  @Output() tripEditResponse = new EventEmitter<string>();
  @Output() onCloseModal = new EventEmitter<any>();
  drivers = [];
  searchDriver = '';
  selectedDriver;
  selectedDriverId;
  driverIndex = -1;
  isSubmitted = false;
  origin = { lat: 0.00000, lng: 0.00000 };
  destination = { lat: 0.00000, lng: 0.00000 };
  driverLoc = { lat: 0.00000, lng: 0.00000 };
  isSubmitForm = false;
  // @ViewChild('newTripForm', { static: false }) newTripForm: NewTripComponent;
  isDriverRequired = false;
  cancelTripModalObj;

  constructor(
  ) { }

  ngOnInit() {
  }

  closeCancelModal(event) {
    this.cancelTripModalObj = null;
    this.tripEditResponse.emit(event);
  }

  closeModal(evt?) {
    if (evt) {
      this.onCloseModal.emit(evt);
    } else {
      this.onCloseModal.emit(null);
    }
  }

  onTripEdit(event: any) {
    this.tripEditResponse.emit(event);
  }

  ngOnDestroy(): void {
  }
}
