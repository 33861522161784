import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StaffService } from '../staff.service';
import { sweetAlert } from '../../../utils/swal';
import { ActivatedRoute, Router } from '@angular/router';
import { error } from 'console';
import { ImageSnippet, imageProcessing } from 'src/app/utils/image-processing-utils';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';

@Component({
  selector: 'app-staff-create',
  templateUrl: './staff-create.component.html',
  styleUrls: ['./staff-create.component.css']
})
export class StaffCreateComponent implements OnInit {
  staffForm: FormGroup;
  platform = 'nemt';
  staffId: any;
  submitted = false;
  selectedFile: ImageSnippet = null;
  profileImageURL = null;

  user;
  togglePassword = false;
  toggleConfirmPassword = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private staffService: StaffService,
    private router: Router,
    private fb: FormBuilder,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.activatedRoute.params
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.staffId = params["id"];
      });

    this.staffForm = this.fb.group({
      displayName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      address: ['', Validators.required],
      latitude: [''],
      longitude: [''],
      roleType: ['', Validators.required],
      accountStatus: ['active', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      cpassword: [''],
      timeZone: ['America/New_York', Validators.required],
      platform: ['']
    });

    this.getUser();
    if (this.staffId) {
      this.getStaff();
    }
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          if (this.user.platform) {
            this.staffForm.patchValue({
              platform: this.user.platform
            });
          }
        }
      });
  }

  getStaff() {
    this.staffService
      .getStaffData(this.staffId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.staffForm.patchValue({
            ...data,
            password: ""
          });
          this.profileImageURL = data.profileImageURL;

          this.staffForm.get('password').clearValidators();
          this.staffForm.get('password').updateValueAndValidity();
        }
      }, (err) => {
        sweetAlert("Error", err, "error", "Ok");
      }
      );
  }

  saveStaff() {
    this.submitted = true;

    if (this.staffForm.invalid) return;

    if (!this.staffId && !this.selectedFile) return
    if (this.staffForm.value.password && this.staffForm.value.password !== this.staffForm.value.cpassword) return;

    let payload = this.staffForm.value;

    if (this.selectedFile) {
      payload['newProfilePicture'] = this.selectedFile;
    }

    if (this.staffId && !this.staffForm.value.password) {
      delete payload.password;
    }

    this.staffService.saveStaff(this.staffId, payload)
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.router.navigateByUrl("credentialing/staff");
        }
      }, (err) => {
        sweetAlert("Error", err, "error", "Ok");
      });
  }

  processFile(imageInput: any) {
    imageProcessing(imageInput)
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((result) => {
        this.selectedFile = result.file;
        this.profileImageURL = result.src;
      });
  }

  getAddress(place) {
    if (place.formatted_address) {
      this.staffForm.patchValue({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        address: place.formatted_address,
      });
    }
  }

  changeTimezone(timezone) {
    this.staffForm.patchValue({ timeZone: timezone });
  }

  get form() {
    return this.staffForm.controls;
  }

  ngOnDestroy(): void { }
}
