import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OnboardingService } from '../onboarding-service/onboarding.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';


@Component({
  selector: 'app-onboarding-wrapper',
  templateUrl: './onboarding-wrapper.component.html',
  styleUrls: ['./onboarding-wrapper.component.css']
})
export class OnboardingWrapperComponent implements OnInit, OnDestroy {
  selectedState = 1;
  companyId;
  data;
  isHeading = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private onboardingService: OnboardingService
  ) { }

  ngOnInit() {
    console.log("Wrappper Component");

    if (this.activatedRoute.snapshot.url.length === 3) {
      this.companyId = this.activatedRoute.snapshot.url[2].path;
      this.getCompanyDetails(this.companyId);
    }
  }
  getCompanyDetails(id) {
    this.onboardingService.getCompaniesDetails(id)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      console.log("Get Details",data);
      this.data = data;
    });
  }
  changeState(state) {
    this.selectedState = state;
  }
  showHeading() {
    return this.isHeading;
  }

  ngOnDestroy(): void {
    
  }
}
