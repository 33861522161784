import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { EnterpriseService } from '../../medex/enterprises/enterprise-service/enterprise.service';
import { ReportsService } from '../../reports/reports.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { CompaniesService } from '../../companies/service/companies.service';
@Component({
  selector: 'app-company-reports',
  templateUrl: './company-reports.component.html',
  styleUrls: ['./company-reports.component.css']
})
export class CompanyReportsComponent implements OnInit, OnDestroy {
  searchForm: FormGroup;
  companiesList = [];
  submitted = false;
  companyReportsData = [];
  selectedCompany = null;
  startDate = null;
  endDate = null;
  totalJobFee = 0;
  accountStatus = "active"

  constructor(private fb: FormBuilder,
    private reportsService: ReportsService, 
    private companyService: CompaniesService) { }

  ngOnInit() {
     this.searchForm = this.fb.group({
        user: ['', Validators.required],
        reportType: ['company', Validators.required],
        providerStatus: [''],
        startDate: [null],
        endDate: [null],
        dateRange: ['']
      })

      this.getCompanies();
  }

  getCompanies(){
    const queryParams = {
      accountStatus: this.accountStatus,
      isRemoveIAAL: false
    }

    this.companyService.getCompanies(queryParams)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data=>{
      if(data){
        this.companiesList = data;
      }
    })
  }

  onDateChange(event) {
    if (event) {
      this.searchForm.patchValue({
        startDate: moment(event[0]).startOf("day").toISOString(),
        endDate: moment(event[1]).endOf("day").toISOString()
      });
    }
  }

  get form() {
    return this.searchForm.controls;
  }

  onSubmit() {
    this.companyReportsData = undefined;
    this.submitted = true;
    if (this.searchForm.invalid) {
      return
    }

    this.reportsService.getFacilityReport(this.searchForm.value)
      .subscribe(data => {
        if (data && data['length'] > 0) {
          this.companyReportsData = data[0].jobs;
          this.companyReportsData.sort((val1, val2)=> {return    moment(val1.scheduleTime).diff(moment(val2.scheduleTime), 'minutes')  })
          this.companiesList.forEach(element => {
            if (element._id === this.searchForm.value.user) {
              this.selectedCompany = element;
            }
          });
          this.startDate = this.searchForm.value.startDate;
          this.endDate = this.searchForm.value.endDate;
          this.calculateJobFee(this.companyReportsData);
        }
      })
  }

  calculateJobFee(trips) {
    this.totalJobFee = 0;
    trips.forEach(element => {
      this.totalJobFee += element.jobFee;
    });
  }

  downloadFacilityReport() {
    if (this.companyReportsData.length > 0) {
      this.reportsService.downloadFacilityReport(this.companyReportsData,this.selectedCompany, this.startDate, this.endDate, this.totalJobFee, 'Provider Trips Report')
        .subscribe(data => {
          console.log(data);
          // console.log(url, fileName);
          const body = <HTMLDivElement>document.body;
          const linkSource = data['pdfFile'];
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.target = '_blank';
          downloadLink.download = data['fileName'];
          body.appendChild(downloadLink);
          console.log(downloadLink)
          console.log(data['pdfFile'])
          console.log(data['fileName'])
          console.log(downloadLink.click())
          // downloadLink.click();
          // downloadLink.dispatchEvent(new MouseEvent('click'));
          var event = document.createEvent('Event');
          event.initEvent('click', true, true);
          downloadLink.dispatchEvent(event);
          // downloadBase64File(data.content, data.pdfName);
        })
    }
  }

  ngOnDestroy(): void {
    
  }
}
