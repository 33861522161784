
export function getCompanyLogo(driverCar): string {

  switch (driverCar) {
    case 'Standard':
      return '../assets/images/vehicles/standardcab.png';

    case 'Premium':
      return '../assets/images/vehicles/premiumcab.png';

    case 'SUV':
      return '../assets/images/vehicles/suv.png';
    case 'WAV':
      return '../assets/images/vehicles/wav.png';
    case 'Stretcher':
      return '../assets/images/vehicles/stretcher.png';
    default:
      return '../assets/images/vehicles/fleet-img.png';
  }
}

export function getBrokerImage(brokerName): string {
  switch (brokerName) {
    case 'united':
      return '../assets/images/brokers/SouthETrans.png';
    case 'lcp':
      return '../assets/images/brokers/lcp-logo.png';
    case 'first':
      return '../assets/images/brokers/mtm-logo.jpg';
    case 'logisticscare':
      return '../assets/images/brokers/logisticare.jpg';
    case 'medtrans':
      return '../assets/images/brokers/medtrans-logo.png';
    case 'medicaid':
      return '../assets/images/brokers/medicaid.png';
    case 'amera':
      return '../assets/images/brokers/amera.png';
    case 'veyo':
      return '../assets/images/brokers/veyo-logo.png';
    case 'vapremier':
      return '../assets/images/brokers/vapremier.png';
    case 'mas':
      return '../assets/images/brokers/mas.png';
    case 'epic':
      return '../assets/images/brokers/epic.png';
    case 'onecall':
      return '../assets/images/brokers/onecall.png';
    case 'americanLogistics':
      return '../assets/img/americanLogistics.png';
    case 'access2Ride':
      return '../assets/img/access2Ride.png';
    case 'welltrans':
      return '../assets/img/welltrans.png';
    case 'modivcare':
      return '../assets/img/modivcare.png.png';
    case 'callthecar':
      return '../assets/img/callthecar.png';
    case 'tncarrier':
      return '../assets/img/tncarrier.png';
    case 'saferide':
      return '../assets/img/saferide.png';
    case 'abcinsurance':
      return '../assets/img/abcinsurance.png';
    case 'ride2md':
      return '../assets/img/ride2md.png';

    case 'iehp':
      return '../assets/img/iehp.png';

    case 'caloptima':
      return '../assets/img/caloptima.png';

    default:
      return '../assets/images/brokers/MixandMatch.png';
  }
}

export const getBrokersList = {
  'default': 'Default',
  'amera': 'Amera',
  'abcinsurance': 'Abc Insurance',
  'americanLogistics': 'American Logistics',
  'arn': 'Angel Ride Network',
  'access2Ride': 'Access2Care',
  'callthecar': 'Call The Car',
  'caloptima': 'CalOptima',
  'epic': 'EPIC',
  'iehp': 'IEHP',
  'lcp': 'LCP',
  'logisticscare': 'Logisticare',
  'medex': 'Medex',
  'first': 'MTM',
  'mas': 'MAS',
  'medicaid': 'Medicaid',
  'modivcare': 'Modivcare',
  'medtrans': 'NMN',
  'onecall': 'One Call',
  'ride2md': 'Ride2MD',
  'united': 'Southeastrans',
  'saferide': 'Safe Ride',
  'tncarrier': 'Tennessee Carriers',
  'welltrans': 'Welltrans',
  'vapremier': 'Va Premier',
  'veyo': 'Veyo', 
  'providearide' : 'Provide A Ride',
  'wellmed' : 'WellMed',
  'factSd': 'FACT SD'
};


// export  function emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
//     var emailRegex = /^[a-zA-Z0-9]+.{0,1}[a-zA-Z0-9]+@[a-z]{3,}\.[a-z]{2,}$/;
//     const email: string = control.value;
//     if (email === null || email === "" || emailRegex.test(email) === true) {
//       return null;
//     }
//     else if (emailRegex.test(email) === false) {
//       return { "domain": true };
//     }
//   }

export const states = [
  { value: "AK", name: "Alaska" },
  { value: "AL", name: "Alabama" },
  { value: "AR", name: "Arkansas" },
  { value: "AZ", name: "Arizona" },
  { value: "CA", name: "California" },
  { value: "CO", name: "Colorado" },
  { value: "CT", name: "Connecticut" },
  { value: "DC", name: "DistrictofColumbia" },
  { value: "DE", name: "Delaware" },
  { value: "FL", name: "Florida" },
  { value: "GA", name: "Georgia" },
  { value: "HI", name: "Hawaii" },
  { value: "IA", name: "Iowa" },
  { value: "ID", name: "Idaho" },
  { value: "IL", name: "Illinois" },
  { value: "IN", name: "Indiana" },
  { value: "KS", name: "Kansas" },
  { value: "KY", name: "Kentucky" },
  { value: "LA", name: "Louisiana" },
  { value: "MA", name: "Massachusetts" },
  { value: "MD", name: "Maryland" },
  { value: "ME", name: "Maine" },
  { value: "MI", name: "Michigan" },
  { value: "MN", name: "Minnesota" },
  { value: "MO", name: "Missouri" },
  { value: "MS", name: "Mississippi" },
  { value: "MT", name: "Montana" },
  { value: "NC", name: "NorthCarolina" },
  { value: "ND", name: "NorthDakota" },
  { value: "NE", name: "Nebraska" },
  { value: "NH", name: "NewHampshire" },
  { value: "NJ", name: "NewJersey" },
  { value: "NM", name: "NewMexico" },
  { value: "NV", name: "Nevada" },
  { value: "NY", name: "NewYork" },
  { value: "OH", name: "Ohio" },
  { value: "OK", name: "Oklahoma" },
  { value: "OR", name: "Oregon" },
  { value: "PA", name: "Pennsylvania" },
  { value: "RI", name: "RhodeIsland" },
  { value: "SC", name: "SouthCarolina" },
  { value: "SD", name: "SouthDakota" },
  { value: "TN", name: "Tennessee" },
  { value: "TX", name: "Texas" },
  { value: "UT", name: "Utah" },
  { value: "VA", name: "Virginia" },
  { value: "VT", name: "Vermont" },
  { value: "WA", name: "Washington" },
  { value: "WI", name: "Wisconsin" },
  { value: "WV", name: "WestVirginia" },
  { value: "WY", name: "Wyoming" },
];

export function mapStyles() {
  return [
    {
      featureType: "administrative",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "water",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "landscape",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.highway",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.local",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "water",
      stylers: [
        {
          color: "#84afa3",
        },
        {
          lightness: 52,
        },
      ],
    },
    {
      stylers: [
        {
          saturation: -17,
        },
        {
          gamma: 0.36,
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#3f518c",
        },
      ],
    },
  ];
}


export function getJobStatus(status) {
  if (status === "pending") {
    return "Unassigned";
  } else  if (status === "arrived") {
    return "On Scene";
  } else if (status === "started") {
    return "Member Onboard";
  } else {
    return status;
  }
}


export function getFileNameFromUrl(url) {
  if (url && url !== '') {
    const urlSegments = url.split('/');
    if (urlSegments)
      return decodeURI(urlSegments[urlSegments.length - 1]);
    else
      return '';
  } else {
    return '';
  }
}

export function companyFieldsList () {
  return [
    { isCompanyName: "Company Name" },
    { isCity: "City" },
    { isZip: "ZIP" },
    { isAddress_1: "Address 1" },
    { isOfficePhoneNumber: "Office Phone Number" },
    { isSecondaryPhoneNumber: "Secondary Phone Number" },
    { isOfficeEmailAddress: "Office Email Address" },
    { isHoursofOperation: "Hours of Operation" },
    { isFirstName: "First Name" },
    { isLastName: "Last Name" },
    { isPhoneNumber: "Phone Number" },
    { isEmailAddress: "Email Address" },
    { isHIPAATrainingCertificateImage: "HIPAA Training Certificate" },
    { isHIPAATrainingDate: "HIPAA Training Completion Date" },
    { isFraudWasteAndAbuseCertificateImage: "Fraud, Waste, And Abuse Training Certificate" },
    { isFraudWasteAndAbuseCertificateDate: "Fraud, Waste and Abuse Training Completion Date" },
    { isDriverLicenseImage: "Upload Owner Driver License" },
    { isNonDisclosureAgreementImage: "Signed Non-Disclosure Agreement" },
    { isTransportationProviderAgreementImage: "Signed Transportation Provider Agreement" },
    { isTaxIDNumber: "Tax-ID Number (EIN)" },
    { isW9Image: "W-9" },
    { isDisclosureofOwnershipImage: "Disclosure of Ownership" },
    { isEFTFormImage: "EFT Form" },
    { isVoidedCheckImage: "Voided Check" },
    { isGeneralLiabilityInsuranceImage: "Certificate of General Liability Insurance" },
    { isGeneralLiabilityInsuranceDate: "General Liability Insurance Expiration Date" },
    { isDriverScheduleImage: "Driver Schedule" },
    { isAutoLiabilityInsuranceImage: "Certificate of Auto Liability Insurance" },
    { isAutoLiabilityInsuranceDate: "Auto Liability Insurance Expiration Date" },
    { isVehicleScheduleImage: "Vehicle Schedule" },
    { isTransportationCompanyName: "Company Name" },
    { isAmbulatoryVehicles: "Do you have Ambulatory Vehicles" },
    { isWheelchairVehicles: "Do you have Wheelchair Vehicles" },
    { isStretcherVehicles: "Do you have Stretcher Vehicles" },
    { isVehicleForHireLicenseImage: "Vehicle for Hire License" },
    { isProvideService: "What Counties do you provide Service" },
    { isBankName: "Bank Name" },
    { isAccountNumber: "Account number" },
    { isRoutingNumber: "Routing number" }
  ]
}

export function fillingStatus(object) {
  let status = true;
  for (const key in object) {
    // Skip the keys which do not start with 'is'
    // Skip the key 'isApproved'
    if (!key.startsWith('is') || key === 'isApproved' || key === 'isAddress_2' || key === 'isDev') continue;

    // Decision based on false value of keys which starts with 'is'
    if (!object[key]) {
      status = false;
      break;
    }
  }
  return status;
}

export function driverFieldsList () {
  return [
    { isFirstName: "First Name" },
    { isLastName: "Last Name" },
    { isCity: "City" },
    { isAddress_1: "Address 1" },
    { isPhoneNumber: "Cell Phone Number" },
    { isEmail: "Email Address" },
    { isProfileImage: "Profile Image" },
    { isDriverLicenseImage: "Front of Driver's License" },
    { isDriverLicenseNumber: "License Number" },
    { isDriverLicenseIssueDate: "License Issue Date" },
    { isDriverLicenseExpireDate: "License Expiration Date" },
    { isDriverLicenseStateIssue: "License State Issued" },
    { isDob: "Date of Birth" },
    { isLanguageSpoken: "Languages Spoken" },
    { isLatestPhyExamImage: "Latest Physical Exam" },
    { isLatestPhyExamDate: "Latest Physical Exam Completion Date" },
    { isDrugTestImage: "Drug Test" },
    { isDrugTestDate: "Drug Test Date" },
    { isAbuse: "Have you been convicted for child abuse or any felonies in the past 5 years?" },
    { isViolations: "Have you had any motor vehicle moving violations in the last 3 years?" },
    { isCountiesServed: "Counties Served" }
  ]
}

export function vehicleFieldsList () {
  return [
    { isDescription: "Description" },
    { isVin: "VIN" },
    { isLicensePlateNumber: "License Plate Number" },
    { isYear: "Year" },
    { isMake: "Make" },
    { isModel: "Model" },
    { isColor: "Color" },
    { isVehicleType: "Vehicle Type" },
    { isAmbulatoryCapacity: "Ambulatory Passenger Capacity" },
    { isWheelchairCapacity: "Wheelchair Passenger Capacity" },
    { isStretcherCapacity: "Stretcher Passenger Capacity" },
    { isGaragedStreetAddress: "Garaged Street Address" },
    { isCity: "City" },
    { isState: "State" },
    { isCountiesServed: "Counties Served" },
    { isRegistrationImage: "Registration" },
    { isRegistrationDate: "Registration Expiration Date" },
    { isInspectionReportImage: "Inspection Report" },
    { isInspectionReportDate: "Inspection Report Completion Date" },
    { isVehiclePicture: "Vehicle Picture Front" },
    { isVehiclePictureBack: "Vehicle Picture Back" },
    { isVehiclePictureLeft: "Vehicle Picture Left" },
    { isVehiclePictureRight: "Vehicle Picture Right" },

]
}