import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { errorHandl, setFormData, setFormDataV2 } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {


  constructor(private http:HttpClient) { }

  assignEnterpriseTrip(assignId,data) {
    return this.http.put(urls.BASE_URL + "facilitytrips/" + assignId, data );
  }

  assignCompany(lat, lng, jobCarType?) {

    let params: any = {
      longitude: lng,
      latitude: lat,
    };

    if (jobCarType) {
      params.driverCar = (jobCarType === 'LIV' || jobCarType === 'AMB') ? 'Standard': jobCarType;
    }

    let options = {
      params,
    };
    return this.http.get(urls.BASE_URL + "marketplace/companies/nearby", options);
  }


  assignNearbyDriver(timeZone, payload) {
    return this.http.post(urls.BASE_URL + "driverdistribution", {...payload,timeZone});
  }

  getCompanies(queryParams): Observable<any> {
    const params = new HttpParams({fromObject: queryParams});
    return this.http.get<any>(urls.BASE_URL + urls.GET_COMPANIES_LIST,{params});
  }


  getCompanyById(id){
    return this.http.get<any>(urls.BASE_URL + urls.GET_COMPANIES_LIST + `/${id}`);
  }


  onSaveCompany(payload){
    const imageFiles = payload.images;
    const formData = setFormData(imageFiles, payload,'');
    return this.http.post<any>(urls.BASE_URL + urls.GET_COMPANIES_LIST , formData);
  }

  updateCompany(id, payload){
    const imageFiles = payload.images;
    const formData: FormData = new FormData();
    formData.append('newProfilePicture', imageFiles['newProfilePicture'], imageFiles['newProfilePicture'].name);
    formData.append("focalPerson[displayName]", payload.focalPerson.displayName);
    formData.append("focalPerson[contactNumber]", payload.focalPerson.contactNumber);
    for (const key in payload) {
      formData.append(key, payload[key]);
    }

    // Append the image data


    return this.http.put<any>(urls.BASE_URL + urls.GET_COMPANIES_LIST + `/${id}`, formData);
  }

  saveCompany(id, payload) {
    const formData = setFormDataV2(payload);
    if (id) {
      return this.http.put<any>(urls.BASE_URL + urls.GET_COMPANIES_LIST + `/${id}`, formData);
    } else {
      return this.http.post<any>(urls.BASE_URL + urls.GET_COMPANIES_LIST , formData);
    }
  }

  getETA(tripId) {
    return this.http.get<any>(urls.BASE_URL + urls.SINGLE_TRIP + '/' + tripId)
      .pipe(
        catchError(errorHandl)
      );
  }



  assiginDriver(data) {
    return this.http.post(urls.BASE_URL + "assigns/selectedtrips",data)
    .pipe(catchError(errorHandl));
  }

  getNearbyDrivers(data) {
    // let authHeader = new HttpHeaders({
    //   authorization: "JWT " + localStorage.getItem("token")
    // });
    // let options = {
    //    headers: authHeader
    //    };
    return this.http.post(urls.BASE_URL + urls.GET_NEARBY_DRIVER_URL , data);
  }

  getCompanyToken(Id,type?) {
    if (type) {
      const params = new HttpParams({ fromObject: { roleType: type } });
      return this.http.get(urls.BASE_URL + urls.GET_COMPANY_TOKEN + '/' + Id, { params });
    } else {
      return this.http.get(urls.BASE_URL + urls.GET_COMPANY_TOKEN + '/' + Id);
    }
  }

  // Fares
  getSignupRequestFareList(signuprequestId): Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.SIGNUP_REQUESTS + signuprequestId);
  }

  getOfferedFaresList(signuprequestId): Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.GET_OFFERED_FARELIST + signuprequestId);
  }



  editOfferedFareByID(fareId, fare) {

    return this.http.put(urls.BASE_URL + urls.GET_OFFERED_FAREBYID + fareId, fare);
  }

  createOfferedFare(fare) {
    return this.http.post(urls.BASE_URL + urls.GET_CREATE_OFFERED_FARE, fare);
  }

  getFareDetails(fareId): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_OFFERED_FAREBYID + fareId);
  }

  saveFare(fareId, payload) {
    if (fareId) {
      return this.http.put(urls.BASE_URL + urls.GET_OFFERED_FAREBYID + fareId, payload);
    } else {
      return this.http.post(urls.BASE_URL + urls.GET_CREATE_OFFERED_FARE, payload);
    }
  }
}
